import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ReactComponent as LogoMiddleIcon } from '../../assets/svg/logoMiddle.svg';
import AdminAuthentication from '../AdminAuth/AdminAuthentication';
import secureLocalStorage from 'react-secure-storage';
import { useLocation, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';
import './styles.css';

const BpIcon = styled('span')(() => ({
  width: 19,
  height: 17,
  boxShadow: '0 0 0 1px var(--border-color)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
}));

const BpCheckedIcon = styled(BpIcon)({
  '&::before': {
    display: 'block',
    width: 15,
    height: 13,
    backgroundColor: 'var(--border-color)',
    content: '""',
    borderRadius: '4px',
  },
  'input:hover ~ &': {
    backgroundColor: 'none',
  },
});

const SignIn = () => {
  // console.log(a);
  const navigate = useNavigate();
  const location = useLocation();
  const emailRef = useRef();
  const isMobile = window.innerWidth <= 768;

  const [welcomeMessage, setWelcomeMessage] = useState('Morning');
  const rememberUser = secureLocalStorage.getItem('remember');

  const [checkBox, setCheckBox] = useState(rememberUser?.isChecked);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [loginData, setLoginData] = useState({
    email: rememberUser?.email || '',
    password: rememberUser?.password || '',
  });

  const { loginAdmin, error } = AdminAuthentication();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // loginAdmin(loginData, location, navigate);
    // e.target.reset();
    setIsLoading(true);
    try {
      const response = await fetch(
        'https://flyfar-hrm-pro.de.r.appspot.com/api/company/auth/send-otp',
        {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData),
        },
      );

      // if (!response.ok) {
      //   throw new Error("Failed to fetch departments");
      // }

      const data = await response.json();

      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          navigate('/otpVerification', {
            state: {
              loginData,
            },
          });
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: data?.error?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: 'error',
        title: e?.data?.message,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 11) {
      setWelcomeMessage('Morning');
    } else if (hours >= 11 && hours < 15) {
      setWelcomeMessage('After Noon');
    } else if (hours >= 15 && hours < 20) {
      setWelcomeMessage('Evening');
    } else {
      setWelcomeMessage('Night');
    }
  }, []);

  return (
    <Box
      sx={{
        bgcolor: 'var(--primary-color)',
        px: isMobile ? 2.5 : 0,
        pt: {
          lg: 0,
          md: 10,
          sm: 20,
          xs: 10,
        },
      }}>
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: '60%',
            md: '43%',
            lg: '55%',
          },
          // width: isMobile ? "100%" : "55%",
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: !isMobile && 'space-between',
          flexDirection: isMobile && 'column',
          height: isMobile ? '90vh' : '100vh',
          gap: '50px',
        }}>
        <Box sx={{ width: isMobile ? '100%' : '43%' }}>
          <Typography
            sx={{
              fontSize: '28px',
              color: 'white',
              textAlign: isMobile && 'center',
            }}>
            Good {welcomeMessage}, Dear
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              color: 'white',
              textAlign: isMobile && 'center',
            }}>
            {moment(new Date()).format('DD MMMM YYYY, hh:mm A')}
          </Typography>
          {!isMobile && (
            <>
              <Typography
                sx={{ fontSize: '22px', color: 'white', mt: '100px' }}>
                Welcome back
              </Typography>
              <Typography sx={{ fontSize: '14px', color: 'white' }}>
                you can sign in with your account by using your company admin
                credential
              </Typography>
            </>
          )}
        </Box>

        <Box
          sx={{
            bgcolor: 'white',
            borderRadius: '10px',
            width: isMobile ? '100%' : '48%',
          }}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ padding: '35px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <LogoMiddleIcon />
              </Box>
              <Box
                mt={8}
                sx={{
                  display: 'flex',
                  borderBottom: '1px solid var(--border-color)',
                  justifyContent: 'space-between',
                }}>
                <Typography sx={{ fontSize: isMobile ? '13px' : '16px' }}>
                  Email
                </Typography>
                <input
                  placeholder="Enter Email"
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  style={{ width: isMobile ? '65%' : '60%' }}
                  value={loginData.email}
                  name="email"
                  onChange={handleOnChange}
                  className="input-field"
                />
              </Box>
              <Box
                // onClick={() => {
                //   if (emailRef.current) {
                //     emailRef.current.focus();
                //   }
                // }}
                mt={5}
                sx={{
                  display: 'flex',
                  borderBottom: '1px solid var(--border-color)',
                  justifyContent: 'space-between',
                  // bgcolor: "red",
                  // cursor: "pointer",
                  position: 'relative',
                }}>
                <Typography sx={{ fontSize: isMobile ? '13px' : '16px' }}>
                  Password
                </Typography>
                <input
                  ref={emailRef}
                  placeholder="Enter Your Password"
                  required
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={loginData.password}
                  style={{ width: isMobile ? '65%' : '60%' }}
                  onChange={handleOnChange}
                  className="input-field"
                />
                <Box
                  onClick={handleClickShowPassword}
                  sx={{
                    position: 'absolute',
                    top: isMobile ? '-4px' : '-10%',
                    right: '0px',
                  }}>
                  {showPassword ? (
                    <Visibility className="fonticon09" />
                  ) : (
                    <VisibilityOff className="fonticon09" />
                  )}
                </Box>
              </Box>
              <Box
                mt={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <FormControlLabel
                  sx={{ mr: '5px', ml: '-8px' }}
                  value="end"
                  control={
                    <Checkbox
                      sx={{
                        '&:hover': { bgcolor: 'transparent' },
                      }}
                      disableRipple
                      color="default"
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: isMobile ? '12px' : '14px',
                        color: 'var(--text-medium)',
                        pl: '5px',
                      }}>
                      Remember me
                    </Typography>
                  }
                  labelPlacement="end"
                />

                <Typography
                  sx={{
                    fontSize: isMobile ? '12px' : '14px',
                    color: 'var(--primary-color)',
                    pl: '5px',
                    cursor: 'pointer',
                  }}>
                  forget password ?
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                {error && (
                  <Alert
                    severity="error"
                    style={{
                      margin: '0px',
                      padding: '0px',
                    }}>
                    <AlertTitle color="red" m={0} p={0}>
                      Error !
                    </AlertTitle>{' '}
                    <strong textAlign={'center'}>{error} !</strong>
                  </Alert>
                )}
              </Box>

              <Button
                type="submit"
                sx={{
                  color: 'white',
                  bgcolor: 'var(--text-medium)',
                  textTransform: 'capitalize',
                  width: '100%',
                  ':hover': {
                    bgcolor: 'var(--text-medium)',
                  },
                  mt: 4,
                }}
                // onClick={() => {
                //   loginAdmin(loginData, location, navigate);
                // }}
                disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress
                    style={{
                      height: '20px',
                      width: '20px',
                      color: 'white',
                    }}
                  />
                ) : (
                  'Log In'
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
