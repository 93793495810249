import {
  Box,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import ClearIcon from '@mui/icons-material/Clear';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const button = {
  mt: 1.5,
  minWidth: '150px',
  fontSize: '14px',
  textTransform: 'capitalize',
};

const selectStyle = {
  fontSize: '14px',
  '& .MuiSelect-select': {
    py: '8px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px',
    borderColor: 'transparent',
  },

  '& .MuiMenu-paper': {
    boxShadow: '10px 10px 10px black',
  },
};

const chip = {
  text: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  btn: {
    fontSize: '14px',
    cursor: 'pointer',
  },
};

const AddOverTime = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [employee, setEmployee] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    start: '',
    end: '',
    date: '',
    reason: '',
  });

  // console.log(user)

  //   console.log(selectedEmp); :00

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(
          `https://flyfar-hrm-pro.de.r.appspot.com/api/company/employees?deptId=${user?.user?.deptId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch branches');
        }

        const data = await response.json();
        setEmployee(data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEmployee();
  }, [token, user?.user?.deptId]);

  const handleAddOverTime = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://flyfar-hrm-pro.de.r.appspot.com/api/company/overtime/assign`,
        {
          method: 'POST',
          body: JSON.stringify({
            ...fields,
            start: fields?.start + ':00',
            end: fields?.end + ':00',
            employeeIds: selectedEmp.map((s) => s.id),
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const data = await response.json();
      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          navigate('/dashboard/overTime');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: data?.error?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldValue = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box>
      <Typography variant="title" className="page-title">
        Add Over Time
      </Typography>

      <Box mt={6}>
        <Box className="input-container">
          <label className="input-label">Select Employee</label>

          <Select
            multiple
            displayEmpty
            value={selectedEmp}
            onChange={(e) => setSelectedEmp(e.target.value)}
            sx={{ ...selectStyle, minWidth: '30%', width: 'auto' }}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return (
                  <span style={{ color: 'var(--text-medium)' }}>
                    Select Employee Here
                  </span>
                );
              }

              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value.id}
                      label={
                        <span style={chip.text}>
                          {value?.firstname} {value?.lastname}{' '}
                          <ClearIcon
                            style={chip.btn}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              setSelectedEmp(
                                selectedEmp.filter((b) => b.id !== value.id),
                              );
                            }}
                          />
                        </span>
                      }
                    />
                  ))}
                </Box>
              );
            }}>
            {employee.map((emp, i) => (
              <MenuItem
                sx={{ fontSize: '14px' }}
                key={i}
                value={{
                  firstname: emp?.firstname,
                  lastname: emp?.lastname,
                  id: emp?.id,
                }}>
                {emp.firstname} {emp.lastname}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box className="input-container">
          <label className="input-label">Start time</label>
          <input
            name="start"
            onChange={handleFieldValue}
            value={fields?.start}
            className="input-field"
            type="time"
            placeholder="Late Time Range"
          />
        </Box>
        <Box className="input-container">
          <label className="input-label">End time</label>
          <input
            name="end"
            onChange={handleFieldValue}
            value={fields?.end}
            className="input-field"
            type="time"
            placeholder="Late Time Range"
          />
        </Box>
        <Box className="input-container">
          <label className="input-label">Date</label>
          <input
            name="date"
            onChange={handleFieldValue}
            value={fields?.date}
            className="input-field"
            type="date"
            placeholder="Late Time Range"
            style={{ width: 'max-content' }}
            min={moment(new Date()).format('YYYY-MM-DD')}
          />
        </Box>

        <Box className="input-container">
          <label className="input-label">Reason</label>
          <input
            name="reason"
            onChange={handleFieldValue}
            value={fields?.reason}
            className="input-field"
            type="text"
            placeholder="Enter reason here"
          />
        </Box>

        <Box
          sx={{ display: 'flex', gap: '10px', justifyContent: 'end', mt: 3 }}>
          <Button
            disabled={isLoading}
            onClick={handleAddOverTime}
            sx={{
              bgcolor: 'var(--primary-color)',
              color: 'white',
              '&:hover': {
                bgcolor: 'var(--primary-color)',
              },
              ...button,
              height: '35px',
            }}>
            {isLoading ? (
              <CircularProgress
                style={{
                  height: '20px',
                  width: '20px',
                  color: 'white',
                }}
              />
            ) : (
              'Add Over Time'
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddOverTime;
