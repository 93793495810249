import { Box, Typography } from "@mui/material";
import React from "react";

const PersonalContactDetails = ({ employeeDetails }) => {
  const isMobile = window.innerWidth <= 768;
  const data = employeeDetails[0];
  return (
    <Box sx={{ mt: isMobile && 5 }}>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Personal Contact Information
      </Typography>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="salary" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Phone Number
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.personalPhone}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>Email</Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.personalEmail}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Present Address
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.presentAddress}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Parmanent Address
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.permanentAddress}
        </Typography>
      </Box>

      <Typography sx={{ mt: 4, fontWeight: 600, mb: 1 }}>
        Emergency Contact Information
      </Typography>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Phone Number
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.emergencyContact}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Relation
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.emergencyContactRelation}
        </Typography>
      </Box>
    </Box>
  );
};

export default PersonalContactDetails;
