import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch,
  SwipeableDrawer,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { submenuButton, addButton } from '../../utility/styles';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import APILoader from '../../components/APILoader/APILoader';
import Swal from 'sweetalert2';
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';

const headers = [
  'Name',
  'Status',
  'Designations',
  'Join date',
  'Salary',
  'Leave',
  'Personal Contact Info',
  'Official Contact Info',
  'Action',
  'Active',
];

const ExEmployee = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;
  const user = secureLocalStorage.getItem('admin-info');
  const [departments, setDepartments] = useState([]);
  const [deptId, setDeptId] = useState([]);
  const [employeeDept, setEmployeeDept] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const token = user?.accessToken;
  // console.log(roasterData);

  const handleUpdate = (data) => {
    const dataToSend = {
      data,
    };
    console.log(dataToSend);

    navigate('/dashboard/employeeUpdate', { state: { data: dataToSend } });
  };

  const handleMasterCheckboxChange = (event) => {
    let isChecked = event.target.checked;

    if (allCheck === false) {
      isChecked = true;
      setAllCheck(true);
    } else {
      setAllCheck(false);
      isChecked = false;
    }

    console.log(allCheck);
    console.log(isChecked);

    if (isChecked) {
      const allselectedEmployee = employeeDept?.map((data) => ({
        id: data.id,
        firstname: data.firstname,
        lastname: data.lastname,
        deptName: data.deptName,
      }));
      setSelectedRows(allselectedEmployee);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSingleCheckboxChange = (
    event,
    id,
    firstname,
    lastname,
    deptName,
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows((prevselectedEmployee) => [
        ...prevselectedEmployee,
        { id, firstname, lastname, deptName },
      ]);
    } else {
      setSelectedRows((prevselectedEmployee) =>
        prevselectedEmployee.filter((row) => row.id !== id),
      );
    }
  };

  useEffect(() => {
    const token = user?.accessToken;
    fetch(`https://flyfar-hrm-pro.de.r.appspot.com/api/company/departments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        setDepartments(data?.data);
        handleButtonClick(0, data?.data[0]?.id);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, []);

  useEffect(() => {
    fetch(
      `https://flyfar-hrm-pro.de.r.appspot.com/api/company/employees?deptId=${deptId}&status=inactive`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        setEmployeeDept(
          data?.data?.map((emp) => ({
            ...emp,
            isActive: emp.status === 'active' ? true : false,
          })),
        );
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [deptId]);

  const handleButtonClick = (index, id) => {
    setSelectedButtonIndex(index);
    setDeptId(id);
    console.log('id', id);
  };

  const handleSingleEmployee = (id) => {
    navigate(`/dashboard/employeeDtails`, { state: { employeeId: id } });
  };

  const handleStatusChange = (id) => {
    const singleUpdated = employeeDept.find((emp) => emp.id === id);

    // console.log(singleUpdated);

    try {
      Swal.fire({
        icon: 'warning',
        title: `Are you sure you want to ${
          singleUpdated.isActive ? 'inactive' : 'active'
        } employee`,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      }).then(async function (res) {
        if (res.isConfirmed) {
          await fetch(
            `https://flyfar-hrm-pro.de.r.appspot.com/api/company/employees/${id}/update-status`,
            {
              method: 'PATCH',
              body: JSON.stringify({
                status: singleUpdated.isActive ? 'inactive' : 'active',
              }),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                Swal.fire({
                  icon: 'success',
                  title: data.message,
                  confirmButtonColor: 'var(--primary-color)',
                  confirmButtonText: 'Ok',
                }).then(function () {
                  window.location.reload();
                });
              }
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDepartmentClick = (id) => {
    setDeptId(id);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const filteredEmployees = employeeDept?.filter(
    (emp) => emp.deptId === deptId,
  );

  console.log('employeeDept', employeeDept);
  console.log(employeeDept);
  console.log(deptId);

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflowY: 'auto' }}>
      {/*------------- Page Title -------------*/}

      {departments?.length > 0 ? (
        <Box sx={{ paddingBottom: '50px' }}>
          <Box sx={{ display: 'flex', gap: '25px' }}>
            <Typography variant="title" className="page-title">
              Ex-Employee List
            </Typography>
          </Box>

          {isMobile && (
            <Box sx={{ mt: 5 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {departments?.map((department, i) => (
                  <Box
                    key={i}
                    onClick={() => handleDepartmentClick(department?.id)}
                    sx={{
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      px: 2,
                      py: 1,
                      borderRadius: '5px',
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: '#f5f5f5',
                      color: '#000',
                    }}>
                    <LayersIcon />
                    <Typography>{department?.deptName}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          <SwipeableDrawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            PaperProps={{
              sx: {
                height: '100%',
                width: '100%',
                borderRadius: 0,
              },
            }}>
            <Box sx={{ padding: 0 }}>
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  bgcolor: 'var(--primary-color)',
                  position: 'sticky',
                  py: 4,
                  px: 3,
                  borderRadius: '0 0 50px 50px',
                  color: 'white',
                }}>
                Employees
              </Typography>

              <CloseIcon
                onClick={handleDrawerClose}
                sx={{
                  position: 'absolute',
                  top: 30,
                  right: 8,
                  zIndex: 1,
                  color: 'black',
                  bgcolor: 'white',
                  p: 0.5,
                  borderRadius: '50px',
                }}
              />

              <Box sx={{ px: 2 }}>
                {filteredEmployees?.map((data, index) => (
                  <Box
                    key={index}
                    sx={{
                      fontSize: '13px',
                      height: '60px',
                      mb: 1,
                      bgcolor: 'white',
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      px: 2,
                      borderRadius: '2px',
                    }}
                    onClick={() => {
                      handleSingleEmployee(data?.id);
                    }}>
                    <Typography
                      sx={{
                        bgcolor: 'black',
                        px: 0.8,
                        py: 0.2,
                        borderRadius: '2px',
                        color: 'var(--evening-color)',
                        fontWeight: '600',
                      }}>
                      {data?.firstname?.[0]?.toUpperCase()}
                      {data?.lastname?.[0]?.toUpperCase()}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {data?.firstname} {data?.lastname}
                      </Typography>

                      <Typography sx={{ fontSize: '13px', fontWeight: '400' }}>
                        {data?.designation}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              {filteredEmployees?.length === 0 && (
                <Typography>No employees found for this department.</Typography>
              )}
            </Box>
          </SwipeableDrawer>

          <Box
            mt={5}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {!isMobile && (
              <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                {departments.map((department, i) => (
                  <Button
                    key={i}
                    sx={{
                      ...submenuButton,
                      ...(i === selectedButtonIndex && {
                        backgroundColor: 'var(--primary-color)',
                        color: '#ffffff',
                        ':hover': {
                          bgcolor: 'var(--primary-color)',
                        },
                        // width: "100px",
                      }),
                    }}
                    onClick={() => handleButtonClick(i, department.id)}>
                    {department?.deptName}
                  </Button>
                ))}
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
              }}>
              <Button
                sx={{
                  background: 'var(--primary-color)',
                  color: '#ffffff',
                  borderRadius: '5px',
                  fontSize: '12px',
                  textTransform: 'capitalize',
                  '&:hover': {
                    background: 'var(--primary-color)',
                  },
                  m: 1,
                  visibility: selectedRows?.length > 0 ? 'visible' : 'hidden',
                }}
                onClick={() => {
                  navigate('/dashboard/employee/editRoaster', {
                    state: { selectedRows },
                  });
                }}>
                Edit Roaster
              </Button>

              {/* <Button
                onClick={() => navigate("/dashboard/employee/addEmployee")}
                sx={addButton}
              >
                Add Employee
              </Button> */}
            </Box>
          </Box>

          {/*------------- Page Content -------------*/}
          {!isMobile && (
            <Box mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={allCheck}
                          onChange={handleMasterCheckboxChange}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: 'Var(--primary-color)',
                            },
                          }}
                        />
                      </TableCell>
                      {headers?.map((header, i) => (
                        <TableCell key={i}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeeDept?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.some(
                              (row) => row.id === data.id,
                            )}
                            onChange={(event) =>
                              handleSingleCheckboxChange(
                                event,
                                data?.id,
                                data?.firstname,
                                data?.lastname,
                                data?.deptName,
                              )
                            }
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: 'Var(--primary-color)',
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            sx={{
                              backgroundColor: 'var(--primary-color)',
                              color: '#ffffff',
                              ':hover': {
                                bgcolor: 'var(--primary-color)',
                              },
                              width: '80%',
                            }}
                            onClick={() => handleSingleEmployee(data?.id)}>
                            {data?.firstname} {data?.lastname}{' '}
                          </Button>
                        </TableCell>
                        <TableCell> {data?.status} </TableCell>
                        <TableCell> {data?.designation} </TableCell>
                        <TableCell> {data?.joiningDate} </TableCell>
                        <TableCell> {data?.basicSalary} </TableCell>
                        <TableCell> {data?.casualLeave} </TableCell>
                        <TableCell> {data?.personalPhone} </TableCell>
                        <TableCell> {data?.officialPhone} </TableCell>

                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{
                              marginLeft: '10px',
                              background: 'var(--primary-color)',
                              '&:hover': {
                                background: 'var(--primary-color)',
                              },
                            }}
                            onClick={() => handleUpdate(data)}>
                            <Typography
                              sx={{ fontSize: '12px', color: 'white' }}>
                              Update
                            </Typography>
                          </Button>
                        </TableCell>

                        <TableCell>
                          <Switch
                            checked={data?.isActive}
                            onChange={() => handleStatusChange(data?.id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default ExEmployee;
