import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../assets/svg/leftArrow.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/svg/rightArrow.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/edit.svg';
import moment from 'moment';
import secureLocalStorage from 'react-secure-storage';
import APILoader from '../../components/APILoader/APILoader';
import Swal from 'sweetalert2';
import { addButton } from '../../utility/styles';

function getCurrentMonth(date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthAbbreviation = months[monthIndex];

  return `${day} ${monthAbbreviation}, ${year}`;
}

function getCurrentDate(date) {
  const da = moment(date);
  // Format the date
  const formattedDate = da.format('D MMM, YYYY');
  return formattedDate;
}

const updateRemarks = {
  padding: '1.5px 5px 1px 5px',
  fontSize: '12px',
  backgroundColor: 'transparent',
  width: 'max-content',
  borderBottom: '1px solid var(--text-medium)',
  cursor: 'pointer',
  color: 'var(--text-medium)',
};

const SingleAttendenceDetails = ({
  currentDate,
  employeeId,
  employeeName,
  setOpen,
  crrAttDate,
}) => {
  const [pickDate, setPickDate] = useState(currentDate);
  const [selectedSublist, setSelectedSublist] = useState(null);
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [shifts, setShifts] = useState([]);
  const [currentShift, setCurrentShift] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateData, setUpdateData] = useState({
    adminRemarks: '',
    updateCheckOutStatus: false,
    updateCheckInStatus: false,
  });

  const [updateRemarks, setUpdateRemarks] = useState({
    reason: 'late',
    checkInTime: '',
    checkOutTime: '',
  });

  const inputRef = useRef(null);
  const isMobile = window.innerWidth <= 768;
  const nextDate = () => {
    const newDate = new Date(pickDate);
    newDate.setDate(newDate.getDate() + 1);

    const currentDateObj = new Date();
    currentDateObj.setHours(0, 0, 0, 0);

    const tomorrow = new Date(currentDateObj);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (newDate <= tomorrow) {
      setPickDate(newDate);
    } else {
      alert('Cannot go beyond the current date.');
    }
  };

  const previousDate = () => {
    const newDate = new Date(pickDate);
    newDate.setDate(newDate.getDate() - 1);

    setPickDate(newDate);
  };

  function isISO8601Format(dateString) {
    const date = new Date(dateString);
    return (
      !isNaN(date.getTime()) && date.toISOString().slice(0, 10) === dateString
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/attendances?employeeId=${employeeId}&date=${moment(
          pickDate,
        ).format('YYYY-MM-DD')}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch attendance data');
        }

        const data = await response.json();

        if (data?.success === true && Array.isArray(data.data)) {
          setShifts(data.data);
          if (data.data.length > 0) {
            setCurrentShift(0);
            setSelectedSublist(0);
          }
        } else {
          console.error('Failed to fetch attendance data:', data);
        }
      } catch (error) {
        console.error('Error fetching attendance data:', error.message);
      }
    };

    fetchData();
  }, [pickDate, employeeId, token]);

  const pickDateType = isISO8601Format(pickDate);

  const handleGetUpdateData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUpdateData({
      ...updateData,
      [name]:
        name === 'updateCheckOutStatus' || name === 'updateCheckInStatus'
          ? value === 'true'
            ? true
            : false
          : value,

      // name === "updateCheckOutStatus"
      //   ? value === "true"
      //     ? true
      //     : false
      //   : name === "updateCheckInStatus" && value === "true"
      //   ? true
      //   : false,
    });
  };

  const handleupdateAttendance = async () => {
    setIsUpdating(true);

    try {
      const response = await fetch(
        `https://flyfar-hrm-pro.de.r.appspot.com/api/company/attendances/${shifts[currentShift]?.attendacneId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Assuming you're sending JSON data
          },
          body: JSON.stringify({
            adminRemarks: `I updated the attendance record for ${employeeName} due to ${
              updateData.updateCheckInStatus && !updateData.updateCheckOutStatus
                ? 'late'
                : 'forget shift'
            } with the check-in time ${
              updateRemarks.checkInTime
            } and the check-out time ${updateRemarks.checkOutTime}`,
            updateCheckOutStatus: updateData.updateCheckOutStatus,
            updateCheckInStatus: updateData.updateCheckOutStatus,
            checkIn: updateRemarks.checkInTime + ':00',
            checkOut:
              updateRemarks.reason === 'late'
                ? null
                : updateRemarks.checkOutTime + ':00',
          }),
        },
      );

      const updatedData = await response.json();
      // console.log(response, updatedData);

      if (response.ok) {
        setOpen(false);
        Swal.fire({
          icon: 'success',
          title: updatedData?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          window.location.reload();
        });
      } else {
        // setOpen(false);
        Swal.fire({
          icon: 'error',
          title: updatedData?.error?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(() => {
          setOpen(true);
        });
      }
    } catch (error) {
      console.error('Error updating data:', error.message);
      // You can handle the error as needed, e.g., show a message to the user
      throw error; // Re-throwing the error to propagate it
    }
  };

  return (
    <>
      {shifts.length > 0 ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="title"
              className="page-title"
              sx={{
                fontSize: isMobile ? '17px' : '22px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}>
              {!isMobile && (
                <Typography>
                  Attendance Details, {employeeName},{' '}
                  {moment(pickDate).format('DD MMMM YYYY')}
                </Typography>
              )}

              {isMobile && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontSize: '20px !important' }}>
                    {moment(pickDate).format('DD MMMM YYYY')}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 0.6 }}>
                    <Typography sx={{ color: 'var(--primary-color)' }}>
                      {moment(pickDate).format('dddd')}{' '}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ color: 'var(--primary-color)' }}>
                        {shifts[currentShift]?.checkInStatus === 'pending' ||
                        !shifts[currentShift]?.checkInStatus
                          ? 0
                          : 1}
                        /
                      </Typography>

                      <Typography sx={{ color: 'var(--primary-color)' }}>
                        {shifts[currentShift]?.checkOutStatus === 'pending' ||
                        !shifts[currentShift]?.checkOutStatus
                          ? 0
                          : 1}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {shifts[currentShift]?.checkInStatus !== 'updated' && (
                <EditIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsEdit(!isEdit)}
                />
              )}
            </Typography>

            <Box className="date-change-container" sx={{ flex: 'none' }}>
              <button
                className="previous-next-button"
                onClick={previousDate}
                sx={{ width: '34px', height: '22px' }}>
                <LeftArrowIcon />
              </button>
              <button
                className="previous-next-button"
                onClick={nextDate}
                sx={{ width: '34px', height: '22px' }}>
                <RightArrowIcon />
              </button>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '8px',
              mt: 3,
            }}>
            {shifts?.map((_, i) => (
              <Button
                key={i}
                onClick={() => {
                  setCurrentShift(i);
                  setSelectedSublist(i);
                }}
                sx={{
                  bgcolor:
                    i === selectedSublist
                      ? 'Var(--primary-color)'
                      : 'var(--bg-color)',
                  ':hover': {
                    bgcolor:
                      i === selectedSublist
                        ? 'Var(--primary-color)'
                        : 'Var(--primary-color)',
                    color: 'white',
                  },
                  textTransform: 'capitalize',
                  color: i === selectedSublist ? 'white' : 'var(--text-medium)',
                  fontSize: '14px',
                  height: '38px',
                  width: '100%',
                }}>
                Shift {i + 1}
              </Button>
            ))}
          </Box>

          <Box sx={{ overflowY: 'auto', mt: 4 }}>
            <Grid container spacing={1} justifyContent={'end'}>
              <Grid item lg={4} xs={6}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    In Time
                  </Typography>

                  <Typography sx={{ fontSize: '12px' }}>
                    {shifts[currentShift]?.checkIn || 'N/A'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} xs={6}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    In Time Status
                  </Typography>

                  {isEdit ? (
                    <select
                      type="text"
                      name="updateCheckInStatus"
                      value={updateData.updateCheckInStatus}
                      id="deptId"
                      style={{
                        padding: '5px 10px',
                        fontSize: '14px',
                        border: 'none',
                        borderRadius: '5px',
                        background: 'var(--primary-color)',
                        color: 'white',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      onChange={handleGetUpdateData}>
                      <option value={false}>
                        {shifts[currentShift]?.checkInStatus}
                      </option>
                      <option value={true}>updated</option>
                    </select>
                  ) : (
                    <Typography sx={{ fontSize: '12px' }}>
                      {shifts[currentShift]?.checkInStatus || 'N/A'}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item lg={4} xs={12}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    In Time Remarks
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    {shifts[currentShift]?.checkInRemarks || 'N/A'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} xs={6}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    Out Time
                  </Typography>

                  <Typography sx={{ fontSize: '12px' }}>
                    {shifts[currentShift]?.checkOut || 'N/A'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} xs={6}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    Out Time Status
                  </Typography>

                  {isEdit ? (
                    <select
                      type="text"
                      name="updateCheckOutStatus"
                      value={updateData.updateCheckOutStatus}
                      id="deptId"
                      required
                      style={{
                        padding: '5px 10px',
                        fontSize: '14px',
                        border: 'none',
                        borderRadius: '5px',
                        background: 'var(--primary-color)',
                        color: 'white',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      onChange={handleGetUpdateData}>
                      <option value={false}>
                        {shifts[currentShift]?.checkOutStatus}
                      </option>
                      <option value={true}>updated</option>
                    </select>
                  ) : (
                    <Typography sx={{ fontSize: '12px' }}>
                      {shifts[currentShift]?.checkOutStatus || 'N/A'}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item lg={4} xs={12}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    Out Time Remarks
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    {shifts[currentShift]?.checkOutRemarks || 'N/A'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={6} xs={12}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    Break Time
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>N/A</Typography>
                </Box>
              </Grid>

              <Grid item lg={6} xs={12}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    Break Time Remarks
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>N/A</Typography>
                </Box>
              </Grid>

              <Grid item lg={12} xs={12}>
                <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                  <Typography
                    sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                    Shift Remarks
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}> N/A</Typography>
                </Box>
              </Grid>

              {shifts[currentShift]?.checkInStatus === 'updated' && (
                <Grid item lg={12}>
                  <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                    <Typography
                      sx={{ color: 'var(--primary-color)', fontSize: '15px' }}>
                      Admin Remarks
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      {shifts[currentShift]?.adminRemarks}{' '}
                    </Typography>
                  </Box>
                </Grid>
              )}

              {isEdit && (
                <>
                  <Grid item lg={12}>
                    <Box sx={{ bgcolor: '#FAFAFA', p: 2, borderRadius: '4px' }}>
                      <Typography
                        sx={{
                          color: 'var(--primary-color)',
                          fontSize: '15px',
                        }}>
                        Update Remarks
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}>
                        <Typography sx={{ fontSize: '14px' }}>
                          I updated the attendance record for {employeeName} due
                          to
                        </Typography>
                        <Typography sx={{ fontSize: '14px', px: '4px' }}>
                          {updateData.updateCheckInStatus &&
                          !updateData.updateCheckOutStatus
                            ? 'late'
                            : 'forget shift'}
                        </Typography>

                        {/* <select
                          onChange={(e) =>
                            setUpdateRemarks({
                              ...updateRemarks,
                              reason: e.target.value,
                            })
                          }
                          value={updateRemarks.reason}
                          style={{
                            padding: "4px 10px",
                            fontSize: "14px",
                            border: "none",
                            borderRadius: "5px",
                            background: "var(--primary-color)",
                            color: "white",
                            cursor: "pointer",
                            width: "100px",
                            margin: "0 3px",
                          }}
                        >
                          <option value={"late"}>late</option>
                          <option value={"forget shift"}>forget shift</option>
                        </select> */}

                        {updateData.updateCheckInStatus &&
                        !updateData.updateCheckOutStatus ? (
                          <>
                            <input
                              onChange={(e) =>
                                setUpdateRemarks({
                                  ...updateRemarks,
                                  checkInTime: e.target.value,
                                })
                              }
                              style={{
                                borderRadius: '5px',
                                background: '#222222',
                                fontSize: '14px',
                                padding: '3px 10px',
                                marginLeft: '3px',
                                cursor: 'pointer',
                                color: 'white',
                              }}
                              type="time"
                            />
                            <Typography sx={{ fontSize: '14px' }}>
                              {' '}
                              with the check-in time{' '}
                            </Typography>
                          </>
                        ) : (
                          ''
                        )}

                        {updateData.updateCheckOutStatus && (
                          <>
                            <Typography sx={{ fontSize: '14px' }}>
                              {' '}
                              and the check-out time{' '}
                            </Typography>
                            <input
                              onChange={(e) =>
                                setUpdateRemarks({
                                  ...updateRemarks,
                                  checkOutTime: e.target.value,
                                })
                              }
                              style={{
                                borderRadius: '5px',
                                background: '#222222',
                                fontSize: '14px',
                                padding: '3px 10px',
                                marginLeft: '3px',
                                cursor: 'pointer',
                                color: 'white',
                              }}
                              type="time"
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item lg={4}>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                      <Button
                        disabled={isUpdating}
                        onClick={() => setIsEdit(false)}
                        sx={addButton}>
                        Cancle
                      </Button>

                      <Button
                        disabled={isUpdating}
                        onClick={handleupdateAttendance}
                        sx={{
                          bgcolor: 'Var(--primary-color)',
                          ':hover': {
                            bgcolor: 'Var(--primary-color)',
                            color: 'white',
                          },
                          textTransform: 'capitalize',
                          color: 'white',
                          fontSize: '14px',
                          height: '38px',
                          width: '100%',
                        }}>
                        {isUpdating ? (
                          <CircularProgress
                            style={{
                              color: 'white',
                              height: '20px',
                              width: '20px',
                            }}
                          />
                        ) : (
                          'Update'
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </>
      ) : (
        <Box sx={{ height: '498px' }}>
          <APILoader />
        </Box>
      )}
    </>
  );
};

export default SingleAttendenceDetails;
