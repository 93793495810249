import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from '@mui/material';

import React, { useEffect, useState } from 'react';

import secureLocalStorage from 'react-secure-storage';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import APILoader from '../../components/APILoader/APILoader';

const headers = [
  'Leave Id',
  'Employee Name',
  'Office',
  'Department',
  'Leave Type',
  'Leave From',
  'Leave To',
  'Leave Days',
  'Day Duration',
  'Days',
  'Total Sick Leaves',
  'Taken Sick Leaves',
  'Total Casual Leaves',
  'Taken Casual Leaves',
  'Attachment',
];

const Leave = () => {
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [leaveData, setLeaveData] = useState([]);

  useEffect(() => {
    fetch(
      `https://flyfar-hrm-pro.de.r.appspot.com/api/company/leave-requests`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setLeaveData(data?.data);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  console.log(leaveData);
  return (
    <Box>
      {leaveData.length > 0 ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: '25px',
              justifyContent: 'space-between',
            }}>
            <Typography variant="title" className="page-title">
              Leave Request
            </Typography>
          </Box>

          {isMobile && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: isMobile && 3,
              }}>
              {leaveData?.map((data, i) => (
                <Box
                  key={i}
                  onClick={() => {
                    navigate('/dashboard/employeeLeaveRequest', {
                      state: {
                        id: data?.id,
                      },
                    });
                  }}
                  sx={{
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    px: 2,
                    py: 1,
                    borderRadius: '5px',
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#f5f5f5',
                    color: '#000',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      pb: 1.5,
                    }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography>{data?.branchName}</Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Employee Name
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.firstname} {data?.lastname}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Department Name
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.deptName || 'N/A'}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Leave Type</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.leaveType || 'N/A'}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Leave From</Typography>
                      <Typography sx={attendFontStyle}>
                        {moment(data?.leaveFrom).format('D MMM, YYYY')}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Leave To</Typography>
                      <Typography sx={attendFontStyle}>
                        {moment(data?.leaveTo).format('D MMM, YYYY')}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Leave Days</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.leaveDays || 'N/A'}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Day Duration</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.dayDuration || 'N/A'}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Duration</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.duration || 'N/A'}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Total Sick Leaves
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.totalSickLeaves}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        ...flexStyle,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Taken Sick Leaves
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.takenSickLeaves}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        ...flexStyle,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Total Casual Leaves
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.totalCasualLeaves}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        ...flexStyle,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Taken Casual Leaves
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.takenCasualLeaves}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        ...flexStyle,
                      }}>
                      <Typography sx={attendFontStyle}>Attachment</Typography>
                      <Typography sx={attendFontStyle}>View</Typography>
                    </Box>
                    <Divider />
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {!isMobile && (
            <Box mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 2050 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header, i) => (
                        <TableCell key={i}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaveData.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <span
                            onClick={() => {
                              navigate('/dashboard/employeeLeaveRequest', {
                                state: {
                                  id: data?.id,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: '#dadce0',
                              padding: '3px 5px',
                              cursor: 'pointer',
                            }}>
                            {data?.customId}
                          </span>
                        </TableCell>
                        <TableCell>
                          {data?.firstname} {data?.lastname}
                        </TableCell>
                        <TableCell>{data?.branchName || 'N/A'}</TableCell>

                        <TableCell>{data?.deptName || 'N/A'}</TableCell>
                        <TableCell>{data?.leaveType || 'N/A'}</TableCell>

                        <TableCell>
                          {moment(data?.leaveFrom).format('D MMM, YYYY')}
                        </TableCell>
                        <TableCell>
                          {moment(data?.leaveTo).format('D MMM, YYYY')}
                        </TableCell>

                        <TableCell>{data?.leaveDays || 'N/A'}</TableCell>

                        <TableCell>{data?.dayDuration || 'N/A'}</TableCell>

                        <TableCell>{data?.duration || 'N/A'}</TableCell>

                        <TableCell>{data?.totalSickLeaves}</TableCell>

                        <TableCell>{data?.takenSickLeaves}</TableCell>

                        <TableCell>{data?.totalCasualLeaves}</TableCell>

                        <TableCell>{data?.takenCasualLeaves}</TableCell>

                        <TableCell>View</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default Leave;
const flexStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 1,
};

const attendFontStyle = {
  fontSize: '13px',
};
