import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PersonalInfoDetails from './EmployeeDetailsTabs/PersonalInfoDetails';
import PersonalContactDetails from './EmployeeDetailsTabs/PersonalContactDetails';
import OfficeInfoDetails from './EmployeeDetailsTabs/OfficeInfoDetails';
import SalaryInfoDetails from './EmployeeDetailsTabs/SalaryInfoDetails';
import EquipmentInfoDetails from './EmployeeDetailsTabs/EquipmentInfoDetails';
import { useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EmployeeDetails = () => {
  const location = useLocation();
  const isMobile = window.innerWidth <= 768;
  const employeeId = location?.state?.employeeId;
  const user = secureLocalStorage.getItem('admin-info');
  const [selectedComponent, setSelectedComponent] = useState(
    'Personal Information',
  );
  const [value, setValue] = useState('1');
  const [employeeDetails, setemployeeDetails] = useState([]);
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const token = user?.accessToken;
    fetch(
      `https://flyfar-hrm-pro.de.r.appspot.com/api/company/employees?id=${employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        setemployeeDetails(data?.data);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [employeeId]);

  const handleDropdownChange = (component) => {
    setSelectedComponent(component);
    setOpen(false);
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Personal Information':
        return <PersonalInfoDetails employeeDetails={employeeDetails} />;
      case 'Personal Contact Information':
        return <PersonalContactDetails employeeDetails={employeeDetails} />;
      case 'Official Information':
        return <OfficeInfoDetails employeeDetails={employeeDetails} />;
      case 'Salary Information':
        return <SalaryInfoDetails employeeDetails={employeeDetails} />;
      case 'Equipment Information':
        return <EquipmentInfoDetails employeeDetails={employeeDetails} />;
      default:
        return <PersonalInfoDetails employeeDetails={employeeDetails} />;
    }
  };

  const menuItems = [
    { label: 'Personal Information' },
    { label: 'Personal Contact Information' },
    { label: 'Official Information' },
    { label: 'Salary Information' },
    { label: 'Equipment Information' },
  ];

  return (
    <Box>
      <Box ml={1}>
        <Typography
          sx={{ fontSize: isMobile ? '17px' : '26px', fontWeight: '600' }}
          className="page-title">
          Employee Details
        </Typography>
      </Box>

      {isMobile ? (
        <Box sx={{ position: 'relative', mt: 2 }}>
          <Box
            onClick={handleToggle}
            sx={{
              bgcolor: 'var(--primary-color)',
              borderRadius: '5px',
              mb: 0,
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ color: 'white', px: 1.5, py: 1 }}>
                {selectedComponent}
              </Typography>
              <ExpandMoreIcon
                sx={{
                  color: 'white',
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </Box>
          </Box>
          <Collapse
            sx={{
              bgcolor: 'var(--primary-color)',
              borderRadius: '5px',
              py: 1,
              position: 'absolute',
              width: '100%',
              zIndex: '10',
            }}
            in={open}>
            {menuItems?.map((item, idx) => (
              <Box key={idx} sx={{ py: 0.5, px: 1 }}>
                <Typography
                  onClick={() => handleDropdownChange(item.label)}
                  sx={{
                    color: 'white',
                    px: 1,
                  }}>
                  {item?.label}
                </Typography>
              </Box>
            ))}
          </Collapse>

          <Box mt={-2}>{renderComponent()}</Box>
        </Box>
      ) : (
        <Box mt={4}>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                indicatorColor="none"
                textColor="white"
                aria-label="home-tab"
                sx={{
                  display: 'flex',
                  borderBottom: 'none',
                }}>
                <Tab
                  sx={{
                    marginX: 1,
                    opacity: '1',
                    backgroundColor: 'var( --gray)',
                    color: 'var(--black)',

                    height: '30px',
                    width: 'fit-content',
                    minHeight: '10px',
                    paddingX: 1,
                    fontSize: { xs: '8px', sm: '10px', md: '12px' },
                    textTransform: 'Capitalize',
                    '&.Mui-selected': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'white',

                      opacity: '1',
                    },
                  }}
                  label="Personal Information"
                  value="1"
                />
                <Tab
                  label="Personal Contact Information"
                  value="2"
                  sx={{
                    marginX: 1,
                    opacity: '1',
                    backgroundColor: 'var( --gray)',
                    color: 'var(--black)',

                    height: '30px',
                    width: 'fit-content',
                    minHeight: '10px',
                    paddingX: 1,
                    fontSize: { xs: '8px', sm: '10px', md: '12px' },
                    textTransform: 'Capitalize',
                    '&.Mui-selected': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'white',

                      opacity: '1',
                    },
                  }}
                />
                <Tab
                  label="Official Information"
                  value="3"
                  sx={{
                    marginX: 1,
                    opacity: '1',
                    backgroundColor: 'var( --gray)',
                    color: 'var(--black)',

                    height: '30px',
                    width: 'fit-content',
                    minHeight: '10px',
                    paddingX: 1,
                    fontSize: { xs: '8px', sm: '10px', md: '12px' },
                    textTransform: 'Capitalize',
                    '&.Mui-selected': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'white',

                      opacity: '1',
                    },
                  }}
                />
                <Tab
                  label="Salary Information"
                  value="4"
                  sx={{
                    marginX: 1,
                    opacity: '1',
                    backgroundColor: 'var( --gray)',
                    color: 'var(--black)',

                    height: '30px',
                    width: 'fit-content',
                    minHeight: '10px',
                    paddingX: 1,
                    fontSize: { xs: '8px', sm: '10px', md: '12px' },
                    textTransform: 'Capitalize',
                    '&.Mui-selected': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'white',

                      opacity: '1',
                    },
                  }}
                />
                <Tab
                  label="Equipment Information"
                  value="5"
                  sx={{
                    marginX: 1,
                    opacity: '1',
                    backgroundColor: 'var( --gray)',
                    color: 'var(--black)',

                    height: '30px',
                    width: 'fit-content',
                    minHeight: '10px',
                    paddingX: 1,
                    fontSize: { xs: '8px', sm: '10px', md: '12px' },
                    textTransform: 'Capitalize',
                    '&.Mui-selected': {
                      backgroundColor: 'var(--primary-color)',
                      color: 'white',
                      opacity: '1',
                    },
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <PersonalInfoDetails employeeDetails={employeeDetails} />{' '}
            </TabPanel>
            <TabPanel value="2">
              {' '}
              <PersonalContactDetails employeeDetails={employeeDetails} />
            </TabPanel>
            <TabPanel value="3">
              <OfficeInfoDetails employeeDetails={employeeDetails} />
            </TabPanel>
            <TabPanel value="4">
              <SalaryInfoDetails employeeDetails={employeeDetails} />
            </TabPanel>
            <TabPanel value="5">
              <EquipmentInfoDetails employeeDetails={employeeDetails} />
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </Box>
  );
};

export default EmployeeDetails;
