import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  colors,
  Button,
  CircularProgress,
  Dialog,
  Zoom,
  Divider,
  SwipeableDrawer,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';

const headers = [
  'Shift Count',
  'Deduction for Shift 1',
  'Deduction for Shift 2',
  'Deduction for Shift 3',
  'Deduction for Shift 4',

  'Action',
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const AbsentRules = () => {
  const [absentData, setAbsentData] = useState([]);
  const isMobile = window.innerWidth <= 768;
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedShiftCount, setSelectedShiftCount] = useState({});
  const [updateForm, setUpdateForm] = useState({ shift: '', penalty: '' });
  const [shiftData, setShiftData] = useState([]);

  useEffect(() => {
    fetch(
      `https://flyfar-hrm-pro.de.r.appspot.com/api/company/salary-rules/absent`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch datas');
        }
        return res.json();
      })
      .then((data) => {
        setAbsentData(data?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleOpen = (data) => {
    console.log(data);
    setShiftData(data?.penalties);

    setOpen(true);
    setSelectedShiftCount(data?.shiftCount);
  };

  const handleClose = () => setOpen(false);

  console.log(absentData);

  const handleOnChange = (value, penalty) => {
    console.log(value, penalty);
    const updateData = shiftData.map((obj) => {
      if (Number(obj.shift) === value) {
        return {
          ...obj,
          penalty: Number(penalty),
        };
      } else return obj;
    });

    console.log(updateData);
    setShiftData(updateData);
  };
  return (
    <Box>
      <Typography variant="title" className="page-title">
        Absent Rules
      </Typography>
      <Box mt={3}>
        {!isMobile ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1450 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headers.map((header, i) => (
                    <TableCell key={i}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {absentData?.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        sx={{
                          background: 'var(--primary-color)',
                          width: 'fit-content',
                          p: 0.5,
                          color: 'white',
                          borderRadius: '5px',
                        }}>
                        Shift {data?.shiftCount}
                      </Typography>
                    </TableCell>
                    {[...Array(4)].map((_, idx) => (
                      <TableCell key={idx}>
                        {data?.penalties[idx]?.penalty
                          ? `${data.penalties[idx].penalty}%`
                          : '- -'}
                      </TableCell>
                    ))}

                    <TableCell>
                      {' '}
                      <Button
                        variant="contained"
                        sx={{
                          marginLeft: '10px',
                          background: 'var(--primary-color)',
                          '&:hover': {
                            background: 'var(--primary-color)',
                          },
                        }}
                        onClick={() => {
                          handleOpen(data);
                        }}>
                        <Typography sx={{ fontSize: '12px', color: 'white' }}>
                          Update
                        </Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 5 }}>
            {absentData?.map((data, idx) => (
              <Box
                key={idx}
                // onClick={() =>
                //   navigate("/dashboard/deptSalary", {
                //     state: {
                //       branchId: salary?.branchId,
                //     },
                //   })
                // }
                sx={{
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  px: 2,
                  py: 1,
                  borderRadius: '5px',
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#f5f5f5',
                  color: '#000',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    pb: 1.5,
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <Typography sx={attendFontStyle}>Shift Count</Typography>
                    <Typography sx={attendFontStyle}>
                      {data?.shiftCount}
                    </Typography>
                  </Box>
                  <Divider />
                  {[...Array(4)].map((_, idx) => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Deduction for Shift {idx}
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.penalties[idx]?.penalty
                          ? `${data.penalties[idx].penalty}%`
                          : '- -'}
                      </Typography>
                    </Box>
                  ))}

                  <Divider />

                  <Box sx={{ display: 'flex', mt: 1.5 }}>
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        marginLeft: '10px',
                        background: 'var(--primary-color)',
                        '&:hover': {
                          background: 'var(--primary-color)',
                        },
                      }}
                      onClick={() => {
                        handleOpen(data);
                      }}>
                      <Typography sx={{ fontSize: '12px', color: 'white' }}>
                        Update
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          position: 'relative',

          width: '100%',
          height: '70vh',
        }}>
        <Link to="/dashboard/setAbsentRules">
          {' '}
          <Box
            sx={{
              position: 'absolute',
              right: '0',
              bottom: '0',
              width: '55px',
              height: '55px',
              backgroundColor: 'var(--primary-color)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
            <AddIcon width={'25px'} height={'25px'} style={{ fill: 'white' }} />
          </Box>
        </Link>
      </Box>

      {!isMobile ? (
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          maxWidth="md"
          fullWidth>
          <Box
            sx={{
              input: {
                border: 'none',
                backgroundColor: 'var( --input-bgcolor)',
                padding: '10px 8px 10px 8px',
                width: '100%',
              },
              p: 3,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
              },
            }}>
            <Typography
              sx={{
                fontFamily: 'poppins',
                fontWeight: '600px',
                fontSize: '22px',
                color: '#222222',
              }}
              mb={2}>
              Update Late count Information
            </Typography>
            <form>
              {Array.from(
                { length: selectedShiftCount },
                (_, index) => index + 1,
              ).map((value) => {
                return (
                  <Box className="input-container" mt={2}>
                    {console.log('hola', value, shiftData)}
                    <label htmlFor="homeAllowance" className="input-label">
                      <span> For Shift {value}</span>
                    </label>
                    <input
                      name="deductAmount"
                      id="deductAmount"
                      value={shiftData[value - 1].penalty}
                      onChange={(e) => handleOnChange(value, e.target.value)}
                      className="input-field"
                      type="number"
                      placeholder="Deduction amount "
                    />
                  </Box>
                );
              })}

              <Box sx={{ textAlign: 'end' }}>
                <Button
                  sx={{
                    fontFamily: 'poppins',
                    fontWeight: '400',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    borderRadius: '2px',
                    bgcolor: 'var(--text-medium)',
                    ':hover': {
                      bgcolor: 'var(--text-medium)',
                    },
                    color: '#FFFFFF',
                    mt: 3,
                  }}
                  type="submit">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          onOpen={() => {}}>
          <Box
            sx={{
              input: {
                border: 'none',
                backgroundColor: 'var(--input-bgcolor)',
                padding: '10px 8px',
                width: '100%',
              },
              p: 3,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
              },
            }}>
            <Typography
              sx={{
                fontFamily: 'poppins',
                fontWeight: '600',
                fontSize: '22px',
                color: '#222222',
              }}
              mb={2}>
              Update Late Count Information
            </Typography>
            <form>
              {Array.from(
                { length: selectedShiftCount },
                (_, index) => index + 1,
              ).map((value) => (
                <Box className="input-container" mt={2} key={value}>
                  <Grid container>
                    <Grid item xs={6}>
                      <label
                        htmlFor={`deductAmount-${value}`}
                        className="input-label"
                        style={{ width: '100%' }}>
                        <span>For Shift {value}</span>
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      <input
                        name="deductAmount"
                        id={`deductAmount-${value}`}
                        value={shiftData[value - 1]?.penalty || ''}
                        onChange={(e) => handleOnChange(value, e.target.value)}
                        className="input-field"
                        type="number"
                        placeholder="Deduction amount"
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Box sx={{ textAlign: 'end' }}>
                <Button
                  sx={{
                    width: '100%',
                    fontFamily: 'poppins',
                    fontWeight: '400',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    borderRadius: '2px',
                    bgcolor: 'var(--text-medium)',
                    ':hover': {
                      bgcolor: 'var(--text-medium)',
                    },
                    color: '#FFFFFF',
                    mt: 3,
                  }}
                  type="submit">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default AbsentRules;

const attendFontStyle = {
  fontSize: '13px',
};
