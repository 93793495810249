import {
  Box,
  Button,
  Dialog,
  Grid,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import APILoader from '../../components/APILoader/APILoader';
import moment from 'moment';
import SingleShiftUpdate from './components/SingleShiftUpdate';
import { selectStyle } from '../../components/Holiday/style';
import { Height } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
};

const labels = [
  'Employee Name',
  'Saturday',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

const UpdateShifts = () => {
  const isMobile = window.innerWidth <= 768;
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [initialShift, setInitialShift] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [allBranch, setAllBranch] = useState([]);
  const [allDept, setAllDept] = useState([]);
  const [selectedDept, setSelectedDept] = useState({
    id: '',
    deptName: '',
  });
  const [selectedBranch, setSelectedBranch] = useState({
    id: '',
    branchName: '',
  });

  useEffect(() => {
    setIsLoaded(false);
    const url = 'https://flyfar-hrm-pro.de.r.appspot.com/api/company/branches';

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        setIsLoaded(true);
        setAllBranch(data?.data);
        setSelectedBranch({
          id: data?.data[0]?.id,
          branchName: data?.data[0]?.branchName,
        });
      })
      .catch((error) => {
        setIsLoaded(true);
        console.error('Error fetching departments:', error);
      });
  }, [token]);

  useEffect(() => {
    setIsLoaded(false);
    const url =
      'https://flyfar-hrm-pro.de.r.appspot.com/api/company/departments';

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        setIsLoaded(true);
        setAllDept(data?.data);
        setSelectedDept({
          id: data?.data[0]?.id,
          deptName: data?.data[0]?.deptName,
        });
      })
      .catch((error) => {
        setIsLoaded(true);
        console.error('Error fetching departments:', error);
      });
  }, [token]);

  useEffect(() => {
    setIsLoaded(false);
    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/shifts/employees`;

    // const url = "http://192.168.1.103:5000/api/company/shifts/employees";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        setIsLoaded(true);
        const updateData = data?.data?.map((d) => {
          return {
            ...d,
            show: 'active',
          };
        });
        setShiftData(
          updateData?.map((d) => {
            return {
              ...d,
              allDays: d.allDays?.map((ad) => {
                return {
                  ...ad,
                  shifts: ad.shifts.filter((s) => s?.status === 'active'),
                };
              }),
            };
          }),
        );

        setInitialShift(updateData);
      })
      .catch((error) => {
        setIsLoaded(true);
        console.error('Error fetching departments:', error);
      });
  }, [token, isRefetch]);

  const handleFilterByStatus = (status, index) => {
    setShiftData(
      initialShift
        .filter(
          (s) =>
            s.branchId === selectedBranch?.id && s.deptId === selectedDept?.id,
        )
        ?.map((d, i) => {
          if (index === i) {
            return {
              ...d,
              allDays: d.allDays?.map((ad) => {
                return {
                  ...ad,
                  shifts: ad.shifts.filter((s) => s?.status === status),
                };
              }),
              show: status,
            };
          } else {
            return {
              ...d,
              allDays: d.allDays?.map((ad) => {
                return {
                  ...ad,
                  shifts: ad.shifts.filter((s) => s?.status === d.show),
                };
              }),
              show: d.show,
            };
          }
        }),
    );
  };

  const handleDeptChange = (event) => {
    setSelectedDept({
      id: event.target.value,
      deptName:
        allDept.find((dept) => dept.id === event.target.value)?.deptName || '',
    });
  };

  const handleBranchChange = (event) => {
    setSelectedBranch({
      id: event.target.value,
      branchName:
        allBranch.find((br) => br.id === event.target.value)?.branchName || '',
    });
  };

  const filterShift = shiftData.filter(
    (s) => s.branchId === selectedBranch?.id && s.deptId === selectedDept?.id,
  );
  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Box>
      {isLoaded ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isMobile && 'column',
            }}>
            <Typography
              variant="title"
              className="page-title"
              sx={{
                fontSize: isMobile ? '16px' : '22px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}>
              Employee Shift Update
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                mt: isMobile && 2,
                flexDirection: isMobile && 'column',
              }}>
              <select
                type="text"
                name="deptId"
                id="deptId"
                required
                style={selectStyle}
                onChange={handleBranchChange}>
                {allBranch.map((data, index) => (
                  <option
                    selected={data.id === selectedBranch.id}
                    key={index}
                    value={data.id}>
                    {data.branchName}
                  </option>
                ))}
              </select>
              <select
                type="text"
                name="deptId"
                id="deptId"
                required
                style={selectStyle}
                onChange={handleDeptChange}>
                {allDept.map((data, index) => (
                  <option
                    selected={data.id === selectedDept.id}
                    key={index}
                    value={data.id}>
                    {data.deptName}
                  </option>
                ))}
              </select>
            </Box>
          </Box>

          {!isMobile ? (
            <Box>
              <Grid id="header" container spacing={'6px'} mt={3}>
                {labels?.map((label, i) => (
                  <Grid key={i} item md={12 / 8}>
                    <Box
                      sx={{
                        bgcolor: i > 0 ? '#263238' : 'var(--primary-color)',
                        height: '61px',
                        ...boxStyle,
                      }}>
                      <Typography sx={{ fontSize: '14px', color: 'white' }}>
                        {label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              {filterShift?.map((data, i) => {
                // console.log(data);
                return (
                  <Grid key={i} container spacing={'6px'} mt={'0'}>
                    <Grid item md={12 / 8}>
                      <Box
                        sx={{
                          bgcolor: '#263238',
                          borderRadius: '4px',
                          height: '125px',
                          p: 1.1,
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            gap: '40px',
                          }}>
                          <Box sx={{ height: '45px' }}>
                            <Typography
                              sx={{ fontSize: '14px', color: 'white' }}>
                              {data?.firstname} {data?.lastname}
                            </Typography>
                            <Typography
                              sx={{ fontSize: '12px', color: '#969696' }}>
                              {data?.designation}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              height: '35px',
                              bgcolor: 'white',
                              borderRadius: '50px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <Button
                              onClick={() => handleFilterByStatus('active', i)}
                              sx={{
                                bgcolor:
                                  data?.show === 'active' ? '#263238' : 'white',
                                fontSize: '10px',
                                borderRadius: '50px',
                                height: '25px',
                                textTransform: 'capitalize',
                                color:
                                  data?.show === 'active' ? 'white' : '#263238',
                                ':hover': {
                                  bgcolor:
                                    data?.show === 'active'
                                      ? '#263238'
                                      : 'white',
                                },
                                padding: '6px 14px',
                              }}>
                              Current
                            </Button>
                            <Button
                              onClick={() => handleFilterByStatus('pending', i)}
                              sx={{
                                bgcolor:
                                  data?.show === 'pending'
                                    ? '#263238'
                                    : 'white',
                                fontSize: '10px',
                                borderRadius: '50px',
                                height: '25px',
                                textTransform: 'capitalize',
                                color:
                                  data?.show === 'pending'
                                    ? 'white'
                                    : '#263238',
                                ':hover': {
                                  bgcolor:
                                    data?.show === 'pending'
                                      ? '#263238'
                                      : 'white',
                                },
                                padding: '6px 14px',
                              }}>
                              Pending
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    {data?.allDays?.map((day, index) => (
                      <Grid key={index} item md={12 / 8}>
                        <Box
                          sx={{
                            border: '1px solid var(--light-pr-clr)',
                            height: '125px',
                            borderRadius: '4px',
                            p: 1,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setOpen(true);
                            setCurrentData({
                              employee: {
                                branchId: data?.branchId,
                                deptId: data?.deptId,
                                employeeId: data?.employeeId,
                                firstname: data?.firstname,
                                lastname: data?.lastname,
                              },
                              shiftData: day,
                            });
                          }}>
                          {day?.shifts?.map((shift, shiftIndex, arr) => {
                            return (
                              <Box
                                key={shiftIndex}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  mb: 1,
                                }}>
                                {shift?.dayState === 'weekend' ? (
                                  <Box
                                    sx={{
                                      bgcolor: '#E02028',
                                      height: `calc(125px / ${arr.length})`,
                                      ...boxStyle,
                                    }}>
                                    <Typography
                                      sx={{
                                        fontSize: '12px',
                                        color: 'white',
                                        textTransform: 'capitalize',
                                        fontFamily: 'productSans500 !important',
                                      }}>
                                      {shift?.dayState} <br />
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      bgcolor:
                                        shiftIndex === 0
                                          ? '#6FABF2'
                                          : shiftIndex === 1
                                          ? '#FFA84D'
                                          : shiftIndex === 2
                                          ? '#61A143'
                                          : '#405687',

                                      height: `calc(125px / 5)`,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      px: '12px',
                                      borderRadius: '15px',
                                    }}>
                                    <Typography
                                      sx={{
                                        fontSize: '10.5px',
                                        color: 'white',
                                      }}>
                                      {moment(
                                        shift?.roaster?.checkIn,
                                        'HH:mm:ss',
                                      ).format('hh:mm A')}{' '}
                                      -{' '}
                                      {moment(
                                        shift?.roaster?.checkOut,
                                        'HH:mm:ss',
                                      ).format('hh:mm A')}
                                      {/* {shift?.status} */}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                );
              })}
            </Box>
          ) : (
            <TableContainer
              sx={{ mt: 3, bgcolor: 'white', borderRadius: '8px' }}>
              <Table>
                {/* Table Header */}
                <TableHead id="header">
                  <TableRow>
                    {labels?.map((label, i) => (
                      <TableCell
                        key={i}
                        sx={{
                          bgcolor: i > 0 ? '#263238' : 'var(--primary-color)',

                          fontSize: '14px',
                          textAlign: 'center',
                        }}>
                        <Typography sx={{ color: 'white' }}>{label}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {filterShift?.map((data, i) => (
                    <React.Fragment key={i}>
                      {/* Employee Info Row */}
                      <TableRow>
                        <TableCell
                          sx={{
                            bgcolor: '#263238',
                            color: 'white',
                            fontSize: '14px',
                            textAlign: 'center',
                            borderBottom: 'none',
                            width: '100px',
                            height: '100px',
                          }}>
                          <Box>
                            <Box>
                              <Typography sx={{ color: 'white' }}>
                                {data?.firstname} {data?.lastname}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: isMobile ? '10px' : '12px',
                                  color: '#969696',
                                }}>
                                {data?.designation}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 1,
                                gap: 1,
                                bgcolor: 'white',
                                borderRadius: '50px',
                              }}>
                              {['active', 'pending']?.map((status) => (
                                <Button
                                  key={status}
                                  onClick={() =>
                                    handleFilterByStatus(status, i)
                                  }
                                  sx={{
                                    bgcolor:
                                      data?.show === status
                                        ? '#263238'
                                        : 'white',
                                    color:
                                      data?.show === status
                                        ? 'white'
                                        : '#263238',
                                    fontSize: '10px',
                                    borderRadius: '50px',
                                    textTransform: 'capitalize',
                                    ':hover': {
                                      bgcolor:
                                        data?.show === status
                                          ? '#263238'
                                          : 'white',
                                    },
                                    px: 2,
                                  }}>
                                  {status === 'active' ? 'Current' : 'Pending'}
                                </Button>
                              ))}
                            </Box>
                          </Box>
                        </TableCell>

                        {/* Shift Days */}
                        {data?.allDays?.map((day, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              border: '1px solid var(--light-pr-clr)',
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              setOpen(true);
                              setCurrentData({
                                employee: {
                                  branchId: data?.branchId,
                                  deptId: data?.deptId,
                                  employeeId: data?.employeeId,
                                  firstname: data?.firstname,
                                  lastname: data?.lastname,
                                },
                                shiftData: day,
                              });
                            }}>
                            {day?.shifts?.map((shift, shiftIndex, arr) => (
                              <Box
                                key={shiftIndex}
                                sx={{
                                  bgcolor:
                                    shift?.dayState === 'weekend'
                                      ? '#E02028'
                                      : shiftIndex === 0
                                      ? '#6FABF2'
                                      : shiftIndex === 1
                                      ? '#FFA84D'
                                      : shiftIndex === 2
                                      ? '#61A143'
                                      : '#405687',
                                  color: 'white',
                                  fontSize: '10.5px',
                                  ...boxStyle,
                                  width: '100px',
                                  height: '100px',
                                  borderRadius:
                                    shift?.dayState !== 'weekend'
                                      ? '15px'
                                      : '4px',
                                  px: 1,
                                  mb: 1,
                                }}>
                                {shift?.dayState === 'weekend' ? (
                                  <Typography
                                    sx={{ textTransform: 'capitalize' }}>
                                    {shift?.dayState}
                                  </Typography>
                                ) : (
                                  `${moment(
                                    shift?.roaster?.checkIn,
                                    'HH:mm:ss',
                                  ).format('hh:mm A')} - ${moment(
                                    shift?.roaster?.checkOut,
                                    'HH:mm:ss',
                                  ).format('hh:mm A')}`
                                )}
                              </Box>
                            ))}
                          </TableCell>
                        ))}
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}

      {!isMobile ? (
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            setCurrentData({});
          }}
          TransitionComponent={Transition}
          maxWidth="md"
          fullWidth>
          <Box sx={{ p: 3, minHeight: '380px' }}>
            <SingleShiftUpdate
              currentData={currentData}
              setCurrentData={setCurrentData}
              setOpen={setOpen}
              isRefetch={isRefetch}
              setIsRefetch={setIsRefetch}
            />
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={() => {
            setOpen(false);
            setCurrentData({});
          }}
          onOpen={() => {}}>
          <Box sx={{ p: 3, minHeight: '380px' }}>
            {open && (
              <SingleShiftUpdate
                currentData={currentData}
                setCurrentData={setCurrentData}
                setOpen={setOpen}
                isRefetch={isRefetch}
                setIsRefetch={setIsRefetch}
              />
            )}
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default UpdateShifts;
