import {
  Box,
  Typography,
  Dialog,
  Zoom,
  Button,
  Grid,
  SwipeableDrawer,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const inputStyle = {
  container: {
    backgroundColor: '#FAFAFA',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'block',
    padding: '10px',
  },

  label: {
    color: 'var(--primary-color)',
    fontSize: '12px',
    cursor: 'pointer',
  },
  input: {
    backgroundColor: 'inherit',
    marginTop: '5px',
    paddingLeft: '0',
    fontSize: '14px',
    cursor: 'pointer',
    width: '100%',
  },
};

const Holiday = () => {
  const isMobile = window.innerWidth <= 768;
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [addData, setAddData] = useState({
    title: '',
    start: '',
    end: '',
    backgroundColor: '',
    borderColor: '',
    description: '',
  });
  const [oepnUpdateId, setOpenUpdateId] = useState(null);
  const [holidayUpdateData, setHolidayUpdateData] = useState({});
  const calanderRef = useRef();

  useEffect(() => {
    fetch(`https://flyfar-hrm-pro.de.r.appspot.com/api/company/holidays`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        setEventData(
          data?.data.map((d) => {
            const endDate = moment(d.end);
            const afterDate = endDate.add(1, 'day').format('YYYY-MM-DD');
            return { ...d, end: afterDate };
          }),
        );
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [token]);

  const handleDateClick = (arg) => {
    // console.log(arg);
    setAddData({
      ...addData,
      start: arg.startStr,
      end: arg.endStr,
    });
    setOpen(true);
  };

  const handleSubmit = async () => {
    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/holidays`;

    const previousDate = moment(addData.end)
      .subtract(1, 'day')
      .format('YYYY-MM-DD');

    try {
      setLoading(true);

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          ...addData,
          end: previousDate,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      // console.log(data);
      setOpen(false);
      if (data?.success === true) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          window.location.reload();
        });
      } else {
        console.log(data);
        Swal.fire({
          icon: 'error',
          title: data?.error?.message || 'Something went wrong',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Try again',
        }).then(function () {
          setOpen(true);
          // navigate("/dashboard/employee");
        });
        throw new Error(data?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputValue = (e) => {
    setHolidayUpdateData({
      ...holidayUpdateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = async () => {
    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/holidays/${holidayUpdateData.id}`;

    try {
      setLoading(true);

      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      setOpenUpdateId(null);
      if (data?.success === true) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: data?.error?.message || 'Something went wrong',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Try again',
        }).then(function () {
          setOpenUpdateId(holidayUpdateData.id);
        });
        throw new Error(data?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/holidays/${holidayUpdateData.id}`;

    try {
      setLoading(true);

      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify({
          start: holidayUpdateData.start,
          end: holidayUpdateData.end,
          title: holidayUpdateData.title,
          description: holidayUpdateData.description,
          backgroundColor: holidayUpdateData.backgroundColor,
          borderColor: holidayUpdateData.borderColor,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      setOpenUpdateId(null);
      if (data?.success === true) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: data?.error?.message || 'Something went wrong',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Try again',
        }).then(function () {
          setOpenUpdateId(holidayUpdateData.id);
        });
        throw new Error(data?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        '.fc-day': {
          border: '1px solid #ddd',
        },
        '.fc-daygrid-day-frame:hover': {
          backgroundColor: '#f4f5f7',
          // border: "1px solid green",
        },

        '.fc-daygrid-day-number': {
          fontSize: '18px',
        },

        '.fc-daygrid-day-frame': {
          height: '100px',
        },

        '.fc-button-group': {
          gap: '5px',
        },

        '.fc-col-header-cell-cushion': {
          height: '40px',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          color: 'var(--text-medium)',
        },

        '.fc-button-primary': {
          backgroundColor: 'var(--primary-color)',
          border: 'none',
        },
      }}>
      <Box>
        <Typography variant="title" className="page-title">
          Holiday
        </Typography>
      </Box>

      <Box sx={{ width: isMobile ? '250%' : '100%' }}>
        <FullCalendar
          className="calendar-container"
          contentHeight="auto"
          ref={calanderRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={eventData}
          businessHours={{ daysOfWeek: [0, 1, 2, 3, 4, 6] }}
          firstDay={6}
          headerToolbar={{
            start: 'title',
            center: '',
            end: 'prev,next',
          }}
          selectable={true}
          select={handleDateClick}
          eventClick={(info) => {
            setOpenUpdateId(info.event._def?.publicId);
            const holidayData = eventData.find(
              (data) => data.id === info.event._def?.publicId,
            );
            setHolidayUpdateData({
              ...holidayData,
              end: moment(holidayData.end)
                .subtract(1, 'day')
                .format('YYYY-MM-DD'),
            });
          }}
        />
      </Box>

      {!isMobile ? (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}
          maxWidth="xs"
          fullWidth>
          <Box p={3}>
            <Typography sx={{ fontSize: '18px' }}>
              {moment(addData.end).diff(moment(addData.start), 'days') < 2 ? (
                <>
                  Add Holiday For {moment(addData.start).format('DD-MM-YYYY')}
                </>
              ) : (
                <>
                  Add Holiday From {moment(addData.start).format('DD-MM-YYYY')}{' '}
                  To{' '}
                  {moment(addData.end).subtract(1, 'days').format('DD-MM-YYYY')}
                </>
              )}
            </Typography>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontSize: '16px' }}>Title</Typography>
              <input
                placeholder="Enter Title"
                type="text"
                style={{ width: '70%' }}
                value={addData.title}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    title: e.target.value,
                  });
                }}
              />
            </Box>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontSize: '16px' }}>Description</Typography>
              <input
                placeholder="Enter Description"
                type="text"
                style={{ width: '70%' }}
                value={addData.description}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    description: e.target.value,
                  });
                }}
              />
            </Box>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontSize: '16px' }}>Color</Typography>
              <input
                placeholder="Enter Color"
                type="color"
                defaultValue={'#bf5122'}
                style={{ width: '70%' }}
                // value={addData.backgroundColor}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    backgroundColor: e.target.value,
                    borderColor: e.target.value,
                  });
                }}
              />
            </Box>

            <Box sx={{ textAlign: 'end' }}>
              <Button
                disabled={loading}
                sx={{
                  background: 'var(--primary-color)',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: 'var(--primary-color)',
                  },
                  mt: 2,
                }}
                onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => {}}>
          <Box p={3} sx={{ minHeight: '350px' }}>
            <Typography sx={{ fontSize: '18px' }}>
              {moment(addData.end).diff(moment(addData.start), 'days') < 2 ? (
                <>
                  Add Holiday For {moment(addData.start).format('DD-MM-YYYY')}
                </>
              ) : (
                <>
                  Add Holiday From {moment(addData.start).format('DD-MM-YYYY')}{' '}
                  To{' '}
                  {moment(addData.end).subtract(1, 'days').format('DD-MM-YYYY')}
                </>
              )}
            </Typography>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontSize: '16px' }}>Title</Typography>
              <input
                placeholder="Enter Title"
                type="text"
                style={{ width: '70%' }}
                value={addData.title}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    title: e.target.value,
                  });
                }}
              />
            </Box>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontSize: '16px' }}>Description</Typography>
              <input
                placeholder="Enter Description"
                type="text"
                style={{ width: '70%' }}
                value={addData.description}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    description: e.target.value,
                  });
                }}
              />
            </Box>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                borderBottom: '1px solid var(--border-color)',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ fontSize: '16px' }}>Color</Typography>
              <input
                type="color"
                defaultValue={'#bf5122'}
                style={{ width: '70%' }}
                onChange={(e) => {
                  setAddData({
                    ...addData,
                    backgroundColor: e.target.value,
                    borderColor: e.target.value,
                  });
                }}
              />
            </Box>

            <Box sx={{ textAlign: 'end' }}>
              <Button
                disabled={loading}
                sx={{
                  background: 'var(--primary-color)',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: 'var(--primary-color)',
                  },
                  mt: 2,
                }}
                onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Box>
        </SwipeableDrawer>
      )}

      {!isMobile ? (
        <Dialog
          open={!!oepnUpdateId}
          onClose={() => setOpenUpdateId(null)}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth>
          <Box p={3}>
            <Typography
              variant="title"
              className="page-title"
              sx={{ flex: 1, fontSize: '20px' }}>
              Update Holiday
            </Typography>

            <Grid container spacing={'15px'} mt={0}>
              <Grid item lg={6}>
                <label htmlFor="title" style={inputStyle.container}>
                  <span style={inputStyle.label}>Holiday Title</span>
                  <br />
                  <input
                    id="title"
                    name="title"
                    type="text"
                    value={holidayUpdateData?.title}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="description" style={inputStyle.container}>
                  <span style={inputStyle.label}>Holiday Description</span>
                  <br />
                  <input
                    id="description"
                    name="description"
                    type="text"
                    value={holidayUpdateData?.description}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="start" style={inputStyle.container}>
                  <span style={inputStyle.label}>Start Date</span>
                  <br />
                  <input
                    id="start"
                    name="start"
                    type="date"
                    value={holidayUpdateData?.start}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="end" style={inputStyle.container}>
                  <span style={inputStyle.label}>End Date</span>
                  <br />
                  <input
                    min={holidayUpdateData?.start}
                    id="end"
                    name="end"
                    type="date"
                    value={holidayUpdateData?.end}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="selectColor" style={inputStyle.container}>
                  <span style={inputStyle.label}>Select Color</span>
                  <br />
                  <input
                    id="selectColor"
                    type="color"
                    style={{ width: '100%' }}
                    value={holidayUpdateData.backgroundColor}
                    onChange={(e) => {
                      setHolidayUpdateData({
                        ...holidayUpdateData,
                        backgroundColor: e.target.value,
                        borderColor: e.target.value,
                      });
                    }}
                  />
                </label>
              </Grid>
            </Grid>

            <Grid container spacing={'15px'} mt={0}>
              <Grid item lg={6}>
                <Box sx={{ textAlign: 'end' }}>
                  <Button
                    disabled={loading}
                    sx={{
                      background: 'red',
                      color: '#ffffff',
                      '&:hover': {
                        backgroundColor: 'red',
                      },
                      mt: 2,
                      width: '100%',
                    }}
                    onClick={handleDelete}>
                    Delete
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Button
                  disabled={loading}
                  sx={{
                    background: 'var(--primary-color)',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: 'var(--primary-color)',
                    },
                    mt: 2,
                    width: '100%',
                  }}
                  onClick={handleUpdate}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={!!oepnUpdateId}
          onClose={() => setOpenUpdateId(null)}
          onOpen={() => {}}>
          <Box p={3} sx={{ minHeight: '400px' }}>
            <Typography
              variant="title"
              className="page-title"
              sx={{ flex: 1, fontSize: '20px' }}>
              Update Holiday
            </Typography>

            <Grid container spacing={'15px'} mt={0}>
              <Grid item lg={6}>
                <label htmlFor="title" style={inputStyle.container}>
                  <span style={inputStyle.label}>Holiday Title</span>
                  <br />
                  <input
                    id="title"
                    name="title"
                    type="text"
                    value={holidayUpdateData?.title}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="description" style={inputStyle.container}>
                  <span style={inputStyle.label}>Holiday Description</span>
                  <br />
                  <input
                    id="description"
                    name="description"
                    type="text"
                    value={holidayUpdateData?.description}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="start" style={inputStyle.container}>
                  <span style={inputStyle.label}>Start Date</span>
                  <br />
                  <input
                    id="start"
                    name="start"
                    type="date"
                    value={holidayUpdateData?.start}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="end" style={inputStyle.container}>
                  <span style={inputStyle.label}>End Date</span>
                  <br />
                  <input
                    min={holidayUpdateData?.start}
                    id="end"
                    name="end"
                    type="date"
                    value={holidayUpdateData?.end}
                    onChange={handleInputValue}
                    style={inputStyle.input}
                  />
                </label>
              </Grid>
              <Grid item lg={6}>
                <label htmlFor="selectColor" style={inputStyle.container}>
                  <span style={inputStyle.label}>Select Color</span>
                  <br />
                  <input
                    id="selectColor"
                    type="color"
                    style={{ width: '100%' }}
                    value={holidayUpdateData.backgroundColor}
                    onChange={(e) => {
                      setHolidayUpdateData({
                        ...holidayUpdateData,
                        backgroundColor: e.target.value,
                        borderColor: e.target.value,
                      });
                    }}
                  />
                </label>
              </Grid>
            </Grid>

            <Grid container spacing={'15px'} mt={0}>
              <Grid item lg={6}>
                <Box sx={{ textAlign: 'end' }}>
                  <Button
                    disabled={loading}
                    sx={{
                      background: 'red',
                      color: '#ffffff',
                      '&:hover': {
                        backgroundColor: 'red',
                      },
                      mt: 2,
                      width: '100%',
                    }}
                    onClick={handleDelete}>
                    Delete
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Button
                  disabled={loading}
                  sx={{
                    background: 'var(--primary-color)',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: 'var(--primary-color)',
                    },
                    mt: 2,
                    width: '100%',
                  }}
                  onClick={handleUpdate}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default Holiday;
