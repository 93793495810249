import { Box, Grid } from "@mui/material";
import React from "react";

const Others = ({ salaryData, handleGetSalaryValue, handleGetReason }) => {
  const isMobile = window.innerWidth <= 768;
  // console.log(salary)
  return (
    <Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label
              htmlFor="salary"
              className="input-label"
              style={{ width: "100%" }}
            >
              <span>Absent Deduction</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.LateDeductionByDay)}
              onChange={handleGetSalaryValue}
              name="LateDeductionByDay"
              className="input-field"
              type="number"
              placeholder="Enter Late Deduction By Day"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label
              htmlFor="salary"
              className="input-label"
              style={{ width: "100%" }}
            >
              <span>Late Deduction </span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.LateDeductionByTime)}
              onChange={handleGetSalaryValue}
              name="LateDeductionByTime"
              className="input-field"
              type="number"
              placeholder="Enter Late Deduction By Time"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label
              htmlFor="salary"
              className="input-label"
              style={{ width: "100%" }}
            >
              <span>Lunch Deduct</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.lunchDeduct)}
              onChange={handleGetSalaryValue}
              name="lunchDeduct"
              className="input-field"
              type="number"
              placeholder="Enter Lunch Deduct"
            />
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        sx={{ display: "flex", flexDirection: isMobile && "column" }}
      >
        <Grid item lg={4}>
          <Box className="input-container" sx={{ width: "100%" }}>
            <Grid container>
              <Grid item lg={6} xs={9}>
                <label
                  htmlFor="salary"
                  className="input-label"
                  style={{ width: isMobile ? "100%" : "70%" }}
                >
                  <span>Food Allowance</span>
                </label>
              </Grid>
              <Grid item lg={5} xs={2}>
                <input
                  readOnly
                  value={String(salaryData?.mealPrice * salaryData?.meals)}
                  onChange={handleGetSalaryValue}
                  name="foodAllowance"
                  className="input-field"
                  type="number"
                  placeholder="Enter Meal Price"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box className="input-container" sx={{ width: "100%" }}>
            <Grid container>
              <Grid item lg={6} xs={9}>
                <label
                  htmlFor="salary"
                  className="input-label"
                  style={{ width: isMobile ? "100%" : "50%" }}
                >
                  <span>Per Meal Price</span>
                </label>
              </Grid>
              <Grid item lg={5} xs={2}>
                <input
                  value={String(salaryData?.mealPrice)}
                  onChange={handleGetSalaryValue}
                  name="mealPrice"
                  className="input-field"
                  type="number"
                  placeholder="Enter Meal Price"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box className="input-container" sx={{ width: "100%" }}>
            <Grid container>
              <Grid item lg={6} xs={9}>
                <label
                  htmlFor="salary"
                  className="input-label"
                  style={{ width: "100%" }}
                >
                  <span>Meals Count</span>
                </label>
              </Grid>
              <Grid item lg={6} xs={2}>
                <input
                  value={String(salaryData?.meals)}
                  onChange={handleGetSalaryValue}
                  name="meals"
                  className="input-field"
                  type="number"
                  placeholder="Enter Meals Taken"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label
              htmlFor="salary"
              className="input-label"
              style={{ width: "100%" }}
            >
              <span>Advance Salary Deduction</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.advanceSalary)}
              onChange={handleGetSalaryValue}
              name="advanceSalary"
              className="input-field"
              type="number"
              placeholder="Enter Meal Price"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label
              htmlFor="salary"
              className="input-label"
              style={{ width: "100%" }}
            >
              <span>Loan Deduction</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.loanDeduction)}
              onChange={handleGetSalaryValue}
              name="loanDeduction"
              className="input-field"
              type="number"
              placeholder="Enter Meal Price"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label
              htmlFor="salary"
              className="input-label"
              style={{ width: "100%" }}
            >
              <span>Provident Fund</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.providentFund || 0)}
              onChange={handleGetSalaryValue}
              name="providentFund"
              className="input-field"
              type="number"
              placeholder="Enter Meal Price"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid lg={2} xs={9}>
            <label
              htmlFor="salary"
              className="input-label"
              style={{ width: "100%" }}
            >
              <span>Loss Of Office Equipment</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.lossOfOfficeEquipment)}
              onChange={handleGetSalaryValue}
              name="lossOfOfficeEquipment"
              className="input-field"
              type="number"
              placeholder="Enter Loss Of Office Equipment"
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container sx={{ display: "flex" }}>
        <Grid item lg={4} xs={12}>
          <Box className="input-container" sx={{ width: "100%" }}>
            <Grid container>
              <Grid item lg={6} xs={9}>
                <label
                  htmlFor="salary"
                  className="input-label"
                  style={{ width: "100%" }}
                >
                  <span>Others Deductions</span>
                </label>
              </Grid>
              <Grid item lg={4} xs={2} >
                <input
                  value={String(salaryData?.extraDeduction)}
                  onChange={handleGetSalaryValue}
                  name="extraDeduction"
                  className="input-field"
                  type="number"
                  placeholder="Enter Loss Of Office Equipment"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Box className="input-container" sx={{ py: isMobile && 1 }}>
            <Grid container >
              <Grid item lg={6.4} xs={12}>
                <label
                  htmlFor="salary"
                  className="input-label"
                  style={{ width: "100%" }}
                >
                  <span>Others Deductions Reason</span>
                </label>
              </Grid>
              <Grid
                item
                lg={3}
                xs={12}
                sx={{ mt: isMobile && 2, ml: !isMobile ? -30 : -1 }}
              >
                <input
                  style={{ width: !isMobile && "250%" }}
                  value={salaryData?.extraDeductionReason}
                  onChange={handleGetReason}
                  name="extraDeductionReason"
                  className="input-field"
                  type="text"
                  placeholder="Enter Others Deductions Reason"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Others;
