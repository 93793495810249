import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Zoom,
  Tooltip,
  FormControl,
  styled,
  MenuItem,
  OutlinedInput,
  Divider,
  Select,
  SwipeableDrawer,
  Grid,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { addButton } from '../../utility/styles';
import moment from 'moment';
import APILoader from '../../components/APILoader/APILoader';
import Swal from 'sweetalert2';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const CustomSelect = styled(Select)({
  width: '100%', // Initial fixed width
  minWidth: '200px', // Minimum width to prevent shrinking
  '&.MuiOutlinedInput-root': {
    border: 'none', // Remove border from root element
  },
  '& .MuiSelect-select': {
    display: 'flex',
    flexGrow: 1,
  },
});

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  color: 'black',
  '&.Mui-selected': {
    backgroundColor: 'var(--primary-color)', // Set background color for selected items
    color: 'white',
    '&:hover': {
      color: 'white', // Change text color on hover
      backgroundColor: 'var(--primary-color)',
    },
  },
}));

const categoryOptions = [
  {
    name: 'House Rules',
    value: 'house-rules',
  },
  {
    name: 'Birthday',
    value: 'birthday-on-board',
  },
  {
    name: 'Promotions',
    value: 'promotions',
  },
  {
    name: 'Holidays',
    value: 'holidays',
  },
  {
    name: 'Attendance Rules',
    value: 'attendance-rules',
  },
  {
    name: 'Celebrations',
    value: 'celebrations',
  },
  {
    name: 'Festival',
    value: 'festival',
  },
  {
    name: 'Cultural Program',
    value: 'cultural-program',
  },
  {
    name: 'House Party',
    value: 'house-party',
  },
  {
    name: 'Others',
    value: 'others',
  },
];

const headers = [
  'Subject',
  'Notice Type',
  'Description',
  'Created At',
  'Updated At',
];

const Notice = () => {
  const user = secureLocalStorage.getItem('admin-info');
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  const token = user?.accessToken;
  const [noticeData, setNoticeData] = useState([]);
  const [singleNotice, setSingleNotice] = useState({});
  const [showModal, setShowModal] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    fetch(`https://flyfar-hrm-pro.de.r.appspot.com/api/company/notices`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setNoticeData(data?.data);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [token]);

  const handleShowNoticeDetails = (id) => {
    fetch(`https://flyfar-hrm-pro.de.r.appspot.com/api/company/notices/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // setNoticeData(data?.data);
        setSingleNotice(data?.data[0]);
        setShowModal(true);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const handleChangeUpdateData = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateNotice = (id) => {
    fetch(`https://flyfar-hrm-pro.de.r.appspot.com/api/company/notices/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        description: updateData?.description,
        subject: updateData.subject,
        noticeType: updateData?.noticeType,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data?.success) {
          setShowUpdateModal(false);
          Swal.fire({
            icon: 'success',
            title: data?.message,
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Ok',
          }).then(function () {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: data?.error?.message,
            confirmButtonColor: 'var(--primary-color)',
            confirmButtonText: 'Try again',
          });
        }
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  // console.log(updateData);

  return (
    <Box>
      {noticeData.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '25px',
              justifyContent: 'space-between',
              flexDirection: isMobile && 'column',
            }}>
            <Typography variant="title" className="page-title">
              Notice
            </Typography>
            {user?.user?.role !== 'manager' && (
              <Button
                onClick={() => navigate('/dashboard/addNotice')}
                sx={addButton}>
                Add Notice
              </Button>
            )}
          </Box>

          <Box mt={3}>
            {!isMobile ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers.map((header, i) => (
                        <TableCell key={i}>{header}</TableCell>
                      ))}
                      <TableCell sx={{ textAlign: 'center' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {noticeData?.map((notice, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Tooltip
                            title={
                              <Typography
                                sx={{ color: 'white', fontSize: '14px' }}>
                                {notice?.subject || 'N/A'}
                              </Typography>
                            }>
                            {notice?.subject.slice(0, 50) || 'N/A'}
                          </Tooltip>
                        </TableCell>
                        <TableCell>{notice?.noticeType}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              <Typography
                                sx={{ color: 'white', fontSize: '14px' }}>
                                {notice?.description || 'N/A'}
                              </Typography>
                            }>
                            {notice?.description.slice(0, 50) || 'N/A'}
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {moment(notice?.createdAt).format(
                            'DD MMM YYYY hh:mm:ss',
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(notice?.updatedAt).format(
                            'DD MMM YYYY hh:mm:ss',
                          )}
                        </TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            {user?.user?.role !== 'manager' && (
                              <Button
                                onClick={() => {
                                  setShowUpdateModal(true);
                                  setUpdateData(notice);
                                }}
                                variant="contained"
                                sx={{
                                  marginLeft: '10px',
                                  bgcolor: 'var(--primary-color)',
                                  ':hover': { bgcolor: 'var(--primary-color)' },
                                }}>
                                <Typography
                                  sx={{ fontSize: '12px', color: 'white' }}>
                                  Update
                                </Typography>
                              </Button>
                            )}

                            <Button
                              onClick={() =>
                                handleShowNoticeDetails(notice?.id)
                              }
                              variant="contained"
                              sx={{
                                marginLeft: '10px',
                                bgcolor: 'var(--text-medium)',
                                ':hover': { bgcolor: 'var(--text-medium)' },
                              }}>
                              <Typography
                                sx={{ fontSize: '12px', color: 'white' }}>
                                View
                              </Typography>
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {noticeData?.map((notice, i) => (
                  <Box
                    key={i}
                    sx={{
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      px: 2,
                      py: 1,
                      borderRadius: '5px',
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: '#f5f5f5',
                      color: '#000',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        pb: 1.5,
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Typography sx={attendFontStyle}>Subject</Typography>
                        <Typography sx={attendFontStyle}>
                          {notice?.subject || 'N/A'}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>
                          Notice Type
                        </Typography>
                        <Typography sx={attendFontStyle}>
                          {notice?.noticeType}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                          flexDirection: 'column',
                        }}>
                        <Typography sx={attendFontStyle}>
                          Description :
                        </Typography>
                        <Typography sx={attendFontStyle}>
                          {notice?.description || 'N/A'}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>Created At</Typography>
                        <Typography sx={attendFontStyle}>
                          {moment(notice?.createdAt).format(
                            'DD MMM YYYY hh:mm:ss',
                          )}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>Updated At</Typography>
                        <Typography sx={attendFontStyle}>
                          {moment(notice?.updatedAt).format(
                            'DD MMM YYYY hh:mm:ss',
                          )}
                        </Typography>
                      </Box>
                      <Divider />

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mt: 2,
                        }}>
                        {user?.user?.role !== 'manager' && (
                          <Button
                            onClick={() => {
                              setShowUpdateModal(true);
                              setUpdateData(notice);
                            }}
                            variant="contained"
                            sx={{
                              width: '100%',
                              marginLeft: '10px',
                              bgcolor: 'var(--primary-color)',
                              ':hover': { bgcolor: 'var(--primary-color)' },
                            }}>
                            <Typography
                              sx={{ fontSize: '12px', color: 'white' }}>
                              Update
                            </Typography>
                          </Button>
                        )}

                        <Button
                          onClick={() => handleShowNoticeDetails(notice?.id)}
                          variant="contained"
                          sx={{
                            width: '100%',
                            marginLeft: '10px',
                            bgcolor: 'var(--text-medium)',
                            ':hover': { bgcolor: 'var(--text-medium)' },
                          }}>
                          <Typography sx={{ fontSize: '12px', color: 'white' }}>
                            View
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}

      {!isMobile ? (
        <Dialog
          // open={true}
          open={showModal}
          onClose={() => setShowModal(null)}
          TransitionComponent={Transition}
          maxWidth="md"
          fullWidth>
          <Box sx={{ p: 2, minHeight: '450px' }}>
            <Typography sx={{ fontSize: '17px', textTransform: 'capitalize' }}>
              Subject: {singleNotice?.subject}
            </Typography>

            <Typography sx={{ fontSize: '17px', textTransform: 'capitalize' }}>
              Notice Type: {singleNotice?.noticeType}
            </Typography>

            <Typography sx={{ fontSize: '17px', textTransform: 'capitalize' }}>
              Branches:{' '}
              {singleNotice?.branches?.map((branch, i) => (
                <span key={i}>{branch?.branchName}, </span>
              ))}
            </Typography>

            <Typography sx={{ fontSize: '17px', textTransform: 'capitalize' }}>
              Departments:{' '}
              {singleNotice?.depts?.map((branch, i) => (
                <span key={i}>{branch?.deptName}, </span>
              ))}
            </Typography>

            <Typography sx={{ fontSize: '17px', textTransform: 'capitalize' }}>
              Description: {singleNotice?.description}
            </Typography>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={Boolean(showModal)}
          onClose={() => setShowModal(null)}
          onOpen={() => {}}>
          <Box sx={{ p: 2, minHeight: '450px' }}>
            <Typography
              sx={{
                fontSize: isMobile ? '13px' : '17px',
                textTransform: 'capitalize',
              }}>
              Subject: {singleNotice?.subject}
            </Typography>

            <Typography
              sx={{
                fontSize: isMobile ? '13px' : '17px',
                textTransform: 'capitalize',
              }}>
              Notice Type: {singleNotice?.noticeType}
            </Typography>

            <Typography
              sx={{
                fontSize: isMobile ? '13px' : '17px',
                textTransform: 'capitalize',
              }}>
              Branches:{' '}
              {singleNotice?.branches?.map((branch, i) => (
                <span key={i}>{branch?.branchName}, </span>
              ))}
            </Typography>

            <Typography
              sx={{
                fontSize: isMobile ? '13px' : '17px',
                textTransform: 'capitalize',
              }}>
              Departments:{' '}
              {singleNotice?.depts?.map((branch, i) => (
                <span key={i}>{branch?.deptName}, </span>
              ))}
            </Typography>

            <Typography
              sx={{
                fontSize: isMobile ? '13px' : '17px',
                textTransform: 'capitalize',
              }}>
              Description: {singleNotice?.description}
            </Typography>
          </Box>
        </SwipeableDrawer>
      )}

      {!isMobile ? (
        <Dialog
          // open={true}
          open={showUpdateModal}
          onClose={() => setShowUpdateModal(false)}
          TransitionComponent={Transition}
          maxWidth="md"
          fullWidth>
          <Box sx={{ height: '350px', p: 4 }}>
            <Typography
              variant="title"
              className="page-title"
              sx={{ mb: 2, fontSize: '20px' }}>
              Update Notice
            </Typography>

            <Box className="input-container">
              <label htmlFor="noticeType" className="input-label">
                <span>Select Categories</span>
              </label>

              <select
                name="noticeType"
                id="noticeType"
                onChange={handleChangeUpdateData}
                value={updateData?.noticeType}
                style={{ fontSize: '14px' }}>
                {categoryOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </Box>

            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Enter Notice Title</span>
              </label>
              <input
                name="subject"
                id="subject"
                value={updateData?.subject}
                onChange={handleChangeUpdateData}
                className="input-field"
                type="text"
                placeholder="Notice Title"
                required
              />
            </Box>

            <Box className="input-container">
              <label htmlFor="salary" className="input-label">
                <span>Enter Description</span>
              </label>
              <input
                name="description"
                id="description"
                value={updateData?.description}
                onChange={handleChangeUpdateData}
                className="input-field"
                type="text"
                placeholder="Notice Description"
                required
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                sx={{
                  color: '#fff',
                  background: 'var(--primary-color)',
                  mt: 4,
                  minWidth: '100px',
                  '&:hover': { background: 'var(--primary-color)' },
                }}
                onClick={() => handleUpdateNotice(updateData?.id)}>
                Update
              </Button>
            </Box>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={Boolean(showUpdateModal)}
          onClose={() => setShowUpdateModal(false)}
          onOpen={() => {}}>
          <Box sx={{ height: '400px', p: 4 }}>
            <Typography
              variant="title"
              className="page-title"
              sx={{ mb: 2, fontSize: '20px' }}>
              Update Notice
            </Typography>

            <Box className="input-container">
              <Grid container>
                <Grid item xs={6}>
                  <label
                    htmlFor="noticeType"
                    className="input-label"
                    style={{ width: '100%' }}>
                    <span>Select Categories</span>
                  </label>
                </Grid>
                <Grid>
                  <select
                    name="noticeType"
                    id="noticeType"
                    onChange={handleChangeUpdateData}
                    value={updateData?.noticeType}
                    style={{ fontSize: '14px', width: '100%', padding: '8px' }}>
                    {categoryOptions.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </Grid>
              </Grid>
            </Box>

            <Box className="input-container">
              <Grid container>
                <Grid item xs={6}>
                  <label
                    htmlFor="subject"
                    className="input-label"
                    style={{ width: '100%' }}>
                    <span>Enter Notice Title</span>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    name="subject"
                    id="subject"
                    value={updateData?.subject}
                    onChange={handleChangeUpdateData}
                    className="input-field"
                    type="text"
                    placeholder="Notice Title"
                    required
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="input-container">
              <Grid container>
                <Grid item xs={6}>
                  <label
                    htmlFor="description"
                    className="input-label"
                    style={{ width: '100%' }}>
                    <span>Enter Description</span>
                  </label>
                </Grid>
                <Grid item xs={6}>
                  <input
                    name="description"
                    id="description"
                    value={updateData?.description}
                    onChange={handleChangeUpdateData}
                    className="input-field"
                    type="text"
                    placeholder="Notice Description"
                    required
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                sx={{
                  width: '100%',
                  color: '#fff',
                  background: 'var(--primary-color)',
                  mt: 4,
                  minWidth: '100px',
                  '&:hover': { background: 'var(--primary-color)' },
                }}
                onClick={() => handleUpdateNotice(updateData?.id)}>
                Update
              </Button>
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default Notice;
const flexStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 1,
};

const attendFontStyle = {
  fontSize: '13px',
};
