import { Box, Grid } from "@mui/material";
import React from "react";

const AdditionalSalary = ({
  salaryData,
  handleGetSalaryValue,
  handleGetReason,
}) => {
  const isMobile = window.innerWidth <= 768;
  // console.log(salary)
  return (
    <Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="salary" className="input-label">
              <span>Conveyance</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.conveyance)}
              onChange={handleGetSalaryValue}
              name="conveyance"
              className="input-field"
              type="number"
              placeholder="Enter Extra Addition"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="salary" className="input-label">
              <span>Overtime</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.overtime)}
              onChange={handleGetSalaryValue}
              name="overtime"
              className="input-field"
              type="text"
              placeholder="Enter Extra Addition"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="salary" className="input-label">
              <span>Bonus</span>
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
            <input
              readOnly
              value={String(salaryData?.bonus)}
              onChange={handleGetSalaryValue}
              name="bonus"
              className="input-field"
              type="number"
              placeholder="Enter Extra Deduction"
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Grid container>
          <Grid item lg={4}>
            <Box className="input-container" sx={{ width: "100%" }}>
              <Grid container>
                <Grid item lg={4} xs={9}>
                  <label
                    htmlFor="salary"
                    className="input-label"
                    style={{ width: "100%" }}
                  >
                    <span>Others Addition</span>
                  </label>
                </Grid>
                <Grid item lg={5} xs={2}>
                  <input
                    value={String(salaryData?.extraAddition)}
                    onChange={handleGetSalaryValue}
                    name="extraAddition"
                    className="input-field"
                    type="number"
                    placeholder="Enter Loss Of Office Equipment"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <Box className="input-container" sx={{ py: isMobile && 1 }}>
              <Grid container>
                <Grid item lg={6.4} xs={12}>
                  <label
                    htmlFor="salary"
                    className="input-label"
                    style={{ width: "100%" }}
                  >
                    <span>Others Addition Reason</span>
                  </label>
                </Grid>
                <Grid
                  item
                  lg={3}
                  xs={12}
                  sx={{ mt: isMobile && 2, ml: !isMobile ? -30 : -1 }}
                >
                  <input
                    style={{ width: !isMobile && "250%" }}
                    value={salaryData?.extraAdditionReason}
                    onChange={handleGetReason}
                    name="extraAdditionReason"
                    className="input-field"
                    type="text"
                    placeholder="Enter Others Addition Reason"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdditionalSalary;
