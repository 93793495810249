import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch,
  Dialog,
  SwipeableDrawer,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import APILoader from '../../components/APILoader/APILoader';
import Swal from 'sweetalert2';
import EmployeeShifts from './EmployeeShifts';
import { CustomZoomTransition } from '../../components/CustomZoomTransition';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { filterSelectStyle } from '../../utility/styles';
import { useFetchAPI } from '../../hooks/useFetchAPI';
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';

const headers = [
  'Name',
  'Status',
  'Designations',
  'Join date',
  'Salary',
  'Leave',
  'Personal Contact',
  'Action',
  'Active',
  'View Shifts',
];

const Employee = () => {
  const isMobile = window.innerWidth <= 768;
  const { getFetcher } = useFetchAPI();
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('admin-info');
  const [deptId, setDeptId] = useState('');
  const [employeeDept, setEmployeeDept] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const token = user?.accessToken;

  const handleUpdate = (data) => {
    const dataToSend = {
      data,
    };

    navigate('/dashboard/employeeUpdate', { state: { data: dataToSend } });
  };

  const handleDepartmentClick = (id) => {
    setDeptId(id);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    fetch(
      `https://flyfar-hrm-pro.de.r.appspot.com/api/company/employees?deptId=${deptId}&status=inactive`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        setEmployeeDept(
          data?.data.map((emp) => ({
            ...emp,
            isActive: emp.status === 'active' ? true : false,
          })),
        );
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [deptId, token]);

  const handleMasterCheckboxChange = (event) => {
    let isChecked = event.target.checked;

    if (allCheck === false) {
      isChecked = true;
      setAllCheck(true);
    } else {
      setAllCheck(false);
      isChecked = false;
    }

    if (isChecked) {
      const allselectedEmployee = employeeDept.map((data) => ({
        id: data.id,
        firstname: data.firstname,
        lastname: data.lastname,
        deptName: data.deptName,
        branchId: data.branchId,
        deptId: data.deptId,
      }));
      setSelectedRows(allselectedEmployee);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSingleCheckboxChange = (
    event,
    id,
    firstname,
    lastname,
    deptName,
    branchId,
    deptId,
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows((prevselectedEmployee) => [
        ...prevselectedEmployee,
        { id, firstname, lastname, deptName, branchId, deptId },
      ]);
    } else {
      setSelectedRows((prevselectedEmployee) =>
        prevselectedEmployee.filter((row) => row.id !== id),
      );
    }
  };

  const { data: departments, status } = useQuery({
    queryKey: ['all-departments'],
    queryFn: () => getFetcher(`departments`),
    onSuccess: (data) => {
      if (data?.data?.length > 0) {
        setDeptId(data.data[0].id);
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  const { data: employee, status: employeeStatus } = useQuery({
    queryKey: ['employee-by-deptId', deptId],
    queryFn: () => getFetcher(`employees?deptId=${deptId}`),
    enabled: !!deptId,
  });

  if (status === 'loading' || employeeStatus === 'loading') return <>Loading</>;

  const handleSingleEmployee = (id) => {
    navigate(`/dashboard/employeeDtails`, { state: { employeeId: id } });
  };

  const handleStatusChange = (id) => {
    console.log('employeeDept', employeeDept);
    try {
      Swal.fire({
        icon: 'warning',
        title: `Are you sure you want to ${'inactive'} employee`,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      }).then(async function (res) {
        if (res.isConfirmed) {
          await fetch(
            `https://flyfar-hrm-pro.de.r.appspot.com/api/company/employees/${id}/update-status`,
            {
              method: 'PATCH',
              body: JSON.stringify({
                status: 'inactive',
              }),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                Swal.fire({
                  icon: 'success',
                  title: data.message,
                  confirmButtonColor: 'var(--primary-color)',
                  confirmButtonText: 'Ok',
                }).then(function () {
                  window.location.reload();
                });
              }
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const selectOptions = departments?.data
    ?.slice(7, departments?.data?.length)
    .map((br) => ({
      label: br?.deptName,
      value: br?.id,
    }));

  const handleSelectChange = (option) => {
    setDeptId(option.value);
  };

  const filteredEmployees = employee?.data?.filter(
    (emp) => emp.deptId === deptId,
  );

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflowY: 'auto' }}>
      {/*------------- Page Title -------------*/}

      {departments?.data.length > 0 ? (
        <Box sx={{ paddingBottom: '50px' }}>
          <Box
            sx={{
              mb: 3,
              display: isMobile && 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              sx={{ fontSize: isMobile ? '17px' : '26px', fontWeight: '600' }}>
              Employee List
            </Typography>
            {isMobile && (
              <Button
                variant="darkBtn"
                onClick={() => navigate('/dashboard/employee/addEmployee')}>
                Add Employee
              </Button>
            )}
          </Box>

          {/* <Box
            className="justify_between align_center"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .MuiTabs-root": { minHeight: "31px" },
            }}
          >
            <Tabs
              value={deptId}
              onChange={(_, newValue) => {
                setDeptId(departments.data[newValue].id);
              }}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                height: "36px",
                width: "75%",
                "& .MuiTabs-indicator": { display: "none" },
                // "& .MuiTabs-root": { minHeight: "36px" },
                "& .Mui-selected": { color: "white" },
                "& .MuiTabScrollButton-root": {
                  color: "var(--text-medium)",
                  bgcolor: "#EFF2F5",
                  width: "16px",
                  ml: 1,
                },
                "& .MuiTabScrollButton-root:first-of-type": {
                  mr: 1,
                  ml: 0,
                },
                // "data"
                height: "31px",
              }}
            >
              {departments?.data.map((department, i) => (
                <Tab
                  key={i}
                  label={department?.deptName}
                  variant={
                    deptId === department?.id ? "primaryBtn" : "lightBtn"
                  }
                  sx={{
                    mr: 1,
                    fontSize: "12px",
                    bgcolor:
                      deptId === department?.id
                        ? "var(--primary-color)"
                        : "#EFF2F5",
                    color:
                      deptId === department?.id
                        ? "white"
                        : "var(--text-medium)",
                    "&:hover": {
                      bgcolor:
                        deptId === department?.id
                          ? "var(--primary-color)"
                          : "#EFF2F5",
                    },
                    padding: "4px 8px",
                    borderRadius: "4px",
                    textTransform: "capitalize",
                    minHeight: "31px",
                    opacity: 1,
                  }}
                />
              ))}
            </Tabs>

            <Box className="justify_between align_center" sx={{ gap: 2 }}>
              <Button
                variant="primaryBtn"
                sx={{
                  visibility: selectedRows.length > 0 ? "visible" : "hidden",
                }}
                onClick={() => {
                  navigate("/dashboard/employee/assignShift", {
                    state: { selectedRows },
                  });
                }}
              >
                Assign Shifts
              </Button>

              <Button
                variant="darkBtn"
                onClick={() => navigate("/dashboard/employee/addEmployee")}
              >
                Add Employee
              </Button>
            </Box>
          </Box> */}

          {isMobile && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {departments?.data?.map((department, i) => (
                <Box
                  key={i}
                  onClick={() => handleDepartmentClick(department?.id)}
                  sx={{
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    px: 2,
                    py: 1,
                    borderRadius: '5px',
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#f5f5f5',
                    color: '#000',
                  }}>
                  <LayersIcon />
                  <Typography>{department?.deptName}</Typography>
                </Box>
              ))}
            </Box>
          )}

          <SwipeableDrawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            onOpen={() => {}}
            PaperProps={{
              sx: {
                height: '100%',
                width: '100%',
                borderRadius: 0,
              },
            }}>
            <Box sx={{ padding: 0 }}>
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  bgcolor: 'var(--primary-color)',
                  position: 'sticky',
                  py: 4,
                  px: 3,
                  borderRadius: '0 0 50px 50px',
                  color: 'white',
                }}>
                Employees
              </Typography>

              <CloseIcon
                onClick={handleDrawerClose}
                sx={{
                  position: 'absolute',
                  top: 30,
                  right: 8,
                  zIndex: 1,
                  color: 'black',
                  bgcolor: 'white',
                  p: 0.5,
                  borderRadius: '50px',
                }}
              />

              <Box sx={{ px: 2 }}>
                {filteredEmployees?.map((data, index) => (
                  <Box
                    key={index}
                    sx={{
                      fontSize: '13px',
                      height: '60px',
                      mb: 1,
                      bgcolor: 'white',
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      px: 2,
                      borderRadius: '2px',
                    }}
                    onClick={() => handleSingleEmployee(data?.id)}>
                    {/* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */}
                    <Typography
                      sx={{
                        bgcolor: 'black',
                        px: 0.8,
                        py: 0.2,
                        borderRadius: '2px',
                        color: 'var(--evening-color)',
                        fontWeight: '600',
                      }}>
                      {' '}
                      {data?.firstname?.[0]?.toUpperCase()}
                      {data?.lastname?.[0]?.toUpperCase()}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ fontSize: '15px', fontWeight: '600' }}>
                        {data?.firstname} {data?.lastname}
                      </Typography>

                      <Typography sx={{ fontSize: '13px', fontWeight: '400' }}>
                        {data?.designation}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              {filteredEmployees?.length === 0 && (
                <Typography>No employees found for this department.</Typography>
              )}
            </Box>
          </SwipeableDrawer>

          {!isMobile && (
            <Box className="justify_between">
              {!isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                    width: '75%',
                  }}>
                  {departments?.data?.slice(0, 7)?.map((department, i) => (
                    <Button
                      key={i}
                      variant={
                        deptId === department?.id ? 'primaryBtn' : 'lightBtn'
                      }
                      onClick={() => setDeptId(department.id)}>
                      {department?.deptName}
                    </Button>
                  ))}

                  <Select
                    onChange={handleSelectChange}
                    styles={filterSelectStyle(deptId)}
                    classNamePrefix="select"
                    isSearchable={false}
                    options={selectOptions}
                    placeholder={
                      <Typography
                        sx={filterSelectStyle(deptId).labelText}
                        noWrap>
                        Select More Department
                      </Typography>
                    }
                    value={
                      selectOptions.find((option) => option.value === deptId) ||
                      null
                    }
                  />
                </Box>
              )}

              <Box className="justify_between align_center" sx={{ gap: 2 }}>
                <Button
                  variant="primaryBtn"
                  sx={{
                    visibility: selectedRows.length > 0 ? 'visible' : 'hidden',
                  }}
                  onClick={() => {
                    navigate('/dashboard/employee/assignShift', {
                      state: { selectedRows },
                    });
                  }}>
                  Assign Shifts
                </Button>

                <Button
                  variant="darkBtn"
                  onClick={() => navigate('/dashboard/employee/addEmployee')}>
                  Add Employee
                </Button>
              </Box>
            </Box>
          )}

          {/*------------- Page Content -------------*/}
          {!isMobile && (
            <Box mt={3}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={allCheck}
                          onChange={handleMasterCheckboxChange}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: 'Var(--primary-color)',
                            },
                          }}
                        />
                      </TableCell>
                      {headers.map((header, i) => (
                        <TableCell key={i}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employee?.data.map((data, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              checked={selectedRows.some(
                                (row) => row.id === data.id,
                              )}
                              onChange={(event) =>
                                handleSingleCheckboxChange(
                                  event,
                                  data.id,
                                  data.firstname,
                                  data.lastname,
                                  data.deptName,
                                  data.branchId,
                                  data.deptId,
                                )
                              }
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  color: 'Var(--primary-color)',
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="primaryBtn"
                              sx={{ fontSize: '13px', width: '90%' }}
                              onClick={() => handleSingleEmployee(data?.id)}>
                              {data?.firstname} {data?.lastname}
                            </Button>
                          </TableCell>
                          <TableCell> {data?.status} </TableCell>
                          <TableCell> {data?.designation} </TableCell>
                          <TableCell> {data?.joiningDate} </TableCell>
                          <TableCell> {data?.grossSalary} </TableCell>
                          <TableCell> {data?.casualLeave} </TableCell>
                          <TableCell> {data?.personalPhone} </TableCell>
                          <TableCell>
                            <Button
                              variant="primaryBtn"
                              onClick={() => handleUpdate(data)}>
                              Update
                            </Button>
                          </TableCell>

                          <TableCell>
                            <Switch
                              checked={data?.isActive}
                              onChange={() => handleStatusChange(data?.id)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell>

                          <TableCell>
                            <Button
                              variant="primaryBtn"
                              onClick={() => {
                                setOpen(true);
                                setCurrentData(data);
                              }}>
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={CustomZoomTransition}
        maxWidth="md"
        fullWidth>
        <Box p={3} minHeight={'460px'}>
          <EmployeeShifts currentData={currentData} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default Employee;
