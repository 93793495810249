import { Box, ClickAwayListener, Grid, Grow, Typography } from "@mui/material";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { ReactComponent as WeekendIcon } from "../../assets/svg/weekend.svg";
import { ReactComponent as RosterIcon } from "../../assets/svg/roster.svg";
import { ReactComponent as ShiftIcon } from "../../assets/svg/shift.svg";
import { ReactComponent as OverTimeIcon } from "../../assets/svg/overtime.svg";
import { ReactComponent as AdvanceSalaryIcon } from "../../assets/svg/advanceSalary.svg";
import { ReactComponent as AttendanceIcon } from "../../assets/svg/attendanceMgt.svg";
import { ReactComponent as ConveyanceIcon } from "../../assets/svg/conveyance.svg";
import { ReactComponent as LeaveIcon } from "../../assets/svg/leave.svg";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";

import DashboardEmployeeAttendance from "./components/DashboardEmployeeAttendance";
import { Calendar } from "react-date-range";
import { useFetchAPI } from "../../hooks/useFetchAPI";

const isMobile = window.innerWidth <= 768;

const flexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const COLORS = ["#5B9AE4", "#FF6867", "#34C75A", "#4d4b4b", "#FF8617"];

const Dashboard = () => {
  const { getFetcher } = useFetchAPI();
  const contentRef = useRef(null);
  const [showAttendance, setShowAttendance] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [touching, setTouching] = useState(false);
  const [touchStartY, setTouchStartY] = useState(0);
  const [height, setHeight] = useState(10);
  const [color,setColor]= useState("Morning")
  const { data: attendanceData, status } = useQuery({
    queryKey: ["attendance-stats"],
    queryFn: () =>
      getFetcher(
        `dashboard/attendance-stats?date=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`
      ),
  });

  const handleClick = () => {
    if (isExpanded) {
      setHeight(100);
      setIsExpanded(false);
    } else if (contentRef.current) {
      setHeight(10);
      setIsExpanded(true);
    }
  };

  const handleTouchStart = (e) => {
    if (!touching) {
      setTouching(true);
      setTouchStartY(e.touches[0].clientY);
    }
  };

  const handleTouchEnd = (e) => {
    const touchEndY = e.changedTouches[0].clientY;
    const swipeDistance = touchStartY - touchEndY;

    if (Math.abs(swipeDistance) > 30 && touching) {
      if (swipeDistance > 30 && !isExpanded && contentRef.current) {
        setHeight(100);
        setIsExpanded(true);
      } else if (swipeDistance < -30 && isExpanded) {
        setHeight(10);
        setIsExpanded(false);
      }
    }
    setTouching(false);
  };

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 11) {
      setColor("Morning");
    } else if (hours >= 11 && hours < 15) {
      setColor("Afternoon");
    } else if (hours >= 15 && hours < 20) {
      setColor("Evening");
    } else {
      setColor("Night");
    }
  }, []);

  const timeColorMapping = {
    Morning: "var(--morning-color)",
    Afternoon: "var(--afternoon-color)",
    Evening: "#61a143",
    Night: "var(--night-color)",
  };

  const { data: branches, status: empStatus } = useQuery({
    queryKey: ["all-branches"],
    queryFn: () => getFetcher(`branches`),
  });

  if (status === "loading" || empStatus === "loading") return <>Loading</>;
  if (attendanceData.error || attendanceData.error)
    return <>Something went wrong..!! Please Reload..!!</>;

  const attendanceStat = attendanceData?.data[0];

  const attendance = [
    {
      name: "Checked In",
      boxColor: "#DBE9F9",
      value: Number(attendanceStat?.checkInTotal),
      icon: <AddLocationAltIcon sx={{ color: "#5B9AE4" }} />,
    },
    {
      name: "Not Checked In",
      boxColor: "#FFE1E0",
      value: Number(attendanceStat?.checkInPending),
      icon: <WrongLocationIcon sx={{ color: "#FF6867" }} />,
    },
    {
      name: "On Leave",
      boxColor: "#E6F8EB",
      value: Number(attendanceStat?.leaves),
      icon: <MeetingRoomIcon sx={{ color: "#34C75A" }} />,
    },
    {
      name: "Weekend Off",
      boxColor: "#ECECEC",
      value: Number(attendanceStat?.weekends),
      icon: <WeekendIcon sx={{ color: "#5B9AE4" }} />,
    },
    {
      name: "Checked Out",
      boxColor: "#FFF5E0",
      value: Number(attendanceStat?.checkOutTotal),
      icon: <LocationOffIcon sx={{ color: "#FF8617" }} />,
    },
  ];

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container spacing={2}>
        <Grid container item md={8.8} spacing={2}>
          <Grid item md={2.8} xs={12}>
            <AttendancePieChart
              attendance={attendance}
              attendanceStat={attendanceStat}
            />
          </Grid>
          <Grid item md={9.2} xs={12}>
            <AttendanceStatBox attendance={attendance} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Summary attendanceStat={attendanceStat} />
          </Grid>
          <Grid item md={6} xs={12}>
            <AttendanceSlider />
          </Grid>
          <Grid item md={12} xs={12}>
            <LastDaysAttendanceChart branches={branches} />
          </Grid>
          <Grid item md={12} xs={12}>
            <PendingOperations />
          </Grid>
        </Grid>
        <Grid item md={3.2} xs={12}>
          {isMobile ? (
            <Box
           
              sx={{
                position: isMobile && "fixed",
                bottom: isMobile && -7,
                pb: isMobile ? 1 : 6,
                left: isMobile && 0,
                bgcolor: isMobile && "white",
                height: isMobile && `${height}vh`,
                transition: isMobile && "height 0.5s ease",
                px: 1.2,
                zIndex: isMobile && 1000,
                overflow: isMobile && "hidden",
                boxShadow: isMobile && "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                borderRadius: "40px 40px 0 0",
                width: "95%",
              }}

              // onClick={handleClick}
            >
              {/* Handle drag icon at the top */}
              {isMobile && (
                <Box
                ref={contentRef}
                onClick={handleClick}
                // onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                  sx={{
                    width: "40%",
                    height: "7px",
                    bgcolor: timeColorMapping[color],
                    my: 1,
                    borderRadius: "5px",
                    mx: "auto",
                    mt: isMobile && 1.5,
                  }}
                ></Box>
              )}


              <Box onClick={handleClick}
                // onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd} >
                <Typography
                  pb={1}
                  variant="h6"
                  component="h6"
                  sx={{
                    fontSize: "17px",
                    color: timeColorMapping[color],
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Click to view Attandance
                </Typography>
              </Box>

              {/* --- visa fee and service charge table start --- */}

              <DashboardEmployeeAttendance branches={branches} />

              {/* --- visa fee and service charge table end --- */}
            </Box>
          ) : (
            <>
              <DashboardEmployeeAttendance branches={branches} />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const AttendancePieChart = ({ attendance, attendanceStat }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{ bgcolor: "white", position: "relative", zIndex: "100" }}>
          <Typography
            sx={{ fontSize: "12px", p: 2 }}
          >{`${payload[0].name} : ${payload[0].value}`}</Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ ...boxStyle, position: "relative", height: "200px" }}>
      <Typography
        className="page-title"
        sx={{ fontSize: isMobile && "14px" }}
        mb={1}
      >
        Statistic
      </Typography>
      <Box sx={{ height: "165px", position: "relative", zIndex: "100" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={attendance}
              dataKey="value"
              innerRadius={50}
              outerRadius={70}
            >
              {attendance.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box
        sx={{
          position: "absolute",
          textAlign: "center",
          top: {
            lg: "43%",
            sm: "47%",
            xs: "47%",
          },
          left: {
            xl: "37%",
            lg: "35%",
            sm: "46%",
            xs: "42%",
          },
          zIndex: "100",
        }}
      >
        <Typography
          sx={{ fontSize: isMobile ? "9px" : "12px", lineHeight: 1.2 }}
        >
          Total <br /> Employees
        </Typography>
        <Typography sx={{ fontSize: isMobile ? "16px" : "24px" }}>
          {attendanceStat?.totalEmployees}
        </Typography>
      </Box>
    </Box>
  );
};

const AttendanceStatBox = ({ attendance }) => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box sx={{ ...boxStyle, height: "200px" }}>
      <Typography
        sx={{ fontSize: isMobile && "14px" }}
        className="page-title"
        mb={1.5}
      >
        Statistics
      </Typography>
      <>
        {isMobile ? (
          <Slider {...sliderSettings}>
            {attendance.map((att, i) => (
              <Box
                key={i}
                sx={{
                  py: 2,
                  ...statBox.container,
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: att?.boxColor,
                    ...statBox.iconBox,
                    my: isMobile && 3,
                  }}
                >
                  {att?.icon}
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: "13px", lineHeight: "1" }}>
                    {att?.name}
                  </Typography>
                  <Typography sx={{ ...attSlider.textLg, color: COLORS[i] }}>
                    {att?.value}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        ) : (
          <Grid container spacing={2}>
            {attendance.map((att, i) => (
              <Grid key={i} item md={12 / 5} xs={12}>
                <Box sx={{ p: 2, ...statBox.container }}>
                  <Box sx={{ bgcolor: att?.boxColor, ...statBox.iconBox }}>
                    {att?.icon}
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "13px", lineHeight: "1" }}>
                      {att?.name}
                    </Typography>
                    <Typography sx={{ ...attSlider.textLg, color: COLORS[i] }}>
                      {att?.value}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </>
    </Box>
  );
};

const Summary = ({ attendanceStat }) => {
  const expectationsData = [
    {
      label: "Late Coming",
      value: attendanceStat?.checkInLate,
    },
    {
      label: "Early Going",
      value: attendanceStat?.checkOutEarly,
    },
  ];

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Box sx={{ ...boxStyle, height: "170px" }}>
      <Typography
        sx={{ fontSize: isMobile && "14px" }}
        className="page-title"
        mb={1.5}
      >
        Summary
      </Typography>
      <>
        {isMobile ? (
          <Slider {...sliderSettings}>
            {expectationsData.map((expected, i) => (
              <Box
                key={i}
                sx={{
                  p: 2,
                  bgcolor: "white",
                  borderRadius: "5px",
                  height: "100px",
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", color: "var(--text-color)" }}
                >
                  {expected?.label}
                </Typography>
                <Typography mt={1} sx={attSlider.textLg}>
                  {expected?.value}
                </Typography>
              </Box>
            ))}
          </Slider>
        ) : (
          <Grid container spacing={2}>
            {expectationsData.map((expected, i) => (
              <Grid key={i} item md={6} xs={12}>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "5px",
                    p: 2,
                    height: "100px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", color: "var(--text-color)" }}
                  >
                    {expected?.label}
                  </Typography>
                  <Typography mt={1} sx={attSlider.textLg}>
                    {expected?.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </>
    </Box>
  );
};

const AttendanceSlider = () => {
  const { getFetcher } = useFetchAPI();
  const [crrBranchSlide, setCrrBranchSlide] = useState(0);
  const [crrDeptSlide, setCrrDeptSlide] = useState(0);

  const { data: attendance, status } = useQuery({
    queryKey: ["attendance-summary"],
    queryFn: () =>
      getFetcher(
        `dashboard/attendance-summary?date=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`
      ),
  });

  if (status === "loading") return <>Loading</>;

  const branches = attendance?.data?.branches || [];
  const branchDepts = attendance?.data?.branchDepts || [];

  const branchSetting = {
    ...commonArrow,
    afterChange: (current) => {
      setCrrBranchSlide(current);
      setCrrDeptSlide(0);
    },
  };

  const departmentSetting = {
    ...commonArrow,
    autoplay: true,
    autoplaySpeed: 3500,
    afterChange: (current) => setCrrDeptSlide(current),
  };

  const currDepts = branchDepts?.filter(
    (dept) => dept?.branchId === branches[crrBranchSlide]?.branchId
  );

  return (
    <Box sx={{ ...boxStyle, height: isMobile ? "270" : "170px" }}>
      <Grid
        container
        spacing={2}
        sx={{
          display: isMobile && "flex",
          flexDirection: isMobile && "column",
        }}
      >
        <Grid item md={6} xs={12}>
          <Box sx={attSlider.container}>
            <Box>
              <Typography sx={attSlider.text}>Total Employee</Typography>

              {branches.length === 1 ? (
                <>
                  {branches.map((branch, i) => (
                    <Box key={i}>
                      <SliderData data={branch} />
                    </Box>
                  ))}
                </>
              ) : (
                <Slider {...branchSetting}>
                  {branches.map((branch, i) => (
                    <Box key={i}>
                      <SliderData data={branch} />
                    </Box>
                  ))}
                </Slider>
              )}
            </Box>

            <Typography sx={attSlider.footerText} noWrap>
              {branches[crrBranchSlide]?.branchName} Branch
            </Typography>
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <Box sx={attSlider.container}>
            <Box>
              <Typography sx={attSlider.text}>Total Employee</Typography>

              {currDepts.length === 1 ? (
                <>
                  {currDepts.map((branch, i) => (
                    <Box key={i}>
                      <SliderData data={branch} />
                    </Box>
                  ))}
                </>
              ) : (
                <Slider {...departmentSetting}>
                  {currDepts.map((branch, i) => (
                    <Box key={i}>
                      <SliderData data={branch} />
                    </Box>
                  ))}
                </Slider>
              )}
            </Box>

            <Typography sx={attSlider.footerText} noWrap>
              {currDepts[crrDeptSlide]?.deptName}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const SliderData = ({ data }) => {
  return (
    <Box sx={attSlider.sliderBox}>
      <Typography sx={attSlider.textLg}>{data?.totalEmployees}</Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box>
          <Typography sx={attSlider.text}>Pending</Typography>
          <Typography sx={attSlider.text}>Check In</Typography>
          <Typography sx={attSlider.text}>Check In Late</Typography>
          <Typography sx={attSlider.text}>Check Out</Typography>
          <Typography sx={attSlider.text}>Check Out Early</Typography>
          <Typography sx={attSlider.text}>Absent</Typography>
        </Box>
        <Box
          sx={{ ".MuiTypography-root": { fontSize: "11px", textAlign: "end" } }}
        >
          <Typography>{data?.noCheckIns}</Typography>
          <Typography>{data?.totalCheckIns}</Typography>
          <Typography>{data?.lateCheckIns}</Typography>
          <Typography>{data?.totalCheckOuts}</Typography>
          <Typography>{data?.earlyCheckOuts}</Typography>
          <Typography>{data?.noCheckIns}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const SliderBtn = ({ onClick, type }) => {
  return (
    <div
      style={{
        ...attSlider.sliderArrow,
        position: "absolute",
        bottom: "-16px",
        right: type === "prev" ? "17px" : "-8px",
        transform: type === "prev" ? "rotate(-180deg)" : "",
      }}
      onClick={onClick}
    >
      <ArrowRightAltIcon sx={{ color: "var(--primary-color)" }} />
    </div>
  );
};

const LastDaysAttendanceChart = ({ branches }) => {
  const { getFetcher } = useFetchAPI();
  const allBranches = branches?.data;
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: attendanceSummary, status } = useQuery({
    queryKey: ["attendance-summary-by-day", selectedDate, currentIndex],
    queryFn: () =>
      getFetcher(
        `dashboard/attendance-summary-by-day?branchId=${
          allBranches[currentIndex]?.id
        }&fromDate=${moment(selectedDate)
          .subtract(isMobile ? 2 : 14, "days")
          .format("YYYY-MM-DD")}&toDate=${moment(selectedDate).format(
          "YYYY-MM-DD"
        )}`
      ),
  });

  if (status === "loading") return "Loading";

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: "white",
            position: "relative",
            zIndex: "1000",
            py: 1,
            px: 2,
            width: "150px",
          }}
        >
          <Typography sx={{ mb: "4px", fontSize: "14px", fontWeight: "600" }}>
            On {moment(payload[0]?.payload?.date).format("ddd DD MMM YYYY")}
          </Typography>
          {payload.map((d, i) => (
            <Box
              key={i}
              sx={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <span
                style={{
                  color:
                    d?.name === "totalCheckIns"
                      ? "#34C75A"
                      : d?.name === "lateCheckIns"
                      ? "#FF8617"
                      : "#EC1F28",
                  fontSize: "18px",
                }}
              >
                •
              </span>

              <Typography sx={{ fontSize: "11px", mt: "3px" }}>
                {d?.name === "totalCheckIns"
                  ? "Total Check In"
                  : d?.name === "lateCheckIns"
                  ? "Late Check In"
                  : "Absent"}{" "}
                : <span style={{ fontWeight: "500" }}>{d?.value}</span>
              </Typography>
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  };

  const handleChangeBranch = (type) => {
    setCurrentIndex((prevIndex) => {
      if (type === "next") {
        return (prevIndex + 1) % allBranches?.length;
      } else if (type === "prev") {
        return prevIndex === 0 ? allBranches?.length - 1 : prevIndex - 1;
      }
      return prevIndex;
    });
  };

  return (
    <Box sx={{ ...boxStyle, height: "260px" }}>
      {attendanceSummary?.error ? (
        <>
          <Box sx={{ height: "100%", ...flexCenter }}>
            <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
              {attendanceSummary?.error?.message}{" "}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: isMobile && "14px" }}
              className="page-title"
            >
              Attendance On time Statistics
            </Typography>

            <ClickAwayListener
              onClickAway={() => {
                if (open) {
                  setOpen(false);
                }
              }}
            >
              <Box sx={{ position: "relative" }}>
                <Typography
                  sx={{
                    fontSize: isMobile ? "8px" : "12px",
                    bgcolor: "var(--primary-color)",
                    borderRadius: "4px",
                    color: "white",
                    cursor: "pointer",
                    minWidth: isMobile ? "110px" : "190px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    px: isMobile && 1,
                  }}
                  onClick={() => setOpen(!open)}
                >
                  {moment(selectedDate)
                    .subtract(14, "days")
                    .format("DD MMM YYYY")}{" "}
                  - {moment(selectedDate).format("DD MMM YYYY")}
                </Typography>

                <Grow
                  in={open}
                  style={{ transformOrigin: "top right" }}
                  {...(open ? { timeout: 300 } : {})}
                >
                  <Box
                    sx={{
                      bgcolor: "white",
                      position: "absolute",
                      borderRadius: "4px",
                      width: "320px",
                      zIndex: "100",
                      boxShadow: "0px 10px 20px 0px rgba(0,0,0,0.20)",
                      px: "2px",
                      right: "0",
                      top: "125%",
                    }}
                  >
                    <Box sx={{ display: "flex", zIndex: 1, width: "100%" }}>
                      <Calendar
                        color="var(--primary-color)"
                        date={selectedDate}
                        direction="vertical"
                        maxDate={new Date()}
                        months={1}
                        onChange={(date) => {
                          setSelectedDate(date);
                          setOpen(false);
                        }}
                        style={{
                          fontWeight: "600",
                          padding: "0 10px 10px",
                          width: "300px",
                          height: "100%",
                          zIndex: 100,
                        }}
                      />
                    </Box>
                  </Box>
                </Grow>
              </Box>
            </ClickAwayListener>
          </Box>

          <Box sx={{ height: "200px", mt: 2, mb: 1 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={attendanceSummary?.data?.map((d) => ({
                  ...d,
                  dateText: moment(d?.date).format("DD MMM"),
                }))}
                margin={{ left: -30, right: 10 }}
                barCategoryGap={"25%"}
              >
                <CartesianGrid strokeDasharray="5 3" vertical={false} />
                <XAxis dataKey="dateText" tick={{ fontSize: 11 }} />
                <YAxis
                  domain={[0, 90]}
                  tickCount={10}
                  tickFormatter={(value) => `${value}`}
                  tickLine={false}
                  tick={{ fontSize: 11 }}
                  axisLine={false}
                />
                <Tooltip
                  cursor={{ fill: "var(--light-pr-clr)" }}
                  content={<CustomTooltip />}
                />

                <Bar
                  dataKey="totalCheckIns"
                  fill="#34C75A"
                  maxBarSize={10}
                  radius={[10, 10, 0, 0]}
                  tickCount={50}
                />
                <Bar
                  dataKey="lateCheckIns"
                  fill="#FF8617"
                  maxBarSize={10}
                  radius={[10, 10, 0, 0]}
                />
                <Bar
                  dataKey="noCheckIns"
                  fill="#EC1F28"
                  maxBarSize={10}
                  radius={[10, 10, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>

          <Box sx={{ ...flexCenter, justifyContent: "space-between" }}>
            <Typography sx={attSlider.footerText}>
              {allBranches[currentIndex]?.branchName} Branch
            </Typography>

            {allBranches.length > 1 && (
              <Box sx={{ ...flexCenter, gap: "2px" }}>
                <Box
                  sx={{
                    ...attSlider.sliderArrow,
                    transform: "rotate(-180deg)",
                  }}
                  onClick={() => handleChangeBranch("prev")}
                >
                  <ArrowRightAltIcon sx={{ color: "var(--primary-color)" }} />
                </Box>
                <Box
                  sx={attSlider.sliderArrow}
                  onClick={() => handleChangeBranch("next")}
                >
                  <ArrowRightAltIcon sx={{ color: "var(--primary-color)" }} />
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

const PendingOperations = () => {
  const { getFetcher } = useFetchAPI();
  const navigate = useNavigate();

  const { data, status } = useQuery({
    queryKey: ["pending-results"],
    queryFn: () => getFetcher(`requests`),
  });
  if (status === "loading") return <>Loading...</>;
  if (status === "error") return <>Something went wrong...</>;

  const pendingReq = data?.data;

  const settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const textColor = [...COLORS, "#741777", "#11ced4"];
  const iconBg = [
    "#DBE9F9",
    "#FFE1E0",
    "#E6F8EB",
    "#ECECEC",
    "#FFF5E0",
    "#feecff",
    "#c3fcff",
  ];

  const icons = [
    <RosterIcon style={{ fill: textColor[0], height: "28px" }} />,
    <ShiftIcon style={{ fill: textColor[1], height: "25px" }} />,
    <OverTimeIcon style={{ fill: textColor[2], height: "40px" }} />,
    <AdvanceSalaryIcon style={{ fill: textColor[3], height: "35px" }} />,
    <AttendanceIcon style={{ fill: textColor[4], height: "30px" }} />,
    <ConveyanceIcon style={{ fill: textColor[5], height: "35px" }} />,
    <LeaveIcon style={{ fill: textColor[6], height: "25px" }} />,
  ];

  return (
    <Box sx={{ ...boxStyle, height: "200px", p: 0,mb:isMobile && 8 }}>
      <Box sx={{ px: 2, pt: 2 }}>
        <Typography
          sx={{ fontSize: isMobile && "14px" }}
          className="page-title"
          mb={1.5}
        >
          Pending Operation
        </Typography>
      </Box>

      <Box px={1}>
        <Slider {...settings}>
          {pendingReq.map((req, i) => {
            return (
              <Box key={i} sx={{ bgcolor: "#F4F5F7" }}>
                <Box sx={{ px: 1 }}>
                  <Box
                    onClick={() => navigate("/dashboard/pendingOperations")}
                    sx={{ ...statBox.container, cursor: "pointer" }}
                  >
                    <Box sx={{ bgcolor: iconBg[i], ...statBox.iconBox }}>
                      {icons[i]}
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography sx={{ fontSize: "13px", lineHeight: "1" }}>
                        {req?.module}
                      </Typography>
                      <Typography
                        sx={{ ...attSlider.textLg, color: textColor[i] }}
                      >
                        {req?.pending}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

const boxStyle = { borderRadius: "10px", bgcolor: "#F4F5F7", p: 2 };

const attSlider = {
  container: {
    p: 2,
    background: "#ffffff",
    height: "140px",
    borderRadius: "5px",
  },
  sliderBox: { display: "flex", justifyContent: "space-between", my: 1 },
  textLg: {
    color: "var(--primary-color)",
    fontSize: isMobile ? "1rem" : "2rem",
    fontWeight: 500,
    lineHeight: "1.2",
  },
  text: { fontSize: isMobile ? "9px" : "11px", color: "var(--text-color)" },
  sliderArrow: {
    cursor: "pointer",
    width: "max-content",
    height: "15px",
    display: "flex",
    alignItems: "center",
  },
  footerText: {
    fontSize: isMobile ? "9px" : "11px",
    color: "var(--primary-color)",
    width: "75%",
  },
};

const commonArrow = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <SliderBtn type={"prev"} />,
  nextArrow: <SliderBtn type={"next"} />,
};

const statBox = {
  container: {
    ...flexCenter,
    flexDirection: "column",
    gap: "15px",
    borderRadius: "5px",
    bgcolor: "white",
    height: "130px",
  },
  iconBox: {
    height: "35px",
    width: "35px",
    borderRadius: "4px",
    ...flexCenter,
  },
};

export default Dashboard;
