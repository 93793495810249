import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const Basics = ({ salaryData, handleGetSalaryValue }) => {
  const isMobile = window.innerWidth <= 768;
  // console.log(salary)
  return (
    <Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="salary" className="input-label" style={{width:"100%"}}>
            Home Allowance
            </label>
          </Grid>
          <Grid item lg={10} xs={2}>
          <input
            readOnly
            value={String(salaryData?.homeAllowance)}
            onChange={handleGetSalaryValue}
            name="homeAllowance"
            className="input-field"
            type="number"
            placeholder="Enter Home Allowance"
          />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
        <Grid item lg={2} xs={9}>
        <label htmlFor="salary" className="input-label" style={{width:"100%"}}>
        Medical Allowance
        </label>
        </Grid>
        <Grid item lg={10} xs={2}>
        <input
          readOnly
          value={String(salaryData?.medicalAllowance)}
          onChange={handleGetSalaryValue}
          name="medicalAllowance"
          className="input-field"
          type="number"
          placeholder="Enter Medical Allowance"
        />
        </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
        <Grid item lg={2} xs={9}>
        <label htmlFor="salary" className="input-label" style={{width:"100%"}}>
          <span>Transportation Allowance</span>
        </label>
        </Grid>
        <Grid item lg={10} xs={2}>
        <input
          readOnly
          value={String(salaryData?.transportationAllowance)}
          onChange={handleGetSalaryValue}
          name="transportationAllowance"
          className="input-field"
          type="number"
          placeholder="Enter Transportation Allowance"
        />
        </Grid>
        </Grid>
      </Box>

      <Box className="input-container">
        <Grid container>
        <Grid item lg={2} xs={9}>
        <label htmlFor="salary" className="input-label" style={{width:"100%"}}>
          <span>Food Allowance</span>
        </label>
        </Grid>
        <Grid item lg={10} xs={2}>
        <input
          readOnly
          value={String(salaryData?.foodAllowance)}
          onChange={handleGetSalaryValue}
          name="foodAllowance"
          className="input-field"
          type="number"
          placeholder="Enter Food Allowance"
        />
        </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
        <Grid item lg={2} xs={9}>
        <label htmlFor="salary" className="input-label" style={{width:"100%"}}>
          <span>Basic Salary</span>
        </label>
        </Grid>
        <Grid item lg={2} xs={2}>
        <input
          readOnly
          value={String(salaryData?.basicSalary)}
          onChange={handleGetSalaryValue}
          name="basicSalary"
          className="input-field"
          type="number"
          placeholder="Enter Basic Salary"
        />
        </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
        <Grid item lg={2} xs={9}>
        <label htmlFor="salary" className="input-label" style={{width:"100%"}}>
          <span>Gross Salary</span>
        </label>
        </Grid>
        <Grid item lg={10} xs={2}>
        <input
          readOnly
          value={String(salaryData?.grossSalary)}
          onChange={handleGetSalaryValue}
          name="grossSalary"
          className="input-field"
          type="number"
          placeholder="Enter Gross Salary"
        />
        </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Basics;
