const isMobile = window.innerWidth <= 768;

export const submenuButton = {
  bgcolor: "#EFF2F5",
  ":hover": { bgcolor: "#EFF2F5" },
  textTransform: "capitalize",
  color: "var(--text-medium)",
  fontSize: "12px",
  padding: "4px 8px",
};

export const addButton = {
  bgcolor: "var(--text-medium)",
  ":hover": {
    bgcolor: "var(--text-medium)",
  },
  textTransform: "capitalize",
  color: "white",
  fontSize: "12px",
  width: isMobile ? "100%" :"120px",
  padding: "5px 8px",
};

export const primaryBtn = {
  backgroundColor: "var(--primary-color)",
  ":hover": { bgcolor: "var(--primary-color)" },
  color: "#ffffff",
  fontSize: "14px",
};

export const customSelectStyle = {
  option: (provided) => {
    return {
      ...provided,
      margin: "0px",
      padding: "5px 8px",
      cursor: "pointer",
      fontSize: "12px",
      backgroundColor: "none",
      color: "var(--text-medium)",
      ":hover": {
        backgroundColor: "var(--light-pr-clr)",
      },
    };
  },

  control: (provided, state) => {
    return {
      ...provided,
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": { borderColor: "none" },
      boxShadow: "none",
      display: "flex",
      cursor: "pointer",
      fontSize: "11px",
      padding: "0px",
    };
  },
  indicatorSeparator: () => null,
  menuList: (provided) => {
    return {
      ...provided,
      // maxHeight: "175px",
      // "::-webkit-scrollbar": { width: "0px", height: "0px" },
      // "::-webkit-scrollbar-track": { background: "#e9f7ff" },
      // "::-webkit-scrollbar-thumb": { background: "#e9f7ff" },
      padding: "0px",
    };
  },
};

export const filterSelectStyle = (deptId) => {
  const isSelectedId = (state) => state?.selectProps?.value?.value === deptId;

  return {
    ...customSelectStyle,

    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        padding: "4px",
        color: isSelectedId(state) ? "white" : "var(--text-medium)",
        ":hover": {
          color: isSelectedId(state) ? "white" : "var(--text-medium)",
        },
      };
    },

    control: (provided, state) => {
      return {
        ...customSelectStyle.control(provided, state),
        backgroundColor: isSelectedId(state)
          ? "var(--primary-color)"
          : "#EFF2F5",
        minHeight: "31px",
        border: 0,
        minWidth: "150px",
        maxWidth: "150px",
      };
    },

    singleValue: (provided) => {
      return {
        ...provided,
        padding: "5px 8px",
        color: "white",
        fontWeight: "500",
        fontSize: "12px",
      };
    },

    labelText: {
      fontSize: "12px",
      pl: "5px",
      color: "var(--text-medium)",
      fontWeight: "500",
      width: "calc(100% - 28px)",
    },
  };
};
