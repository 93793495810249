import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ReactComponent as LeftArrowIcon } from '../../assets/svg/leftArrow.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/svg/rightArrow.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import LayersIcon from '@mui/icons-material/Layers';

const headers = [
  'Department Name',
  'Total Emplpoyee',
  'Paid Employees',
  'Total Paid',
  'Total Draft Salaries',
  'Total Built Salaries',
  'Total Published',
  'Total Accepted',
  'Total Pending',
  'Total Rejected',
  'Action',
];

const EmpSlarayAccept = [
  { label: 'Debit Account Number', key: 'agent' },
  { label: 'Email/Voucher No', key: 'email' },
  { label: 'Batch', key: 'email' },
  { label: 'Beneficiary Name', key: 'phone' },
  { label: 'Credit Account / Card No', key: 'whatsapp' },
  { label: 'Txn Type', key: 'company' },
  { label: 'Bank Name', key: 'companyadd' },
  { label: 'Routing number', key: 'status' },
  { label: 'Pay Amount', key: 'grossSalary' },
  { label: 'Remarks', key: 'joinAt' },
];

const getCurrentMonth = (date) => {
  return date.toLocaleString('default', { month: 'long' });
};

const DeptSalary = () => {
  const isMobile = window.innerWidth <= 768;
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const navigate = useNavigate();
  const location = useLocation();
  const branchId = location?.state?.branchId;

  const [allData, setAllData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [salarySummary, setSalarySummary] = useState([]);
  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);

    const currentMonth = new Date().getMonth();

    if (
      newDate.getMonth() <= currentMonth ||
      newDate.getFullYear() < new Date().getFullYear()
    ) {
      setCurrentDate(newDate);
      setCurrentMonth(newDate.getMonth());
    } else {
      console.log('Cannot go beyond the current month.');
    }
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
    setCurrentMonth(newDate.getMonth());
  };

  useEffect(() => {
    const fetchSalary = async () => {
      fetch(
        `https://flyfar-hrm-pro.de.r.appspot.com/api/company/salaries/sallary-summary/?month=${currentDate.getMonth()}&year=${currentDate.getFullYear()}&branchId=${branchId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
        .then((res) => res.json())
        .then((data) => {
          setSalarySummary(data?.data);
        })
        .catch((err) => console.error(err.message));
    };

    fetchSalary();
  }, []);

  useEffect(() => {
    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/salaries/employees?salaryStatus=published&employeeApproval=accepted`;

    const fetchSalary = async () => {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setAllData(data?.data);
        })
        .catch((err) => console.error(err.message));
    };

    fetchSalary();
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '25px',
          justifyContent: 'space-between',
          mb: isMobile && 2,
          flexDirection: isMobile && 'column',
        }}>
        <Typography
          className="page-title"
          sx={{ flex: 1, fontSize: isMobile ? '13px' : '20px' }}>
          Single Office All Department, {getCurrentMonth(currentDate)},{' '}
          {currentDate.getFullYear()}
        </Typography>

        <Box
          className="date-change-container"
          sx={{
            flex: 'none',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <CSVLink
            data={allData}
            headers={EmpSlarayAccept}
            filename={'employee-approved-salary.csv'}
            style={{ textDecoration: 'none' }}>
            <IconButton
              variant="contained"
              sx={{
                color: 'var(--primary-color)',
                padding: '5px',
                borderRadius: '4px',
                '&:hover': {
                  color: 'var(--secondary-color)',
                },
              }}>
              <DownloadIcon />
            </IconButton>
          </CSVLink>

          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <button
              className="previous-next-button"
              onClick={handlePreviousMonth}
              sx={{ width: '34px', height: '22px' }}>
              <LeftArrowIcon />
            </button>
            <button
              className="previous-next-button"
              onClick={handleNextMonth}
              sx={{ width: '34px', height: '22px' }}>
              <RightArrowIcon />
            </button>
          </Box>
        </Box>
      </Box>

      {isMobile && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {salarySummary?.map((salary, i) => (
            <Box
              key={i}
              onClick={() =>
                navigate('/dashboard/employeeSalary', {
                  state: {
                    branchId: salary?.branchId,
                    deptId: salary?.deptId,
                  },
                })
              }
              sx={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                px: 2,
                py: 1,
                borderRadius: '5px',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: '#f5f5f5',
                color: '#000',
              }}>
              <LayersIcon />
              <Typography>{salary?.deptName}</Typography>
            </Box>
          ))}
        </Box>
      )}

      {!isMobile && (
        <Box mt={3}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1550 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headers.map((header, i) => (
                    <TableCell key={i}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {salarySummary?.map((salary, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Button
                        sx={{
                          backgroundColor: 'var(--primary-color)',
                          color: '#ffffff',
                          ':hover': { bgcolor: 'var(--primary-color)' },
                          fontSize: '12px',
                          textTransform: 'capitalize',
                          minWidth: '160px',
                        }}
                        onClick={() =>
                          navigate('/dashboard/employeeSalary', {
                            state: {
                              branchId: salary?.branchId,
                              deptId: salary?.deptId,
                            },
                          })
                        }>
                        {salary?.deptName}
                      </Button>
                    </TableCell>
                    <TableCell>{salary?.totalEmployees}</TableCell>
                    <TableCell>{salary?.paidEmployees}</TableCell>
                    <TableCell>{salary?.totalPaid}</TableCell>
                    <TableCell>{salary?.totalDraftSalaries}</TableCell>
                    <TableCell>{salary?.totalBuiltSalaries}</TableCell>
                    <TableCell>{salary?.totalPublished}</TableCell>
                    <TableCell>{salary?.totalAccepted}</TableCell>
                    <TableCell>{salary?.totalPending}</TableCell>
                    <TableCell>{salary?.totalRejected}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default DeptSalary;
