import { Box, Typography } from "@mui/material";
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

const borderStyle = {
  top: { style: "thin" },
  left: { style: "thin" },
  bottom: { style: "thin" },
  right: { style: "thin" },
};

const SingleOfficeXL = ({ csvData, fileName }) => {
  // console.log(csvData);
  const isMobile = window.innerWidth <= 768;
  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${fileName} Job Card Report.xlsx`);

    // Header
    worksheet.mergeCells("A1:I1");
    worksheet.getCell("A1").value = "Fly Far International";
    worksheet.getCell("A1").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.getCell("A1").font = { bold: true };

    worksheet.mergeCells("A2:I2");
    worksheet.getCell("A2").value =
      "Ka/9/A, Hazi Abdul Latif Mansion, Bashundhara Residential Road, Near of Jamuna Future Park, Dhaka";
    worksheet.getCell("A2").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.getCell("A2").font = { bold: true };

    worksheet.mergeCells("A3:I3");
    worksheet.getCell("A3").value = `${fileName} Job Card Report`;
    worksheet.getCell("A3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.getCell("A3").font = { bold: true };

    worksheet.mergeCells("A4:I4");
    worksheet.getCell("A4").value = `General Employee Job Card Report (${moment(
      csvData[0]?.attendances[0]?.date
    ).format("DD-MM-YYYY hh:mm A")} To ${moment(
      csvData[0]?.attendances[csvData[0]?.attendances.length - 1]?.date
    ).format("DD-MM-YYYY hh:mm A")})`;
    worksheet.getCell("A4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.getCell("A4").font = { bold: true };

    let rowI = 1;

    csvData?.forEach((employee) => {
      const employeeInfo = [
        [
          "Employee Code",
          employee?.empCustomId,
          "",
          "Designation",
          employee?.designation,
        ],
        [
          "Employee Name",
          `${employee?.name}`,
          "",
          "Branch",
          employee?.branchName,
        ],
        [
          "Join Date",
          `${
            employee?.joiningDate
              ? moment(employee?.joiningDate).format("DD-MM-YYYY")
              : "Not Found"
          }`,
          "",
          "Department",
          employee?.deptName,
        ],
        ["Job Status", "Active", "", "Email", employee?.email],
      ];

      employeeInfo.forEach((row) => {
        const rowIndex = rowI + 5;
        worksheet.getRow(rowIndex).values = row;
        worksheet.getRow(rowIndex).height = 20;

        worksheet.mergeCells(`B${rowIndex}:C${rowIndex}`);
        worksheet.mergeCells(`E${rowIndex}:F${rowIndex}`);

        worksheet.getCell(`A${rowIndex}`).font = { bold: true };
        worksheet.getCell(`A${rowIndex}`).alignment = { vertical: "middle" };

        worksheet.getCell(`B${rowIndex}`).alignment = { vertical: "middle" };

        worksheet.getCell(`D${rowIndex}`).font = { bold: true };
        worksheet.getCell(`D${rowIndex}`).alignment = { vertical: "middle" };

        worksheet.getCell(`E${rowIndex}`).alignment = { vertical: "middle" };

        rowI++;
      });

      worksheet.addRow([]);

      worksheet.addRow([
        "Attendance Date",
        "Status",
        "Expected Duty (Hour)",
        "Check In Status",
        "In Time",
        "Out Time",
        "Break Time (Hour)",
        "Actual Duty (Hour)",
        "Extra/Less Duty (Hour)",
        "Check In Remarks",
        "Check Out Remarks",
      ]);

      worksheet.columns.forEach((_, index) => {
        // console.log(`${String.fromCharCode(65 + index)}${rowI + 6}`);
        worksheet.getCell(
          `${String.fromCharCode(65 + index)}${rowI + 6}`
        ).font = {
          bold: true,
        };
      });

      // const attendanceData = employee?.attendances.map((shift) => {
      //   const checkInTime = shift?.checkIn
      //     ? moment(shift?.checkIn, "hh:mm:ss")
      //     : null;

      //   const attendance = [
      //     moment(shift.date).format("DD-MM-YYYY"),
      //     shift?.dayStatus,
      //     `${shift?.expectedDutyHour}`,
      //     shift?.checkInStatus ? shift?.checkInStatus : "N/A",
      //     shift?.checkIn ? checkInTime.format("hh:mm:ss A") : "N/A",
      //     shift?.checkOut
      //       ? moment(shift?.checkOut, "hh:mm:ss").format("hh:mm:ss A")
      //       : "N/A",
      //     shift?.breakTime ? shift?.breakTime : "00:00",
      //     shift?.actualDutyHour ? `${shift?.actualDutyHour}` : "00:00",
      //     shift?.extraLessHour ? String(shift?.extraLessHour) : "00:00",
      //     shift?.checkInRemarks ? shift?.checkInRemarks : "N/A",
      //     shift?.checkOutRemarks ? shift?.checkOutRemarks : "N/A",
      //   ];

      //   return attendance;
      // });

      // attendanceData.forEach((data, index) => {
      //   const row = worksheet.addRow(data);
      //   rowI++;

      //   const shiftCheckInTime = moment(shift?.shiftCheckIn, "hh:mm:ss");
      //   const actualCheckInTime = moment(data[4], "hh:mm:ss A");

      //   // Check if actual check-in is more than 15 minutes after the scheduled check-in
      //   if (actualCheckInTime.isAfter(shiftCheckInTime.add(15, "minutes"))) {
      //     row.getCell(5).fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF0000" }, // Red color
      //     };
      //   }
      // });

      // const attendanceData = employee?.attendances.map((shift) => {
      //   const shiftCheckInTime = moment(shift?.shiftCheckIn, "hh:mm:ss"); // Scheduled shift check-in time
      //   const actualCheckInTime = shift?.checkIn
      //     ? moment(shift?.checkIn, "hh:mm:ss")
      //     : null; // Actual check-in time

      //   const attendance = [
      //     moment(shift.date).format("DD-MM-YYYY"),
      //     shift?.dayStatus,
      //     `${shift?.shiftCheckIn}`,
      //     shift?.checkInStatus ? shift?.checkInStatus : "N/A",
      //     shift?.checkIn ? actualCheckInTime.format("hh:mm:ss A") : "N/A",
      //     shift?.checkOut
      //       ? moment(shift?.checkOut, "hh:mm:ss A").format("hh:mm:ss A")
      //       : "N/A",
      //     shift?.breakTime ? shift?.breakTime : "00:00",
      //     shift?.actualDutyHour ? `${shift?.actualDutyHour}` : "00:00",
      //     shift?.extraLessHour ? String(shift?.extraLessHour) : "00:00",
      //     shift?.checkInRemarks ? shift?.checkInRemarks : "N/A",
      //     shift?.checkOutRemarks ? shift?.checkOutRemarks : "N/A",
      //   ];
      //   console.log(attendance, shiftCheckInTime, actualCheckInTime);
      //   return { attendance, shiftCheckInTime, actualCheckInTime }; // Return times along with the attendance data
      // });

      // attendanceData.forEach((data, index) => {
      //   const row = worksheet.addRow(data.attendance); // Access the attendance array
      //   rowI++;

      //   const shiftCheckInTime = data.shiftCheckInTime; // Access shiftCheckInTime
      //   const actualCheckInTime = data.actualCheckInTime; // Access actualCheckInTime

      //   console.log(
      //     shiftCheckInTime,
      //     actualCheckInTime
      //     // actualCheckInTime.isAfter(shiftCheckInTime.add(15, "minutes")),
      //     // actualCheckInTime.isAfter(shiftCheckInTime.add(30, "minutes"))
      //   );

      //   // Check if actual check-in is more than 15 minutes after the scheduled check-in
      //   if (
      //     actualCheckInTime &&
      //     actualCheckInTime.isAfter(shiftCheckInTime.add(15, "minutes"))
      //   ) {
      //     row.getCell(5).fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FFFF00" }, // yelow color for late check-in
      //     };
      //   }

      //   if (
      //     actualCheckInTime &&
      //     actualCheckInTime.isAfter(shiftCheckInTime.add(29, "minutes"))
      //   ) {
      //     row.getCell(5).fill = {
      //       type: "pattern",
      //       pattern: "solid",
      //       fgColor: { argb: "FF0000" }, // Red color for late check-in
      //     };
      //   }
      // });

      const attendanceData = employee?.attendances.map((shift) => {
        const shiftCheckInTime = moment(shift?.shiftCheckIn, "hh:mm:ss"); // Scheduled shift check-in time
        const actualCheckInTime = shift?.checkIn
          ? moment(shift?.checkIn, "hh:mm:ss")
          : null; // Actual check-in time

        const attendance = [
          moment(shift.date).format("DD-MM-YYYY"),
          shift?.dayStatus,
          `${shift?.expectedDutyHour}`,
          shift?.checkInStatus ? shift?.checkInStatus : "N/A",
          shift?.checkIn ? actualCheckInTime.format("hh:mm:ss A") : "N/A",
          shift?.checkOut
            ? moment(shift?.checkOut, "hh:mm:ss A").format("hh:mm:ss A")
            : "N/A",
          shift?.breakTime ? shift?.breakTime : "00:00",
          shift?.actualDutyHour ? `${shift?.actualDutyHour}` : "00:00",
          shift?.extraLessHour ? String(shift?.extraLessHour) : "00:00",
          shift?.checkInRemarks ? shift?.checkInRemarks : "N/A",
          shift?.checkOutRemarks ? shift?.checkOutRemarks : "N/A",
        ];

        return { attendance, shiftCheckInTime, actualCheckInTime }; // Return times along with the attendance data
      });

      attendanceData.forEach((data, index) => {
        const row = worksheet.addRow(data.attendance); // Access the attendance array
        rowI++;

        const shiftCheckInTime = data.shiftCheckInTime; // Access shiftCheckInTime
        const actualCheckInTime = data.actualCheckInTime; // Access actualCheckInTime

        // Check if actual check-in is more than 15 minutes late
        if (actualCheckInTime) {
          const delayInMinutes = actualCheckInTime.diff(
            shiftCheckInTime,
            "minutes"
          );

          if (delayInMinutes > 30) {
            // More than 30 minutes late - Red background
            row.getCell(5).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF0000" }, // Red color for late check-in
            };
          } else if (delayInMinutes > 15 && delayInMinutes <= 30) {
            // 15-30 minutes late - Yellow background
            row.getCell(5).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF00" }, // Yellow color for slightly late check-in
            };
          }
        }
      });

      const summaryRow = [
        ["", "", "", "", ""],
        [
          "Present",
          `${employee?.totalPresentDays}`,
          "",
          "Absent",
          `${employee?.totalAbsentDays}`,
        ],
        [
          "Delay",
          `${employee?.totalLateCheckInDays}`,
          "",
          "Leave",
          `${employee?.totalLeaveDays}`,
        ],
        [
          "Weekend",
          `${employee?.totalWeekendDays}`,
          "",
          "Holiday",
          `${employee?.totalHolidayDays}`,
        ],
        [
          "Expected Duty Hour",
          `${employee?.totalExpectedWorkHours}`,
          "",
          "Actual Duty Hour",
          `${employee?.totalWorkHours}`,
        ],
        [
          "Total Days",
          `${employee?.totalDays}`,
          "",
          "Extra/Less Duty",
          `${employee?.totalExtraLessWorkHour}`,
        ],
      ];

      summaryRow.forEach((row, ind) => {
        const rowIndex = rowI + 7;
        worksheet.getRow(rowIndex).values = row;
        worksheet.getRow(rowIndex).height = 20;

        worksheet.mergeCells(`B${rowIndex}:C${rowIndex}`);
        worksheet.mergeCells(`E${rowIndex}:F${rowIndex}`);

        worksheet.getCell(`A${rowIndex}`).font = { bold: true };
        worksheet.getCell(`A${rowIndex}`).alignment = { vertical: "middle" };

        worksheet.getCell(`B${rowIndex}`).alignment = { vertical: "middle" };

        worksheet.getCell(`D${rowIndex}`).font = { bold: true };
        worksheet.getCell(`D${rowIndex}`).alignment = { vertical: "middle" };

        worksheet.getCell(`E${rowIndex}`).alignment = { vertical: "middle" };

        if (ind !== 0) {
          worksheet.getCell(`A${rowIndex}`).border = { ...borderStyle };
          worksheet.getCell(`C${rowIndex}`).border = { ...borderStyle };
          worksheet.getCell(`D${rowIndex}`).border = { ...borderStyle };
          worksheet.getCell(`F${rowIndex}`).border = { ...borderStyle };
        }

        rowI++;
      });

      rowI = rowI + 5;
    });

    // Apply styles
    worksheet.columns.forEach((column) => {
      column.width = 18;
    });

    // worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
    //   row.height = 20;
    // });

    // Save workbook to a file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${fileName} Job Card Report.xlsx`);
  };

  return (
    <div>
      <Box
        onClick={handleDownload}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          pt: 0.5,
          cursor: "pointer",
        }}
      >
        <Typography sx={{ fontSize: isMobile ? "10px" : "12px", mt: "2px" }}>
          Download as CSV
        </Typography>

        <FileDownloadIcon
          style={{ color: "var(--primary-color)", width: "18px" }}
        />
      </Box>
    </div>
  );
};

export default SingleOfficeXL;
