import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useContext, useRef, useState } from 'react';
import { ReactComponent as UserIcon } from '../../assets/svg/otpUser.svg';
import { ReactComponent as ShadowIcon } from '../../assets/svg/otpshadow.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import secureLocalStorage from 'react-secure-storage';
import { useAuth } from '../../contex/AuthProvider';

const OTPverification = () => {
  const isMobile = window.innerWidth <= 768;
  const { saveUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const loginData = location?.state?.loginData || {};
  const firstInputRef = useRef(null);
  const lastInputRef = useRef(null);
  const [otpFields, setOtpFields] = useState(new Array(6).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const handleGetOTPvalue = (e, index) => {
    if (e.key === 'Backspace') {
      if (!e.target.value && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }

      setOtpFields([...otpFields.map((otp, i) => (i === index ? '' : otp))]);

      return;
    }

    const otpValue = e.target.value;

    if (isNaN(otpValue)) {
      return false;
    }

    setOtpFields([
      ...otpFields.map((otp, i) => (i === index ? otpValue : otp)),
    ]);

    if (otpValue && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const handlePasteOTP = (e) => {
    const pastedData = e.clipboardData.getData('text/plain').slice(0, 6);

    if (isNaN(pastedData)) {
      return false;
    } else {
      setOtpFields(
        pastedData.split('').concat(new Array(6 - pastedData.length).fill('')),
      );
      if (lastInputRef.current) {
        lastInputRef.current.focus();
      }
    }
  };

  const primaryColorLight =
    getComputedStyle(document.documentElement).getPropertyValue(
      '--primary-color',
    ) + '15';

  const handleOTPverification = async (e) => {
    e.preventDefault();
    const body = { email: loginData?.email, otp: otpFields.join('') };
    // console.log(body);

    setIsLoading(true);
    try {
      const response = await fetch(
        'https://flyfar-hrm-pro.de.r.appspot.com/api/company/auth/otp-login',
        {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
      );

      const data = await response.json();

      // console.log(data);
      if (data.success) {
        secureLocalStorage.setItem('admin-info', data?.data);
        saveUser(data?.data);
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          navigate('/dashboard');
          // console.log(data);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: data?.error?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: e?.error?.message,
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    // loginAdmin(loginData, location, navigate);
    // e.target.reset();
    setIsResending(true);
    try {
      const response = await fetch(
        'https://flyfar-hrm-pro.de.r.appspot.com/api/company/auth/send-otp',
        {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData),
        },
      );

      const data = await response.json();
      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          navigate('/otpVerification', {
            state: {
              loginData,
            },
          });
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: data?.error?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        });
      }
    } catch (e) {
      Swal.fire({
        icon: 'success',
        title: 'Something went wrong',
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Ok',
      });
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: 'var(--primary-color)',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box
        sx={{
          bgcolor: 'white',
          width: isMobile ? '90%' : '70%',
          minHeight: '80vh',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '7px',
        }}>
        <Grid
          container
          sx={{
            display: isMobile && 'flex',
            justifyContent: isMobile && 'center',
          }}>
          <Grid item md={6.5}>
            <form onSubmit={handleOTPverification}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}>
                <Typography sx={{ fontSize: isMobile ? '50px' : '70px' }}>
                  OTP
                </Typography>
                <Typography
                  sx={{ fontSize: isMobile ? '13px' : '18px', mt: 4 }}>
                  Please enter the OTP send to your email
                </Typography>

                {/* --------- OTP Field Area --------- */}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    my: '25px',
                    width: isMobile ? '95%' : '70%',
                  }}>
                  {otpFields.map((otp, i) => {
                    return (
                      <input
                        style={{
                          width: isMobile ? '30px' : '40px',
                          height: isMobile ? '35px' : '50px',
                          fontSize: '35px',
                          textAlign: 'center',
                          outline: 'none',
                          border: 'none',
                          color: 'var(--primary-color)',
                          backgroundColor: primaryColorLight,
                        }}
                        key={i}
                        type="text"
                        value={otp}
                        onChange={(e) => handleGetOTPvalue(e, i)}
                        onKeyDown={(e) => handleGetOTPvalue(e, i)}
                        onPaste={handlePasteOTP}
                        ref={
                          i === 0
                            ? firstInputRef
                            : i === otpFields.length - 1
                            ? lastInputRef
                            : null
                        }
                        maxLength={1}
                      />
                    );
                  })}
                </Box>

                <Typography
                  sx={{
                    fontSize: isMobile ? '13px' : '18px',
                    color: 'var(--text-color)',
                  }}>
                  Don't receive and OTP?
                </Typography>
                <Typography
                  onClick={handleResendOTP}
                  sx={{
                    fontSize: isMobile ? '13px' : '18px',
                    color: 'var(--text-dark)',
                    mt: 2,
                    textDecoration: 'underline',
                    textUnderlineOffset: '5px',
                    textDecorationColor: 'var(--text-color)',
                    cursor: 'pointer',
                  }}>
                  {isResending ? (
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                        color: 'var(--primary-color)',
                      }}
                    />
                  ) : (
                    ' Resend OTP'
                  )}
                </Typography>
                <Button
                  disabled={isLoading}
                  type="submit"
                  sx={{
                    textTransform: 'capitalize',
                    border: '1px solid var(--input-border)',
                    width: isMobile ? '100%' : '30%',
                    height: '45px',
                    color: 'var(--text-medium)',
                    bgcolor: 'var(--primary-color)',
                    ':hover': {
                      bgcolor: 'var(--primary-color)',
                    },
                    color: 'white',
                    mt: 4,
                  }}>
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        height: '20px',
                        width: '20px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Box>
            </form>
          </Grid>
          {!isMobile && (
            <Grid item md={5.5}>
              <Box
                sx={{
                  p: 1,
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'end',
                }}>
                <UserIcon
                  style={{
                    position: 'relative',
                    zIndex: '100',
                    width: '90%',
                    paddingRight: '25px',
                  }}
                />
                <ShadowIcon
                  style={{
                    position: 'absolute',
                    left: '-15%',
                    top: '-5%',
                    fill: primaryColorLight,
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default OTPverification;
