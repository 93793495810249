import {
  Box,
  Button,
  Divider,
  Grow,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../assets/svg/leftArrow.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/svg/rightArrow.svg';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import OfficePDF from '../../components/PDF/OfficePDF';
import APILoader from '../../components/APILoader/APILoader';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LayersIcon from '@mui/icons-material/Layers';

const headers = [
  'Office',
  'Total Employee',
  'Present ',
  'Work From Home',
  'Absent',
  'Leave',
  'Half day Leave ',
  'Check In on Time',
  'Check In Late ',
  'Check out On Time',
  'Check out Early ',
];

const csvHeaders = [
  { label: 'Office', key: 'branchName' },
  { label: 'Total Employee', key: 'totalEmployees' },
  { label: 'Present', key: 'totalAttendances' },
  { label: 'Work From Home', key: 'workFromHome' },
  { label: 'Absent', key: 'noCheckIns' },
  { label: 'Leave', key: 'fullDay' },
  { label: 'Half day Leave', key: 'halfDay' },
  { label: 'Check In on Time', key: 'onTimeCheckIns' },
  { label: 'Check In Late', key: 'lateCheckIns' },
  { label: 'Check out On Time', key: 'onTimeCheckOuts' },
  { label: 'Check out Early', key: 'earlyCheckOuts' },
];

function getCurrentMonth(date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthAbbreviation = months[monthIndex];

  return `${day} ${monthAbbreviation}, ${year}`;
}
const Attendance = () => {
  const isMobile = window.innerWidth <= 768;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openDownloadOption, setOpenDownloadOption] = useState(false);
  const user = secureLocalStorage.getItem('admin-info');
  const [officeData, setOfficeData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const token = user?.accessToken;
  const navigate = useNavigate();

  const handleOpenDrawer = (officeData) => {
    setSelectedOffice(officeData);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleNextDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);

    const currentDateObj = new Date(); // Current date
    // Reset time parts to compare dates accurately
    currentDateObj.setHours(0, 0, 0, 0);

    const tomorrow = new Date(currentDateObj);
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (newDate <= tomorrow) {
      setCurrentDate(newDate);
      // filterData(newDate);
    } else {
      console.log('Cannot go beyond the current date.');
    }
  };

  const handlePreviousDate = () => {
    const newDate = new Date(currentDate);

    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
    // filterData(newDate);
  };

  useEffect(() => {
    fetch(
      `https://flyfar-hrm-pro.de.r.appspot.com/api/company/attendances/summary/?date=${moment(
        new Date(),
      ).format('YYYY-MM-DD')}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        let modifiedData;
        if (Array.isArray(data?.data)) {
          modifiedData = data?.data?.map((d, idx) => {
            d.id = idx;
            return d;
          });
        }

        setOfficeData(modifiedData);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [currentDate]);

  const handleButtonClick = (branchId) => {
    navigate(`/dashboard/singleOfficeAttendance`, {
      state: { branchId: branchId, date: currentDate },
    });
  };

  // console.log(officeData);
  return (
    <Box>
      {officeData?.length > 0 ? (
        <Box>
          {/*------------- Page Title -------------*/}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              className="page-title"
              sx={{ flex: 1, fontSize: isMobile ? '' : '21px' }}>
              Attendance "{getCurrentMonth(currentDate)}"
            </Typography>

            <Box className="date-change-container" sx={{ flex: 'none' }}>
              <button
                className="previous-next-button"
                onClick={handlePreviousDate}
                sx={{ width: '34px', height: '22px' }}>
                <LeftArrowIcon />
              </button>
              <button
                className="previous-next-button"
                onClick={handleNextDate}
                sx={{ width: '34px', height: '22px' }}>
                <RightArrowIcon />
              </button>
            </Box>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 1,
              mt: 4,
            }}
          >
            <Typography sx={{ fontSize: "17px", fontWeight: 600 }}>
              Download Report
            </Typography>
            <Tooltip title="Click to Download Report As Pdf">
              <Box>
                <PDFDownloadLink
                  document={<OfficePDF reportData={officeData} />}
                  fileName={`Office's Employees Attendence Report of ${officeData[0]?.date}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <SystemUpdateAltIcon
                        style={{ color: "var(--primary-color)" }}
                      />
                    )
                  }
                </PDFDownloadLink>
              </Box>
            </Tooltip>
            {officeData?.length !== 0 ? (
              <Tooltip title="Click to Download Report As Excel">
                <CSVLink
                  data={officeData}
                  headers={csvHeaders}
                  filename={`Office's Employees Attendence Report of ${officeData[0]?.date}.csv`}
                  style={{ textDecoration: "none" }}
                >
                  <FileDownloadIcon style={{ color: "var(--primary-color)" }} />
                </CSVLink>{" "}
              </Tooltip>
            ) : null}
          </Box> */}

          <Box
            mt={2}
            sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Button
              onClick={() => setOpenDownloadOption(!openDownloadOption)}
              sx={{
                bgcolor: 'var(--primary-color)',
                color: 'white',
                textTransform: 'capitalize',
                ':hover': {
                  bgcolor: 'var(--primary-color)',
                },
                fontSize: isMobile ? '10px' : '12px',
                height: '32px',
                width: isMobile ? '300px' : '240px',
              }}>
              Download Attendance Summary
            </Button>

            <Grow in={openDownloadOption}>
              <Box sx={{ width: '193px' }}>
                <PDFDownloadLink
                  document={<OfficePDF reportData={officeData} />}
                  fileName={`Office's Employees Attendence Report of ${officeData[0]?.date}.pdf`}>
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Loading document...'
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '12px',
                          pt: 0.5,
                          pb: '2px',
                          borderBottom: '1px solid var(--border-color)',
                        }}>
                        <Typography sx={{ fontSize: '12px', mt: '2px' }}>
                          Download as PDF
                        </Typography>
                        <SystemUpdateAltIcon
                          style={{
                            color: 'var(--primary-color)',
                            width: '16px',
                          }}
                        />
                      </Box>
                    )
                  }
                </PDFDownloadLink>

                <CSVLink
                  data={officeData}
                  headers={csvHeaders}
                  filename={`Office's Employees Attendence Report of ${officeData[0]?.date}.csv`}
                  style={{ textDecoration: 'none' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '12px',
                      pt: 0.5,
                    }}>
                    <Typography sx={{ fontSize: '12px', mt: '2px' }}>
                      Download as CSV
                    </Typography>

                    <FileDownloadIcon
                      style={{ color: 'var(--primary-color)', width: '18px' }}
                    />
                  </Box>
                </CSVLink>
              </Box>
            </Grow>
          </Box>

          {isMobile && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {officeData?.map((data, i) => (
                <Box
                  key={i}
                  onClick={() => handleButtonClick(data?.branchId)}
                  sx={{
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    px: 2,
                    py: 1,
                    borderRadius: '5px',
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#f5f5f5',
                    color: '#000',
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      pb: 1.5,
                    }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography>{data?.branchName}</Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Typography sx={attendFontStyle}>
                        Total Employees
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.totalEmployees}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Total CheckIns
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.totalCheckIns}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Work FromHome
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.workFromHome || 0}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>No CheckIns</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.noCheckIns}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Leave</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.leave || 0}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Half Day</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.halfDay || 0}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        On Time CheckIns
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.onTimeCheckIns}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>
                        On Time CheckOuts
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.onTimeCheckOuts}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        ...flexStyle,
                      }}>
                      <Typography sx={attendFontStyle}>
                        Early CheckOuts
                      </Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.earlyCheckOuts}
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          {/*------------- Page Content -------------*/}
          {!isMobile && (
            <Box mt={2}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headers?.map((header, i) => (
                        <TableCell key={i}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {console.log('officeData', officeData)}
                    {officeData?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Button
                            sx={{
                              bgcolor: 'var(--primary-color)',
                              color: 'white',
                              fontSize: '12px',
                              width: '100%',
                              textTransform: 'capitalize',
                              '&:hover': {
                                bgcolor: 'var(--primary-color)',
                              },
                            }}
                            onClick={() => handleButtonClick(data?.branchId)}>
                            {data?.branchName}
                          </Button>
                        </TableCell>
                        <TableCell>{data?.totalEmployees}</TableCell>
                        <TableCell>{data?.totalCheckIns}</TableCell>
                        <TableCell>{data?.workFromHome || 0}</TableCell>
                        <TableCell>{data?.noCheckIns}</TableCell>
                        <TableCell>{data?.leave || 0}</TableCell>
                        <TableCell>{data?.halfDay || 0}</TableCell>
                        <TableCell>{data?.onTimeCheckIns}</TableCell>
                        <TableCell>{data?.lateCheckIns}</TableCell>
                        <TableCell>{data?.onTimeCheckOuts}</TableCell>
                        <TableCell>{data?.earlyCheckOuts}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default Attendance;

const flexStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 1,
};

const attendFontStyle = {
  fontSize: '13px',
};
