import { Box, Button, Collapse, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { submenuButton } from '../../utility/styles';
import APILoader from '../../components/APILoader/APILoader';
import PendingShiftAssign from './components/PendingShiftAssign';
import PendingRoaster from './components/PendingRoaster';
import PendingAttendance from './components/PendingAttendance';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PendingOperations = () => {
  const isMobile = window.innerWidth <= 768;
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [allRequests, setAllRequests] = useState([]);
  const [singleReq, setSingleReq] = useState([]);
  const [crrBtn, setCrrBtn] = useState();
  const [isRefetch, setIsRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState('Roaster');

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleDropdownChange = (component) => {
    setSelectedComponent(component);
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // const url = "http://192.168.1.103:5000/api/company/requests";
      const url =
        'https://flyfar-hrm-pro.de.r.appspot.com/api/company/requests';

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch departments');
          }
          setIsLoading(false);
          return res.json();
        })
        .then((data) => {
          setIsLoading(false);

          const queryKey = Object.keys(data?.data[0].queries)[0];
          const queryValue = data?.data[0].queries[queryKey];

          setAllRequests(data?.data);
          setCrrBtn({
            ...data?.data[0],
            queryStr: `${queryKey}=${queryValue}`,
            index: 0,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching departments:', error);
        });
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchSingleReq = async () => {
      setIsLoading2(true);

      const url = `https://flyfar-hrm-pro.de.r.appspot.com${crrBtn?.route}?${crrBtn?.queryStr}`;
      // const url = `http://192.168.1.103:5000${crrBtn?.route}?${crrBtn?.queryStr}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch departments');
          }
          setIsLoading2(false);
          return res.json();
        })
        .then((data) => {
          setIsLoading2(false);

          setSingleReq(data?.data);
        })
        .catch((error) => {
          setIsLoading2(false);
          console.error('Error fetching departments:', error);
        });
    };

    fetchSingleReq();
  }, [token, crrBtn, isRefetch]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const url =
        'https://flyfar-hrm-pro.de.r.appspot.com/api/company/requests';

      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Failed to fetch requests');
          }
          setIsLoading(false);
          return res.json();
        })
        .then((data) => {
          setIsLoading(false);

          if (data?.data?.length > 0) {
            const firstRequest = data.data[0];
            const queryKey = Object.keys(firstRequest.queries)[0];
            const queryValue = firstRequest.queries[queryKey];

            setAllRequests(data.data);

            // Set the initial `crrBtn`
            setCrrBtn({
              ...firstRequest,
              queryStr: `${queryKey}=${queryValue}`,
              index: 0,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error fetching requests:', error);
        });
    };

    fetchData();
  }, [token]);

  const renderPendingComponent = () => {
    if (!crrBtn) {
      return (
        <Box
          sx={{
            height: '50vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography>Loading...</Typography>
        </Box>
      );
    }

    switch (crrBtn?.index) {
      case 0:
        return (
          crrBtn?.pending !== 0 && (
            <PendingRoaster singleReq={singleReq} setIsLoading={setIsLoading} />
          )
        );
      case 1:
        return (
          crrBtn?.pending !== 0 && (
            <PendingShiftAssign
              singleReq={singleReq}
              setIsLoading={setIsLoading}
              setIsRefetch={setIsRefetch}
            />
          )
        );
      case 4:
        return (
          crrBtn?.pending !== 0 && (
            <PendingAttendance
              singleReq={singleReq}
              setIsLoading={setIsLoading}
              setIsRefetch={setIsRefetch}
            />
          )
        );
      default:
        return (
          <Box
            sx={{
              height: '50vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography>No Data Found</Typography>
          </Box>
        );
    }
  };

  // console.log(crrBtn)

  return !isLoading || !isLoading2 ? (
    <Box>
      <Box sx={{ ml: 1 }}>
        <Typography
          className="page-title"
          sx={{ fontSize: isMobile ? '15px' : '21px' }}>
          Pending Operations
        </Typography>
      </Box>

      {isMobile && (
        <Box sx={{ position: 'relative', mt: 3, zIndex: 10 }}>
          <Box
            onClick={handleToggle}
            sx={{
              bgcolor: 'var(--primary-color)',
              borderRadius: '5px',
              mb: 0,
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography sx={{ color: 'white', px: 1.5, py: 1 }}>
                {selectedComponent}
              </Typography>
              <ExpandMoreIcon
                sx={{
                  color: 'white',
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </Box>
          </Box>
          <Collapse
            sx={{
              bgcolor: 'var(--primary-color)',
              borderRadius: '5px',
              py: 1,
              position: 'absolute',
              width: '100%',
              zIndex: '10',
            }}
            in={open}>
            {allRequests?.map((request, idx) => (
              <Box key={idx} sx={{ py: 0.5, px: 1 }}>
                <Typography
                  onClick={() => {
                    const queryKey = Object.keys(request.queries)[0];
                    const queryValue = request.queries[queryKey];

                    setCrrBtn({
                      ...request,
                      index: idx,
                      queryStr: `${queryKey}=${queryValue}`,
                    });
                    setSelectedComponent(`${request?.module}`);
                    setOpen(false);
                  }}
                  sx={{
                    color: 'white',
                    px: 1,
                  }}>
                  {request?.module}
                </Typography>
              </Box>
            ))}
          </Collapse>

          <Box mt={2}>{renderPendingComponent()}</Box>
          {crrBtn?.pending === 0 && (
            <Box
              sx={{
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography>No Data Found</Typography>
            </Box>
          )}
        </Box>
      )}

      {!isMobile && (
        <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap', mt: 5 }}>
          {allRequests.map((request, i) => (
            <Box key={i} sx={{ position: 'relative' }}>
              <Button
                sx={{
                  ...submenuButton,
                  ...(i === crrBtn.index && {
                    backgroundColor: 'var(--primary-color)',
                    color: '#ffffff',
                    ':hover': {
                      bgcolor: 'var(--primary-color)',
                    },
                  }),
                  px: 4,
                }}
                onClick={() => {
                  const queryKey = Object.keys(request.queries)[0];
                  const queryValue = request.queries[queryKey];

                  setCrrBtn({
                    ...request,
                    index: i,
                    queryStr: `${queryKey}=${queryValue}`,
                  });
                }}>
                {request?.module}
              </Button>
              <Typography
                sx={{
                  fontSize: '12px',
                  ...(i === crrBtn.index && {
                    color: 'var(--primary-color)',
                  }),
                  backgroundColor: 'white',
                  width: '25px',
                  height: '25px',
                  position: 'absolute',
                  right: '0',
                  top: '-12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  border: '1px solid var(--primary-color)',
                }}>
                {request?.pending}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {!isMobile && (
        <Box mt={6}>
          {crrBtn?.index === 0 && crrBtn?.pending !== 0 && (
            <PendingRoaster singleReq={singleReq} setIsLoading={setIsLoading} />
          )}

          {crrBtn?.index === 1 && crrBtn?.pending !== 0 && (
            <PendingShiftAssign
              singleReq={singleReq}
              setIsLoading={setIsLoading}
              setIsRefetch={setIsRefetch}
            />
          )}
          {crrBtn?.index === 4 && crrBtn?.pending !== 0 && (
            <PendingAttendance
              singleReq={singleReq}
              setIsLoading={setIsLoading}
              setIsRefetch={setIsRefetch}
            />
          )}

          {crrBtn?.pending === 0 && (
            <Box
              sx={{
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Typography>No Data Found</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box sx={{ height: 'calc(100vh - 200px)' }}>
      <APILoader />
    </Box>
  );
};

export default PendingOperations;
