import { Box, Typography } from "@mui/material";
import React from "react";

const EquipmentInfoDetails = ({ employeeDetails }) => {
  const isMobile = window.innerWidth <= 768;
  const data = employeeDetails[0];
  return (
    <Box sx={{ mt: isMobile && 5 }}>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Equipment Allocation
      </Typography>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="salary" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Allocate Laptop
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {" "}
          {data?.laptop === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Allocate Mobile
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {" "}
          {data?.mobile === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Allocate Mouse
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {" "}
          {data?.mouse === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Allocate Keyboard
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {" "}
          {data?.keyboard === 1 ? "Yes" : "No"}
        </Typography>
      </Box>

      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Allocate Headphone{" "}
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {" "}
          {data?.headphone === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Allocate Other Things{" "}
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {" "}
          {data?.otherAllocation === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
    </Box>
  );
};

export default EquipmentInfoDetails;
