import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { addButton } from '../../utility/styles';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import APILoader from '../../components/APILoader/APILoader';
import moment from 'moment';
import Swal from 'sweetalert2';

const actionButton = {
  border: '1px solid var(--text-medium)',
  borderRadius: '18px',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: 'var(--text-medium)',
    color: 'white',
  },
  textTransform: 'capitalize',
  width: '117px',
  height: '36px',
};

const headers = [
  'Employee Name',
  'Status',
  'Reason',
  'Start Time',
  'End Time',
  'Date',
  'Branch Name',
  'Department Name',
  'Assigned By',
];

const OverTime = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [overTime, setOverTime] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOverTime = async () => {
      try {
        const response = await fetch(
          `https://flyfar-hrm-pro.de.r.appspot.com/api/company/overtime`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch branches');
        }

        const data = await response.json();
        setOverTime(data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchOverTime();
  }, [token]);

  const handleAcceptRejectOvertime = (status, id) => {
    Swal.fire({
      icon: 'warning',
      title: `Are you sure you want to ${
        status === 'accepted' ? 'accept' : 'reject'
      } this overtime ?`,
      confirmButtonColor: 'var(--primary-color)',
      confirmButtonText: 'Ok',
    }).then(async function (res) {
      if (res.isConfirmed) {
        try {
          setIsLoading(true);
          const response = await fetch(
            `https://flyfar-hrm-pro.de.r.appspot.com/api/company/overtime/${id}`,
            {
              method: 'PATCH',
              body: JSON.stringify({ status: status }),
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            },
          );

          const data = await response.json();
          // console.log(data)
          if (data.success) {
            Swal.fire({
              icon: 'success',
              title: data?.message,
              confirmButtonColor: 'var(--primary-color)',
              confirmButtonText: 'Ok',
            }).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: data?.message || data?.error?.message,
              confirmButtonColor: 'var(--primary-color)',
              confirmButtonText: 'Ok',
            });
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  return (
    <Box>
      {overTime.length > 0 ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: '25px',
              justifyContent: 'space-between',
            }}>
            <Typography variant="title" className="page-title">
              Over Time
            </Typography>
            {user?.user?.role === 'manager' && (
              <Button
                onClick={() => navigate('/dashboard/addOverTime')}
                sx={addButton}>
                Add Over Time
              </Button>
            )}
          </Box>

          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table
                //   sx={{ minWidth: 2050 }}

                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                    {user?.user?.role !== 'manager' && (
                      <TableCell sx={{ textAlign: 'center' }}>Action</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {overTime.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {data?.firstname} {data?.lastname}
                      </TableCell>
                      <TableCell>{data?.status || 'N/A'}</TableCell>

                      <TableCell>
                        <Tooltip
                          title={
                            <Typography
                              sx={{ color: 'white', fontSize: '14px' }}>
                              {data?.reason || 'N/A'}
                            </Typography>
                          }>
                          {data?.reason.slice(0, 10) || 'N/A'}
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {moment(data?.start, 'HH:mm:ss').format('hh:mm A')}
                      </TableCell>
                      <TableCell>
                        {moment(data?.end, 'HH:mm:ss').format('hh:mm A')}
                      </TableCell>
                      <TableCell>{data?.date || 'N/A'}</TableCell>
                      <TableCell>{data?.branchName || 'N/A'}</TableCell>
                      <TableCell>{data?.deptName || 'N/A'}</TableCell>
                      <TableCell>{data?.assignedByName || 'N/A'}</TableCell>
                      {user?.user?.role !== 'manager' && (
                        <TableCell>
                          {data?.status === 'pending' && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                              <Button
                                onClick={() =>
                                  handleAcceptRejectOvertime(
                                    'accepted',
                                    data?.id,
                                  )
                                }
                                variant="contained"
                                sx={{
                                  marginLeft: '10px',
                                  bgcolor: 'var(--primary-color)',
                                  ':hover': { bgcolor: 'var(--primary-color)' },
                                }}>
                                <Typography
                                  sx={{ fontSize: '12px', color: 'white' }}>
                                  Accept
                                </Typography>
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAcceptRejectOvertime(
                                    'rejected',
                                    data?.id,
                                  )
                                }
                                variant="contained"
                                sx={{
                                  marginLeft: '10px',
                                  bgcolor: 'var(--text-medium)',
                                  ':hover': { bgcolor: 'var(--text-medium)' },
                                }}>
                                <Typography
                                  sx={{ fontSize: '12px', color: 'white' }}>
                                  Reject
                                </Typography>
                              </Button>
                            </div>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default OverTime;
