import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import './App.css'; // Include your CSS for responsiveness

const MyCalendar = () => {
  return (
    <div style={{ padding: '10px' }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        }}
        editable={true}
        selectable={true}
        events={[
          { title: 'Event 1', start: '2024-12-01' },
          { title: 'Event 2', start: '2024-12-02', end: '2024-12-03' },
        ]}
        height="auto" // Makes it responsive
      />
    </div>
  );
};

export default MyCalendar;
