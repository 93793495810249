import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  Zoom,
  Divider,
  SwipeableDrawer,
  Grid,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import { addButton } from '../../utility/styles';
import APILoader from '../../components/APILoader/APILoader';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const Office = () => {
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  const [office, setOffice] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [Id, setId] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => setOpen(false);
  const handleChange = (e) => {
    setFormInfo({ ...formInfo, [e.target.name]: e.target.value });
  };
  const handleOpen = (data) => {
    console.log(data);
    setOpen(true);
    setFormInfo(data);
    setId(data?.id);
  };
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;

  useEffect(() => {
    fetch(`https://flyfar-hrm-pro.de.r.appspot.com/api/company/branches`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setOffice(data?.data);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [token]);

  const handleEdit = (row) => {
    setFormInfo({
      ...row,
      id: row.id,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/branches/${Id}`;
    const body = JSON.stringify({
      branchName: formInfo.branchName,
      address: formInfo.address,
      minLatitude: parseFloat(formInfo.minLatitude),
      maxLatitude: parseFloat(formInfo.maxLatitude),
      minLongitude: parseFloat(formInfo.minLongitude),
      maxLongitude: parseFloat(formInfo.maxLongitude),
    });

    try {
      setLoading(true); // Move setLoading here

      const response = await fetch(url, {
        method: 'PATCH',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.success === true) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--secondary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          navigate('/admin/ofiice');
          window.location.reload();
        });
      } else {
        throw new Error(data?.message);
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: err.message || 'Something went wrong',
        confirmButtonColor: 'var(--secondary-color)',
        confirmButtonText: 'Try again',
      }).then(function () {
        navigate('/admin/ofiice');
      });
    } finally {
      setLoading(false); // Ensure setLoading is set to false even in case of error
    }

    handleClose();
    e.target.reset();
  };

  const headers = [
    'Office Name',
    'Address',
    'Max Longitude',
    'Min Longitude',
    'Max Latitude',
    'Min Latitude',
    'Total Department',
    'Total Employee',
    'Created At',
    'Action',
  ];

  return (
    <Box>
      {office.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '25px',
              justifyContent: 'space-between',
              flexDirection: isMobile && 'column',
            }}>
            <Typography variant="title" className="page-title">
              Office List
            </Typography>
            <Button
              onClick={() => navigate('/dashboard/addOffice')}
              sx={addButton}>
              Add Office
            </Button>
          </Box>

          <Box mt={3}>
            {!isMobile ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headers.map((header, i) => (
                        <TableCell key={i}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {office?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell> {data?.branchName} </TableCell>
                        <TableCell> {data?.address} </TableCell>
                        <TableCell> {data?.maxLongitude} </TableCell>
                        <TableCell> {data?.minLongitude} </TableCell>
                        <TableCell> {data?.maxLatitude} </TableCell>
                        <TableCell> {data?.minLatitude} </TableCell>
                        <TableCell> N/A </TableCell>
                        <TableCell> N/A </TableCell>
                        <TableCell> {data?.createdAt} </TableCell>
                        <TableCell>
                          {' '}
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {' '}
                            <Button
                              variant="contained"
                              color="primary"
                              // startIcon={<EditIcon />}
                              sx={{
                                marginLeft: '10px',
                                bgcolor: 'var(--primary-color)',
                                ':hover': {
                                  bgcolor: 'var(--primary-color)',
                                },
                              }}
                              onClick={() => {
                                handleOpen(data);
                                handleEdit(data);
                              }}>
                              <Typography
                                sx={{ fontSize: '12px', color: 'white' }}>
                                Update
                              </Typography>
                            </Button>
                            <Button
                              variant="contained"
                              // startIcon={<DeleteIcon />}
                              sx={{
                                marginLeft: '10px',
                                bgcolor: 'var(--text-medium)',
                                ':hover': {
                                  bgcolor: 'var(--text-medium)',
                                },
                              }}
                              // onClick={() => handleDelete(params.row.id)}
                            >
                              <Typography
                                sx={{ fontSize: '12px', color: 'white' }}>
                                Delete
                              </Typography>
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {office?.map((data, i) => (
                  <Box
                    key={i}
                    sx={{
                      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                      px: 2,
                      py: 1,
                      borderRadius: '5px',
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      cursor: 'pointer',
                      backgroundColor: '#f5f5f5',
                      color: '#000',
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        pb: 1.5,
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Typography sx={attendFontStyle}>
                          Branch Name
                        </Typography>
                        <Typography sx={attendFontStyle}>
                          {data?.branchName}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>Address</Typography>
                        <Typography sx={attendFontStyle}>
                          {data?.address}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>
                          Max Longitude
                        </Typography>
                        <Typography sx={attendFontStyle}>
                          {data?.maxLongitude}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>
                          Min Longitude
                        </Typography>
                        <Typography sx={attendFontStyle}>
                          {data?.minLongitude}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>
                          Min Latitude
                        </Typography>
                        <Typography sx={attendFontStyle}>
                          {data?.maxLatitude}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>
                          Max Latitude
                        </Typography>
                        <Typography sx={attendFontStyle}>
                          {data?.minLatitude}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>
                          Total Department
                        </Typography>
                        <Typography sx={attendFontStyle}>N/A</Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>
                          Total Employee
                        </Typography>
                        <Typography sx={attendFontStyle}>N/A</Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mt: 1,
                        }}>
                        <Typography sx={attendFontStyle}>Created At</Typography>
                        <Typography sx={attendFontStyle}>
                          {data?.createdAt}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 2,
                        }}>
                        <Button
                          variant="contained"
                          color="primary"
                          // startIcon={<EditIcon />}
                          sx={{
                            width: '100%',
                            marginLeft: '10px',
                            bgcolor: 'var(--primary-color)',
                            ':hover': {
                              bgcolor: 'var(--primary-color)',
                            },
                          }}
                          onClick={() => {
                            handleOpen(data);
                            handleEdit(data);
                          }}>
                          <Typography sx={{ fontSize: '12px', color: 'white' }}>
                            Update
                          </Typography>
                        </Button>
                        <Button
                          variant="contained"
                          // startIcon={<DeleteIcon />}
                          sx={{
                            width: '100%',
                            marginLeft: '10px',
                            bgcolor: 'var(--text-medium)',
                            ':hover': {
                              bgcolor: 'var(--text-medium)',
                            },
                          }}
                          // onClick={() => handleDelete(params.row.id)}
                        >
                          <Typography sx={{ fontSize: '12px', color: 'white' }}>
                            Delete
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <APILoader />
        </Box>
      )}

      {!isMobile ? (
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          maxWidth="md"
          fullWidth>
          <Box
            sx={{
              input: {
                border: 'none',
                backgroundColor: 'var( --input-bgcolor)',
                padding: '10px 8px 10px 8px',
                width: '100%',
              },
              p: 3,
            }}>
            <Typography
              sx={{
                fontFamily: 'poppins',
                fontWeight: '600px',
                fontSize: '22px',
                color: '#222222',
              }}
              mb={2}>
              Update Office Information
            </Typography>

            <form onSubmit={handleSubmit}>
              <Box className="input-container">
                <label htmlFor="branchName" className="input-label">
                  <span> Branch Name</span>
                </label>

                <input
                  value={formInfo?.branchName}
                  onChange={handleChange}
                  name="branchName"
                  className="input-field"
                  type="text"
                  id="branchName"
                  placeholder="Branch Name"
                  required
                />
              </Box>

              <Box className="input-container">
                <label htmlFor="branaddresschName" className="input-label">
                  <span>Address</span>
                </label>

                <input
                  name="address"
                  id="address"
                  value={formInfo?.address}
                  onChange={handleChange}
                  placeholder="Address"
                  className="input-field"
                  type="text"
                  required
                />
              </Box>

              <Box className="input-container">
                <label htmlFor="minLatitude" className="input-label">
                  <span>Min Latitude</span>
                </label>

                <input
                  name="minLatitude"
                  id="minLatitude"
                  value={formInfo?.minLatitude}
                  placeholder="Min Latitude"
                  className="input-field"
                  type="number"
                  required
                />
              </Box>

              <Box className="input-container">
                <label htmlFor="minLatitude" className="input-label">
                  <span> Max Latitude</span>
                </label>

                <input
                  name="maxLatitude"
                  id="maxLatitude"
                  value={formInfo?.maxLatitude}
                  placeholder="Max Latitude"
                  className="input-field"
                  type="number"
                  required
                />
              </Box>

              <Box className="input-container">
                <label htmlFor="minLongitude" className="input-label">
                  <span> Min Longitude</span>
                </label>

                <input
                  name="minLongitude"
                  id="minLongitude"
                  value={formInfo?.minLongitude}
                  placeholder="Min Longitude"
                  className="input-field"
                  type="number"
                  required
                />
              </Box>

              <Box className="input-container">
                <label htmlFor="minLatitude" className="input-label">
                  <span> Max Longitude</span>
                </label>

                <input
                  name="maxLongitude"
                  id="maxLongitude"
                  value={formInfo?.maxLongitude}
                  placeholder="Max Longitude"
                  onChange={handleChange}
                  className="input-field"
                  type="number"
                  required
                />
              </Box>

              <Box sx={{ textAlign: 'end' }}>
                <Button
                  sx={{
                    fontFamily: 'poppins',
                    fontWeight: '400',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    borderRadius: '2px',
                    color: '#FFFFFF',
                    mt: 3,
                    bgcolor: 'var(--text-medium)',
                    ':hover': {
                      bgcolor: 'var(--text-medium)',
                    },
                  }}
                  type="submit">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          onOpen={() => {}}>
          <Box
            sx={{
              p: 3,
              input: {
                border: 'none',
                backgroundColor: 'var(--input-bgcolor)',
                padding: '10px 8px',
                width: '100%',
              },
            }}>
            <Typography
              sx={{
                fontFamily: 'poppins',
                fontWeight: '600',
                fontSize: '22px',
                color: '#222222',
              }}
              mb={2}>
              Update Office Information
            </Typography>

            <form onSubmit={handleSubmit}>
              <Box className="input-container">
                <Grid container>
                  <Grid item xs={6}>
                    <label
                      htmlFor="branchName"
                      className="input-label"
                      style={{ width: '100%' }}>
                      <span>Branch Name</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      value={formInfo?.branchName}
                      onChange={handleChange}
                      name="branchName"
                      id="branchName"
                      className="input-field"
                      type="text"
                      placeholder="Branch Name"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box className="input-container">
                <Grid container>
                  <Grid item xs={6}>
                    <label
                      htmlFor="address"
                      className="input-label"
                      style={{ width: '100%' }}>
                      <span>Address</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      name="address"
                      id="address"
                      value={formInfo?.address}
                      onChange={handleChange}
                      placeholder="Address"
                      className="input-field"
                      type="text"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box className="input-container">
                <Grid container>
                  <Grid item xs={6}>
                    <label
                      htmlFor="minLatitude"
                      className="input-label"
                      style={{ width: '100%' }}>
                      <span>Min Latitude</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      name="minLatitude"
                      id="minLatitude"
                      value={formInfo?.minLatitude}
                      onChange={handleChange}
                      placeholder="Min Latitude"
                      className="input-field"
                      type="number"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box className="input-container">
                <Grid container>
                  <Grid item xs={6}>
                    <label
                      htmlFor="maxLatitude"
                      className="input-label"
                      style={{ width: '100%' }}>
                      <span>Max Latitude</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      name="maxLatitude"
                      id="maxLatitude"
                      value={formInfo?.maxLatitude}
                      onChange={handleChange}
                      placeholder="Max Latitude"
                      className="input-field"
                      type="number"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box className="input-container">
                <Grid container>
                  <Grid item xs={6}>
                    <label
                      htmlFor="minLongitude"
                      className="input-label"
                      style={{ width: '100%' }}>
                      <span>Min Longitude</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      name="minLongitude"
                      id="minLongitude"
                      value={formInfo?.minLongitude}
                      onChange={handleChange}
                      placeholder="Min Longitude"
                      className="input-field"
                      type="number"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box className="input-container">
                <Grid container>
                  <Grid item xs={6}>
                    <label
                      htmlFor="maxLongitude"
                      className="input-label"
                      style={{ width: '100%' }}>
                      <span>Max Longitude</span>
                    </label>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      name="maxLongitude"
                      id="maxLongitude"
                      value={formInfo?.maxLongitude}
                      onChange={handleChange}
                      placeholder="Max Longitude"
                      className="input-field"
                      type="number"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ textAlign: 'end' }}>
                <Button
                  sx={{
                    width: isMobile ? '20%' : '100%',
                    fontFamily: 'poppins',
                    fontWeight: '400',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    borderRadius: '2px',
                    color: '#FFFFFF',
                    mt: 3,
                    bgcolor: 'var(--text-medium)',
                    ':hover': {
                      bgcolor: 'var(--text-medium)',
                    },
                  }}
                  type="submit">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default Office;
const flexStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 1,
};

const attendFontStyle = {
  fontSize: '13px',
};
