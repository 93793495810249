import {
  Box,
  Button,
  Dialog,
  Grid,
  Grow,
  Paper,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as LeftArrowIcon } from '../../assets/svg/leftArrow.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/svg/rightArrow.svg';
import SingleAttendenceDetails from './SingleAttendenceDetails';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AttendencePDF from '../../components/PDF/AttendencePDF';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UpdateAttendanceStatus from './UpdateAttendanceStatus';
import SingleOfficeXL from './components/SingleOfficeXL';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const headers = [
  'Date',
  'Ratio',
  'Check In',
  'Check In Status',
  'Check In Remarks',
  'Check Out',
  'Check Out Status ',
  'Check Out Remarks ',
  'Break Time',
  'Break Time Remarks',
  'Attendance Status',
  'Details',
];

const getCurrentMonth = (date) => {
  return date.toLocaleString('default', { month: 'long' });
};

const SingleStaffAttendence = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationData = location.state;
  const isMobile = window.innerWidth <= 768;
  const [currentDate, setCurrentDate] = useState(new Date());
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const [open, setOpen] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [pickDate, setPickDate] = useState([]);
  const [openAttStaus, setOpenAttStaus] = useState(false);
  const [crrAttDate, setCrrAttDate] = useState('');
  const [openAttendSummary, setOpenAttendSummary] = useState(false);
  const [openDownloadOption, setOpenDownloadOption] = useState(false);
  const [csvAttendanceData, setCsvAttendanceData] = useState([]);

  const handleNextMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);

    const currentMonth = new Date().getMonth(); // Get current month

    if (
      newDate.getMonth() <= currentMonth ||
      newDate.getFullYear() < new Date().getFullYear()
    ) {
      setCurrentDate(newDate);
      setCurrentMonth(newDate.getMonth());
      // Call function to fetch attendance data for new month
      // fetchAttendanceData(newDate);
    } else {
      console.log('Cannot go beyond the current month.');
    }
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
    setCurrentMonth(newDate.getMonth());
    // Call function to fetch attendance data for new month
    // fetchAttendanceData(newDate);
  };

  const handleOpen = (employeId, employeeName, attendanceDate) => {
    setOpen(true);
    setEmployeeId(employeId);
    setEmployeeName(employeeName);
    setPickDate(attendanceDate);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/attendances/day-summary?employeeId=${
      locationData?.employeeId
    }&month=${currentMonth + 1}&year=${currentDate.getFullYear()}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === true) {
          let modifiedData;

          if (Array.isArray(data.data)) {
            modifiedData = data.data.map((d, idx) => {
              d.id = idx;
              return d;
            });
          }
          setAttendanceData(modifiedData);
        }
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [currentMonth, currentDate, locationData?.employeeId, token]);

  useEffect(() => {
    const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/attendances/report/${
      locationData?.employeeId
    }/${currentDate.getFullYear()}/${currentMonth + 1}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === true) {
          setReportData(data?.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [currentMonth, currentDate, locationData?.employeeId, token]);

  // console.log(attendanceData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/attendances/monthly-report?employeeId=${
          locationData?.employeeId
        }&month=${currentMonth + 1}&year=${currentDate.getFullYear()}`;
        // const url = `http://192.168.1.103:5000/api/company/attendances/monthly-report?employeeId=${
        //   locationData?.employeeId
        // }&month=${currentMonth + 1}&year=${currentDate.getFullYear()}`;

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch attendance data');
        }

        const data = await response.json();
        // console.log(data);
        setCsvAttendanceData(data?.data);
      } catch (error) {
        console.error('Error fetching attendance data:', error.message);
      }
    };

    fetchData();
  }, [currentDate, locationData, token]);

  return (
    <Box sx={{ px: isMobile ? 0 : 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          className="page-title"
          sx={{ flex: 1, fontSize: isMobile ? '17px' : '20px' }}>
          Attendance Report of {locationData?.employeeName},{' '}
          {locationData?.deptName}, Month Of {getCurrentMonth(currentDate)},{' '}
          {currentDate.getFullYear()}
        </Typography>

        {!isMobile && (
          <Box className="date-change-container" sx={{ flex: 'none' }}>
            <button
              className="previous-next-button"
              onClick={handlePreviousMonth}
              sx={{ width: '34px', height: '22px' }}>
              <LeftArrowIcon />
            </button>
            <button
              className="previous-next-button"
              onClick={handleNextMonth}
              sx={{ width: '34px', height: '22px' }}>
              <RightArrowIcon />
            </button>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: isMobile ? 2.5 : 4,
          alignItems: 'center',
          // bgcolor:"red"
          position: 'relative',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          {!isMobile ? (
            <Button
              onClick={() => setOpenDownloadOption(!openDownloadOption)}
              sx={{
                bgcolor: 'var(--primary-color)',
                color: 'white',
                textTransform: 'capitalize',
                ':hover': {
                  bgcolor: 'var(--primary-color)',
                },
                fontSize: '12px',
              }}>
              Download Attendance Summary
            </Button>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FileDownloadIcon
                onClick={() => setOpenDownloadOption(!openDownloadOption)}
                sx={{
                  color: 'white',
                  bgcolor: 'var(--primary-color)',
                  borderRadius: '2px',
                }}
              />
              <Typography
                onClick={() => setOpenAttendSummary(!openAttendSummary)}
                sx={{
                  ml: 0.5,
                  fontSize: '12px',
                  color: 'white',
                  px: 1,
                  py: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  bgcolor: 'var(--primary-color)',
                  borderRadius: '2px',
                  textTransform: 'capitalize',
                  ':hover': {
                    bgcolor: 'var(--primary-color)',
                  },
                }}>
                View Attendance
              </Typography>
            </Box>
          )}
          <Grow in={openDownloadOption}>
            <Box
              sx={{
                position: 'absolute',
                top: isMobile ? 27 : 34,
                width: !isMobile ? '13.5%' : '35%',
                height: isMobile ? 'auto' : '63px',
                bgcolor: 'white',
                px: isMobile ? 1 : 2,
                py: isMobile ? 0 : 0.5,
                borderRadius: '2px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              }}>
              <PDFDownloadLink
                document={<AttendencePDF reportData={reportData} />}
                fileName={`${
                  locationData?.employeeName
                } Attendence Report of ${getCurrentMonth(currentDate)}.pdf`}>
                {({ blob, url, loading, error }) =>
                  loading ? (
                    'Loading document...'
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '12px',
                        pt: 0.5,
                        pb: '2px',
                        borderBottom: '1px solid var(--border-color)',
                      }}>
                      <Typography
                        sx={{
                          fontSize: isMobile ? '10px' : '12px',
                          mt: '2px',
                        }}>
                        Download as PDF
                      </Typography>
                      <SystemUpdateAltIcon
                        style={{ color: 'var(--primary-color)', width: '16px' }}
                      />
                    </Box>
                  )
                }
              </PDFDownloadLink>

              <SingleOfficeXL
                fileName={locationData?.employeeName}
                csvData={csvAttendanceData}
              />
            </Box>
          </Grow>
        </Box>

        <Box>
          {!isMobile && (
            <Button
              onClick={() => setOpenAttendSummary(!openAttendSummary)}
              sx={{
                bgcolor: 'var(--primary-color)',
                color: 'white',
                textTransform: 'capitalize',
                ':hover': {
                  bgcolor: 'var(--primary-color)',
                },
                fontSize: 'px',
                height: '33px',
              }}>
              View Attendance Summary
            </Button>
          )}
        </Box>
        {isMobile && (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <ArrowLeftIcon
              onClick={handlePreviousMonth}
              sx={{ bgcolor: 'var(--primary-color)', color: 'white' }}
            />
            <ArrowRightIcon
              onClick={handleNextMonth}
              sx={{ bgcolor: 'var(--primary-color)', color: 'white' }}
            />
          </Box>
        )}
      </Box>

      {!isMobile ? (
        <Box mt={1}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1450 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headers.map((header, i) => (
                    <TableCell key={i}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(data?.attendanceDate).format('DD MMMM, YYYY')}
                    </TableCell>
                    <TableCell>
                      {data?.completeAttendances}/{data?.shiftCount}
                    </TableCell>
                    <TableCell>
                      {data?.inTime
                        ? moment(data?.inTime, 'hh:mm:ss').format('hh:mm:ss A')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>{data?.inTimeStatus || 'N/A'}</TableCell>
                    <TableCell>
                      {data?.inTimeRemarks ? (
                        <Tooltip
                          title={
                            <Typography
                              sx={{ fontSize: '12px', color: 'white' }}>
                              {data?.inTimeRemarks}
                            </Typography>
                          }
                          placement="bottom">
                          {data?.inTimeRemarks?.slice(0, 15) + '...'}
                        </Tooltip>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      {data?.outTime
                        ? moment(data?.outTime, 'hh:mm:ss').format('hh:mm:ss A')
                        : 'N/A'}
                    </TableCell>

                    <TableCell>{data?.outTimeStatus || 'N/A'}</TableCell>

                    <TableCell>
                      {data?.outTimeRemarks ? (
                        <Tooltip
                          title={
                            <Typography
                              sx={{ fontSize: '12px', color: 'white' }}>
                              {data?.outTimeRemarks}
                            </Typography>
                          }
                          placement="bottom">
                          {data?.outTimeRemarks?.slice(0, 15) + '...'}
                        </Tooltip>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      {data?.outTimeRemarks ? (
                        <Tooltip
                          title={
                            <Typography
                              sx={{ fontSize: '12px', color: 'white' }}>
                              {data?.outTimeRemarks}
                            </Typography>
                          }
                          placement="bottom">
                          {data?.outTimeRemarks?.slice(0, 15) + '...'}
                        </Tooltip>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      {data?.outTimeRemarks ? (
                        <Tooltip
                          title={
                            <Typography
                              sx={{ fontSize: '12px', color: 'white' }}>
                              {data?.outTimeRemarks}
                            </Typography>
                          }
                          placement="bottom">
                          {data?.outTimeRemarks?.slice(0, 15) + '...'}
                        </Tooltip>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      {data?.attendanceRequestPending ? (
                        <Button
                          sx={{
                            bgcolor: 'red',
                            color: 'white',
                            fontSize: '12px',
                            width: '100%',
                            textTransform: 'capitalize',
                            '&:hover': {
                              bgcolor: 'red',
                            },
                          }}
                          onClick={() => {
                            setOpenAttStaus(true);
                            setEmployeeName(data?.employeeName);
                            setEmployeeId(data?.employeeId);
                            setPickDate(data?.attendanceDate);
                          }}>
                          Waiting for approval
                        </Button>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        sx={{
                          bgcolor: 'var(--primary-color)',
                          color: 'white',
                          fontSize: '12px',
                          width: '100%',
                          textTransform: 'capitalize',
                          '&:hover': {
                            bgcolor: 'var(--primary-color)',
                          },
                        }}
                        onClick={() => {
                          handleOpen(
                            data?.employeeId,
                            data?.employeeName,
                            data?.attendanceDate,
                          );
                          setCrrAttDate(data?.attendanceDate);
                        }}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {attendanceData?.map((data, index) => (
            <Box
              onClick={() => {
                handleOpen(
                  data?.employeeId,
                  data?.employeeName,
                  data?.attendanceDate,
                );
                setCrrAttDate(data?.attendanceDate);
              }}
              sx={{
                px: 1.5,
                py: 1,
                borderBottom: '1px solid var(--text-color)',
              }}>
              <Grid
                container
                sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={4} sx={{ mr: 2 }}>
                  <Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography
                        sx={{
                          color: 'var(--primary-color)',
                          fontSize: '12px',
                        }}>
                        {moment(data?.attendanceDate).format('dddd')}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'var(--primary-color)',
                          fontSize: '12px',
                        }}>
                        {data?.inTimeStatus ? 1 : 0}/
                        {data?.outTimeStatus ? 1 : 0}
                      </Typography>
                    </Box>

                    <Typography sx={{ fontSize: '13px', fontWeight: '600' }}>
                      {moment(data?.attendanceDate).format('DD MMMM, YYYY')}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2.9}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Typography
                      sx={{ color: 'var(--primary-color)', fontSize: '12px' }}>
                      In Time
                    </Typography>
                    <Typography sx={{ fontSize: '13px', fontWeight: '600' }}>
                      {data?.inTime
                        ? moment(data?.inTime, 'hh:mm:ss').format('hh:mm A')
                        : 'Pending'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2.9}>
                  <Box>
                    <Typography
                      sx={{ color: 'var(--primary-color)', fontSize: '12px' }}>
                      Out Time
                    </Typography>
                    <Typography sx={{ fontSize: '13px', fontWeight: '600' }}>
                      {data?.outTime
                        ? moment(data?.outTime, 'hh:mm:ss').format('hh:mm A')
                        : 'Pending'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Box>
                    <Typography
                      sx={{
                        bgcolor:
                          data?.inTime && data?.outTime
                            ? 'var(--evening-color)'
                            : 'orange',
                        color: 'white',
                        px: 1,
                        py: 0.2,
                        borderRadius: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      {data?.inTime && data?.outTime ? 'P' : 'A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      )}

      {!isMobile ? (
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          maxWidth="md"
          fullWidth>
          <Box sx={{ p: 3, minHeight: '450px' }}>
            <SingleAttendenceDetails
              currentDate={currentDate}
              employeeId={employeeId}
              employeeName={employeeName}
              setOpen={setOpen}
              crrAttDate={crrAttDate}
            />
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          onOpen={() => setOpen(true)}
          sx={{
            '& .MuiDrawer-paper': {
              borderTopLeftRadius: '40px',
              borderTopRightRadius: '40px',
            },
          }}>
          <Box
            sx={{
              width: '40%',
              height: '5px',
              bgcolor: 'var(--primary-color)',
              my: 1,
              borderRadius: '2.5px',
              mx: 'auto',
            }}></Box>
          <Box sx={{ p: 3, minHeight: '450px' }}>
            <SingleAttendenceDetails
              currentDate={currentDate}
              employeeId={employeeId}
              employeeName={employeeName}
              setOpen={setOpen}
              crrAttDate={crrAttDate}
            />
          </Box>
        </SwipeableDrawer>
      )}

      <Dialog
        open={openAttStaus}
        onClose={() => setOpenAttStaus(false)}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth>
        <Box sx={{ p: 3, minHeight: '450px' }}>
          <UpdateAttendanceStatus
            currentDate={pickDate}
            employeeId={employeeId}
            employeeName={employeeName}
            setOpenAttStaus={setOpenAttStaus}
          />
        </Box>
      </Dialog>

      {!isMobile ? (
        <Dialog
          open={openAttendSummary}
          onClose={() => setOpenAttendSummary(false)}
          TransitionComponent={Transition}
          maxWidth="md"
          fullWidth>
          <Box sx={{ p: 3, minHeight: '450px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Present</TableCell>
                  <TableCell>{reportData?.summary?.present}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Absent</TableCell>
                  <TableCell>{reportData?.summary?.absent}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pending Attendances</TableCell>
                  <TableCell>
                    {reportData?.summary?.pendingAttendances}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Leave Requests</TableCell>
                  <TableCell>{reportData?.summary?.leaveRequests}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Weekends</TableCell>
                  <TableCell>{reportData?.summary?.totalWeekends}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Late CheckIns</TableCell>
                  <TableCell>{reportData?.summary?.lateCheckIns}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Late CheckIns</TableCell>
                  <TableCell>{reportData?.summary?.lateCheckIns}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Early CheckOuts</TableCell>
                  <TableCell>{reportData?.summary?.earlyCheckOuts}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={openAttendSummary}
          onClose={() => setOpenAttendSummary(false)}
          onOpen={() => setOpenAttendSummary(true)}>
          <Box sx={{ p: 3, minHeight: '410px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Present</TableCell>
                  <TableCell>{reportData?.summary?.present}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Absent</TableCell>
                  <TableCell>{reportData?.summary?.absent}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pending Attendances</TableCell>
                  <TableCell>
                    {reportData?.summary?.pendingAttendances}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Leave Requests</TableCell>
                  <TableCell>{reportData?.summary?.leaveRequests}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Weekends</TableCell>
                  <TableCell>{reportData?.summary?.totalWeekends}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Late CheckIns</TableCell>
                  <TableCell>{reportData?.summary?.lateCheckIns}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Early CheckOuts</TableCell>
                  <TableCell>{reportData?.summary?.earlyCheckOuts}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default SingleStaffAttendence;
