import { Box, Grid } from "@mui/material";
import React from "react";

const SalaryAttendance = ({ attendance, handleGetSalaryValue }) => {
  return (
    <Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="workingDays" className="input-label" style={{ width: "100%" }}>
              Total Working Days
            </label>
          </Grid>
          <Grid item lg={10} xs={3}>
            <input
              readOnly
              value={String(attendance?.totalActiveDays)}
              onChange={handleGetSalaryValue}
              name="workingDays"
              className="input-field"
              type="number"
              placeholder="Enter Working Days"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="absentDays" className="input-label" style={{ width: "100%" }}>
              Absent Days
            </label>
          </Grid>
          <Grid item lg={10} xs={3}>
            <input
              readOnly
              value={String(attendance?.totalAbsentDays)}
              onChange={handleGetSalaryValue}
              name="absentDays"
              className="input-field"
              type="number"
              placeholder="Enter Absent Days"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="presentDays" className="input-label" style={{ width: "100%" }}>
              Present Days
            </label>
          </Grid>
          <Grid item lg={10} xs={3}>
            <input
              readOnly
              value={String(attendance?.totalPresentDays)}
              onChange={handleGetSalaryValue}
              name="presentDays"
              className="input-field"
              type="number"
              placeholder="Enter Present Days"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="lateDays" className="input-label" style={{ width: "100%" }}>
              Late Days
            </label>
          </Grid>
          <Grid item lg={10} xs={3}>
            <input
              readOnly
              value={String(attendance?.totalLateCheckInDays)}
              onChange={handleGetSalaryValue}
              name="lateDays"
              className="input-field"
              type="number"
              placeholder="Enter Late Days"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="leaveDays" className="input-label" style={{ width: "100%" }}>
              Leave Taken
            </label>
          </Grid>
          <Grid item lg={10} xs={3}>
            <input
              readOnly
              value={String(attendance?.totalLeaveDays)}
              onChange={handleGetSalaryValue}
              name="leaveDays"
              className="input-field"
              type="number"
              placeholder="Enter Leave Taken"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="holidays" className="input-label" style={{ width: "100%" }}>
              Holidays
            </label>
          </Grid>
          <Grid item lg={10} xs={3}>
            <input
              readOnly
              value={String(attendance?.totalHolidayDays)}
              onChange={handleGetSalaryValue}
              name="holidays"
              className="input-field"
              type="number"
              placeholder="Enter Holidays"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="input-container">
        <Grid container>
          <Grid item lg={2} xs={9}>
            <label htmlFor="weekends" className="input-label" style={{ width: "100%" }}>
              Weekends
            </label>
          </Grid>
          <Grid item lg={10} xs={3}>
            <input
              readOnly
              value={String(attendance?.totalWeekendDays)}
              onChange={handleGetSalaryValue}
              name="weekends"
              className="input-field"
              type="number"
              placeholder="Enter Weekends"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SalaryAttendance;
