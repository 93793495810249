import {
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import APILoader from '../../../components/APILoader/APILoader';
import Swal from 'sweetalert2';

const obj = {
  id: '',
  checkIn: '',
  checkOut: '',
  roasterName: '',
  shiftLocation: '',
  isOpen: false,
};

const SingleShiftUpdate = ({
  currentData,
  setCurrentData,
  setOpen,
  isRefetch,
  setIsRefetch,
}) => {
  const user = secureLocalStorage.getItem('admin-info');
  const token = user?.accessToken;
  const isMobile = window.innerWidth <= 768;

  const [dayStatus, setDayStatus] = useState('active');
  const [shifts, setShifts] = useState(
    currentData?.shiftData?.shifts
      ?.map((s) => ({
        id: s?.roaster?.id,
        checkIn: s?.roaster?.checkIn,
        checkOut: s?.roaster?.checkOut,
        roasterName: s?.roaster?.roasterName,
        shiftLocation: s?.shiftLocation,
        isOpen: false,
      }))
      .concat(
        [...new Array(4 - currentData?.shiftData?.shifts?.length)]?.map(
          () => obj,
        ),
      ),
  );
  const [roasterData, setRoasterData] = useState([]);
  const [initialRoasterData, setInitialRoasterData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://flyfar-hrm-pro.de.r.appspot.com/api/company/roasters`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch departments');
        }
        setIsLoading(false);
        return res.json();
      })
      .then((data) => {
        setIsLoading(false);
        const sortedData = data?.data
          .sort((a, b) => {
            const timeA = moment(a.checkIn, 'HH:mm:ss');
            const timeB = moment(b.checkIn, 'HH:mm:ss');
            return timeA.diff(timeB);
          })
          ?.map((d) => ({ ...d, disabled: false }))
          .filter(
            (d) =>
              d.branchId === currentData?.employee?.branchId &&
              d.deptId === currentData?.employee?.deptId,
          );

        setRoasterData(sortedData);
        setInitialRoasterData(sortedData);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching departments:', error);
      });
  }, [token, isRefetch]);

  const handleOpenRoster = (index) => {
    setShifts(
      shifts?.map((s, i) => {
        if (i === index) {
          return { ...s, isOpen: !s.isOpen };
        }
        return { ...s, isOpen: false };
      }),
    );
  };

  const handleClickAway = (index) => {
    setShifts(
      shifts?.map((s, i) => {
        if (i === index) {
          return { ...s, isOpen: false };
        }
        return s;
      }),
    );
  };

  const handleSelectRoaster = (index, roster) => {
    setShifts(
      shifts?.map((s, i) => {
        if (i === index) {
          return {
            ...s,
            id: roster?.id,
            checkIn: roster?.checkIn,
            checkOut: roster?.checkOut,
            roasterName: roster?.roasterName,
            isOpen: false,
          };
        }
        return s;
      }),
    );
  };

  const handleRemoveRoster = (index) => {
    setShifts(
      shifts?.map((s, i) => {
        if (i === index) {
          return obj;
        }
        return s;
      }),
    );
  };

  const handleSelectLocation = (index, value) => {
    setShifts(
      shifts?.map((s, i) => {
        if (i === index) {
          return { ...s, shiftLocation: value };
        }
        return s;
      }),
    );
  };

  const handleUpdate = async () => {
    for (let i = 0; i < shifts.length; i++) {
      const roster = shifts[i];
      const { roasterName, shiftLocation } = roster;

      if (shiftLocation && !roasterName) {
        alert(
          `Roster at shift ${i + 1} has a shift location but no roster name.`,
        );
        return;
      } else if (roasterName && !shiftLocation) {
        alert(
          `Roster at shift ${i + 1} has a roster name but no shift location.`,
        );
        return;
      }
    }

    const isEmptyShifts = shifts.some((s) => s.roasterName && s.shiftLocation);

    const isEmptyLocation = shifts.some((s) => s.shiftLocation);
    if (isEmptyShifts === false && dayStatus !== 'weekend') {
      alert('Please select at least one shift');
      return;
    }

    if (isEmptyLocation === false && dayStatus !== 'weekend') {
      alert('Please select shift location');
      return;
    }

    const body = [
      {
        employeeId: currentData?.employee?.employeeId,
        daysData: [
          {
            day: currentData?.shiftData?.applicableDay,
            shifts:
              dayStatus === 'weekend'
                ? []
                : shifts
                    .filter((s) => s.id)
                    ?.map((s) => ({
                      roasterId: s?.id,
                      shiftLocation: s?.shiftLocation,
                    })),

            dayState: dayStatus,
          },
        ],
      },
    ];
    console.log(body);

    try {
      // const url = `http://192.168.1.103:5000/api/company/shifts`;
      const url = `https://flyfar-hrm-pro.de.r.appspot.com/api/company/shifts`;
      setIsLoading(true);

      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setOpen(false);

      if (data?.success === true) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          // navigate(-1);
          // window.location.reload();
          setIsRefetch(!isRefetch);
        });
      } else {
        // console.log(data)
        Swal.fire({
          icon: 'error',
          title: data?.error?.message || 'Something went wrong',
          confirmButtonColor: 'var(--primary-color)',
          confirmButtonText: 'Try again',
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: err.message || 'Something went wrong',
        confirmButtonColor: 'var(--primary-color)',
        confirmButtonText: 'Try again',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ height: '450px' }}>
          <APILoader />
        </Box>
      ) : (
        <>
          <Typography
            variant="title"
            className="page-title"
            sx={{
              fontSize: '22px',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}>
            {currentData?.employee?.firstname} {currentData?.employee?.lastname}{' '}
            {currentData?.shiftData?.applicableDay} Shift Update
          </Typography>

          <Box
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'space-between',
              gap: '8px',
            }}>
            {['active', 'weekend']?.map((btn, i) => {
              const bgcolor = dayStatus === btn ? 'var(--primary-color)' : '';
              const color =
                dayStatus === btn ? 'white' : 'var(--primary-color)';
              return (
                <Button
                  key={i}
                  onClick={() => setDayStatus(btn)}
                  sx={{
                    bgcolor,
                    color,
                    ':hover': { bgcolor, color },
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    height: '38px',
                    width: '100%',
                    border: '1px solid var(--primary-color)',
                  }}>
                  {btn}
                </Button>
              );
            })}
          </Box>

          {/* Shift Data */}

          <Box
            sx={{
              pointerEvents: dayStatus === 'weekend' ? 'none' : 'auto',
            }}>
            {shifts?.map((shift, i) => {
              return (
                <Box
                  key={i}
                  sx={{ display: 'flex', flexDirection: isMobile && 'column' }}>
                  <Box
                    className="input-container"
                    sx={{ width: isMobile ? '100%' : '50%', cursor: 'auto' }}>
                    <label
                      htmlFor="salary"
                      className="input-label"
                      style={{
                        width: isMobile ? '100%' : '20%',
                        cursor: 'auto',
                        color: dayStatus === 'weekend' && '#00000061',
                      }}>
                      Shift {i + 1}
                    </label>
                    <ClickAwayListener onClickAway={() => handleClickAway(i)}>
                      <Box
                        sx={{
                          position: 'relative',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          width: isMobile ? '100%' : '75%',
                          gap: '20px',
                        }}>
                        <Box
                          onClick={() => {
                            if (dayStatus === 'active') {
                              handleOpenRoster(i);
                            }
                          }}
                          sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            cursor: 'pointer',
                          }}>
                          {shift?.roasterName ? (
                            <Typography
                              sx={{
                                fontSize: isMobile ? '10px' : '14px',
                                pl: '14px',
                                color: dayStatus === 'weekend' && '#00000061',
                              }}
                              textTransform={'capitalize'}>
                              {shift?.roasterName}{' '}
                              {moment(shift?.checkIn, 'HH:mm:ss').format(
                                'hh:mm A',
                              )}
                              -
                              {moment(shift?.checkOut, 'HH:mm:ss').format(
                                'hh:mm A',
                              )}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: '14px',
                                pl: '14px',
                                color: shift?.isShiftActive
                                  ? 'var(--text-medium)'
                                  : '#00000061',
                              }}>
                              Select Shift {i + 1} Roster
                            </Typography>
                          )}
                        </Box>

                        {shift?.roasterName && (
                          <Typography
                            onClick={() => handleRemoveRoster(i)}
                            sx={{
                              fontSize: isMobile ? '10px' : '14px',
                              cursor: 'pointer',
                              color:
                                dayStatus === 'weekend' ? '#00000061' : 'red',
                            }}>
                            Remove
                          </Typography>
                        )}

                        {shift?.isOpen && (
                          <Box
                            sx={{
                              position: 'absolute',
                              bgcolor: 'white',
                              height: '230px',
                              overflowY: 'scroll',
                              top: '110%',
                              left: '2%',
                              width: isMobile ? '100%' : '90%',
                              boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.14)',
                              zIndex: '1000',
                            }}>
                            <Box
                              sx={{
                                borderBottom: '1px solid #dadce0',
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <input
                                onChange={(e) => {
                                  setRoasterData(
                                    initialRoasterData.filter((data) =>
                                      data?.roasterName
                                        ?.toLowerCase()
                                        ?.includes(
                                          e.target.value.toLowerCase(),
                                        ),
                                    ),
                                  );
                                  setSearchText(e.target.value);
                                }}
                                value={searchText}
                                placeholder="Search Roaster Name"
                                style={{
                                  width: isMobile ? '100%' : '96%',
                                  padding: '15px 8px',
                                }}
                              />

                              <Button
                                onClick={() => {
                                  setRoasterData(initialRoasterData);
                                  setSearchText('');
                                }}
                                sx={{ p: 0, textTransform: 'capitalize' }}>
                                Reset
                              </Button>
                            </Box>

                            {roasterData?.map((data, rosterIndex) => {
                              // console.log(data);
                              return (
                                <Box
                                  onClick={() => handleSelectRoaster(i, data)}
                                  key={rosterIndex}
                                  p={1}
                                  sx={{
                                    ':hover': { bgcolor: 'rgba(0,0,0,0.14)' },
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}>
                                  <Box>
                                    <Typography textTransform={'capitalize'}>
                                      {data.roasterName}
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px' }}>
                                      {moment(data?.checkIn, 'HH:mm:ss').format(
                                        'hh:mm A',
                                      )}
                                      -
                                      {moment(
                                        data?.checkOut,
                                        'HH:mm:ss',
                                      ).format('hh:mm A')}
                                    </Typography>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                    </ClickAwayListener>
                  </Box>

                  <Box
                    className="input-container"
                    width={isMobile ? '100%' : '50%'}>
                    <label
                      className="input-label"
                      style={{
                        width: isMobile ? '100%' : '40%',
                        cursor: 'auto',
                        color: dayStatus === 'weekend' && '#00000061',
                      }}>
                      Select Shift Location
                    </label>

                    <Select
                      multiple
                      displayEmpty
                      value={[shift?.shiftLocation]}
                      onChange={(e) =>
                        handleSelectLocation(
                          i,
                          e.target.value[e.target.value.length - 1],
                        )
                      }
                      sx={{
                        ...selectStyle,
                        minWidth: isMobile ? '30%' : '60%',
                        width: 'auto',
                      }}
                      renderValue={(selected) => {
                        if (shift?.shiftLocation) {
                          if (selected[0].length > 0) {
                            return (
                              <span
                                style={{
                                  color:
                                    dayStatus === 'weekend'
                                      ? '#00000061'
                                      : 'var(--text-medium)',
                                  textTransform: 'capitalize',
                                }}>
                                {selected[0]}
                              </span>
                            );
                          } else {
                            return (
                              <span style={{ color: '#00000061' }}>
                                Select Shift Location Here
                              </span>
                            );
                          }
                        }
                      }}>
                      <MenuItem value={'on-site'} sx={{ fontSize: '14px' }}>
                        On Site
                      </MenuItem>
                      <MenuItem value={'on-field'} sx={{ fontSize: '14px' }}>
                        On Field
                      </MenuItem>
                      <MenuItem value={'remote'} sx={{ fontSize: '14px' }}>
                        Remote
                      </MenuItem>
                    </Select>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box sx={{ textAlign: 'end' }}>
            <Button
              onClick={handleUpdate}
              sx={{
                width: isMobile ? '100%' : '20%',
                bgcolor: 'var(--primary-color)',
                color: 'white',
                ':hover': { bgcolor: 'var(--primary-color)' },
                textTransform: 'capitalize',
                fontSize: isMobile ? '12px' : '14px',
                height: '38px',
                border: '1px solid var(--primary-color)',
                mt: 3,
                px: 3,
              }}>
              Update Now
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

const selectStyle = {
  fontSize: '14px',
  '& .MuiSelect-select': {
    py: '8px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px',
    borderColor: 'transparent',
  },

  '& .MuiMenu-paper': {
    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.20)',
  },
};

export default SingleShiftUpdate;
