import { Box, Typography } from "@mui/material";
import React from "react";

const OfficeInfoDetails = ({ employeeDetails }) => {
  const isMobile = window.innerWidth <= 768;
  const data = employeeDetails[0];
  return (
    <Box sx={{ mt: isMobile && 5 }}>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Official Information
      </Typography>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="salary" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}> Office</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.branchName}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}> Department </Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.deptName}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Roaster</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>
          {[
            data?.shift1Name,
            data?.shift2Name,
            data?.shift3Name,
            data?.shift4Name,
          ]
            .filter((name) => name !== null)
            .join(", ")}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Break Time in Min</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.breakTime}</Typography>
      </Box>

      <Typography sx={{ mt: 6, mb: 2, fontWeight: 600 }}>Official</Typography>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="salary" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Designation</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.designation}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Role</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.role}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Joining Date</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.joiningDate}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Employee Type</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.employeeType}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Job Type</Typography>
        </label>

        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.jobType}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Job Location</Typography>
        </label>

        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.jobLocation}</Typography>
      </Box>

      <Typography sx={{ mt: 6, mb: 2, fontWeight: 600 }}>
        Contact Information
      </Typography>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Phone Number</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.officialPhone}</Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Email</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}>{data?.email}</Typography>
      </Box>
    </Box>
  );
};

export default OfficeInfoDetails;
