import { Box, Typography } from "@mui/material";
import React from "react";

const PersonalInfoDetails = ({ employeeDetails }) => {
  const isMobile = window.innerWidth <= 768;
  const data = employeeDetails[0];
  return (
    <Box sx={{ mt: isMobile && 5 }}>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="salary" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>First Name</Typography>
        </label>

        <Typography sx={{fontSize:isMobile && "14px"}}> {data?.firstname} </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Last Name</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}> {data?.lastname} </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>DOB</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}> {data?.dob} </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Blood Group</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}> {data?.bloodGroup} </Typography>
      </Box>

      {/* <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <Typography>NID Image</Typography>
        </label>
        <Typography> {data?.firstname}  </Typography>
      </Box>
      <Box className="input-container">
        <label htmlFor="homeAllowance" className="input-label">
          <Typography>Profile Image</Typography>
        </label>
        <Typography> {data?.firstname}  </Typography>
      </Box> */}
      {/* <Box className="input-container">
      <label htmlFor="homeAllowance" className="input-label">
        <Typography>Passport No</Typography>
      </label>
      <input id="homeAllowance" className="input-field" type="text" />
    </Box> */}

      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Religion</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}> {data?.religion} </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{fontSize:isMobile && "14px"}}>Nationality</Typography>
        </label>
        <Typography sx={{fontSize:isMobile && "14px"}}> {data?.nationality} </Typography>
      </Box>
    </Box>
  );
};

export default PersonalInfoDetails;
