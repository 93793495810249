import { Box, Typography } from "@mui/material";
import React from "react";

const SalaryInfoDetails = ({ employeeDetails }) => {
  const isMobile = window.innerWidth <= 768;
  const data = employeeDetails[0];
  return (
    <Box sx={{ mt: isMobile && 5 }}>
      <Typography sx={{ my: 2, fontWeight: 600 }}>
        Salary Information
      </Typography>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="salary" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Basic Salary
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.basicSalary}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Home Allowance
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.homeAllowance}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Medical Allowance
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.medicalAllowance}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Transportation Allowance
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.transportationAllowance}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Casual Leave
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.casualLeave}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Sick Leave
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.sickLeave}
        </Typography>
      </Box>

      <Box className="input-container" mt={4}>
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Eligible for Advance Salary{" "}
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.advancedSalaryEligibility === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Eligible for Loan{" "}
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.loanSalaryEligibility === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Eligible for Casual Leave{" "}
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.casualLeaveEligibility === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Having Lunch
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.havingLunch === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box
        className="input-container"
        sx={{
          display: isMobile && "flex",
          justifyContent: isMobile && "space-between",
        }}
      >
        <label htmlFor="homeAllowance" className={!isMobile && "input-label"}>
          <Typography sx={{ fontSize: isMobile && "14px" }}>
            Eligible for Overtime{" "}
          </Typography>
        </label>
        <Typography sx={{ fontSize: isMobile && "14px" }}>
          {data?.overtimeEligibility === 1 ? "Yes" : "No"}
        </Typography>
      </Box>
    </Box>
  );
};

export default SalaryInfoDetails;
