import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  CircularProgress,
  Zoom,
  Select,
  MenuItem,
  Divider,
  SwipeableDrawer,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Swal from 'sweetalert2';
import { submenuButton } from '../../utility/styles';
import APILoader from '../../components/APILoader/APILoader';

const selectStyle = {
  fontSize: '14px',
  '& .MuiSelect-select': {
    py: '8px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0px',
    borderColor: 'transparent',
  },

  '& .MuiMenu-paper': {
    boxShadow: '10px 10px 10px black',
  },
};

const timeHeader = [
  'ID',
  'Shift Count',
  'Shift',
  'Time',
  'Penalty Amount',
  'Created At',
  'Updated At',
  'Action',
];

const dayHeader = [
  'ID',
  'Office',
  'Department',
  'Late Days',
  'Absent Days',
  'Created At',
  'Updated At',
  'Action',
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const fetcher = async (endPoind, token) => {
  const response = await fetch(
    `https://flyfar-hrm-pro.de.r.appspot.com/api/company/salary-rules/${endPoind}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
  if (!response.ok) {
    throw new Error('Failed to fetch datas');
  }
  return response.json();
};

const fetcherUpdate = async (endPoint, token, body) => {
  const response = await fetch(
    `https://flyfar-hrm-pro.de.r.appspot.com/api/company/salary-rules/${endPoint}`,
    {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to update rules');
  }

  const data = await response.json();
  return data;
};

const LateCountRules = () => {
  const [LateCountData, setLateCountData] = useState([]);
  const isMobile = window.innerWidth <= 768;
  const user = secureLocalStorage.getItem('admin-info');
  const [lateType, setLateType] = useState('Day');
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateRule, setUpdateRule] = useState({
    id: '',

    days: '',
    deduction: '',

    time: '',
    penalty: '',
  });
  const token = user?.accessToken;

  // console.log(user)
  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      try {
        const data = await fetcher(
          lateType === 'Time' ? 'late-time-rules' : 'late-day-rules',
          token,
        );
        setLateCountData(data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoaded(true);
      }
    };
    fetchData();
  }, [lateType, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const data = await fetcherUpdate(
        lateType === 'Time'
          ? 'late-time-rules/' + updateRule?.id
          : 'late-day-rules/' + updateRule?.id,
        token,
        lateType === 'Time'
          ? {
              time: Number(updateRule.time),
              penalty: Number(updateRule.penalty),
            }
          : {
              days: updateRule.days,
              deduction: updateRule.deduction,
            },
      );

      if (data?.success === true) {
        Swal.fire({
          icon: 'success',
          title: data?.message,
          confirmButtonColor: 'var(--secondary-color)',
          confirmButtonText: 'Ok',
        }).then(function () {
          window.location.reload();
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: err.message || 'Something went wrong',
        confirmButtonColor: 'var(--secondary-color)',
        confirmButtonText: 'Try again',
      });
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Box>
      <Typography variant="title" className="page-title">
        Late Count Rules
      </Typography>

      <Box sx={{ display: 'flex', gap: 1.5, mt: 5 }}>
        {['Day', 'Time'].map((type, i) => (
          <Button
            key={i}
            sx={{
              ...submenuButton,
              bgcolor: lateType === type ? 'var(--primary-color)' : '#EFF2F5',
              color: lateType === type ? 'white' : 'var(--text-medium)',
              ':hover': {
                bgcolor: lateType === type ? 'var(--primary-color)' : '#EFF2F5',
              },
            }}
            onClick={() => setLateType(type)}>
            By {type}
          </Button>
        ))}
      </Box>

      {!isMobile && (
        <Box mt={3}>
          {isLoaded ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {[lateType === 'Time' ? timeHeader : dayHeader]
                      .flat()
                      .map((header, i) => (
                        <TableCell key={i}>{header}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {LateCountData?.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography
                          sx={{
                            background: 'var(--primary-color)',
                            width: 'fit-content',
                            p: 0.5,
                            px: 1.5,
                            color: 'white',
                            borderRadius: '4px',
                            fontSize: '14px',
                          }}>
                          {data?.customId}
                        </Typography>
                      </TableCell>
                      <TableCell>{data?.branchName}</TableCell>
                      <TableCell>{data?.deptName}</TableCell>

                      {lateType === 'Time' ? (
                        <>
                          <TableCell>{data?.shiftCount}</TableCell>
                          <TableCell>{data?.shift}</TableCell>
                          <TableCell>{data?.time}</TableCell>
                          <TableCell>{data?.penalty}</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{data?.days}</TableCell>
                          <TableCell>{data?.deduction}</TableCell>
                        </>
                      )}

                      <TableCell>{data?.createdAt}</TableCell>
                      <TableCell>{data?.updatedAt}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{
                            marginLeft: '10px',
                            background: 'var(--primary-color)',
                            '&:hover': {
                              background: 'var(--primary-color)',
                            },
                          }}
                          onClick={() => {
                            setOpen(true);
                            if (lateType === 'Time') {
                              setUpdateRule({
                                id: data?.id,
                                time: data?.time,
                                penalty: data?.penalty,
                              });
                            } else {
                              setUpdateRule({
                                id: data?.id,
                                days: data?.days,
                                deduction: data?.deduction,
                              });
                            }
                          }}>
                          <Typography sx={{ fontSize: '12px', color: 'white' }}>
                            Update
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ height: 'calc(100vh - 300px)' }}>
              <APILoader />
            </Box>
          )}
        </Box>
      )}
      {isMobile && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {LateCountData?.map((data, i) => (
            <Box
              key={i}
              sx={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                px: 2,
                py: 1,
                borderRadius: '5px',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: '#f5f5f5',
                color: '#000',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  pb: 1.5,
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Typography sx={attendFontStyle}>Office</Typography>
                  <Typography sx={attendFontStyle}>
                    {data?.branchName}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Typography sx={attendFontStyle}>Department</Typography>
                  <Typography sx={attendFontStyle}>{data?.deptName}</Typography>
                </Box>
                <Divider />
                {lateType === 'Time' ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Shift Count</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.shiftCount}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Shift</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.shift}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Time</Typography>
                      <Typography sx={attendFontStyle}>{data?.time}</Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Penalty</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.penalty}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Days</Typography>
                      <Typography sx={attendFontStyle}>{data?.days}</Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography sx={attendFontStyle}>Deduction</Typography>
                      <Typography sx={attendFontStyle}>
                        {data?.deduction}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}>
                  <Typography sx={attendFontStyle}>Created At</Typography>
                  <Typography sx={attendFontStyle}>
                    {data?.createdAt}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}>
                  <Typography sx={attendFontStyle}>Updated At</Typography>
                  <Typography sx={attendFontStyle}>
                    {data?.updatedAt}
                  </Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', mt: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      marginLeft: '10px',
                      background: 'var(--primary-color)',
                      '&:hover': {
                        background: 'var(--primary-color)',
                      },
                    }}
                    onClick={() => {
                      setOpen(true);
                      if (lateType === 'Time') {
                        setUpdateRule({
                          id: data?.id,
                          time: data?.time,
                          penalty: data?.penalty,
                        });
                      } else {
                        setUpdateRule({
                          id: data?.id,
                          days: data?.days,
                          deduction: data?.deduction,
                        });
                      }
                    }}>
                    <Typography sx={{ fontSize: '12px', color: 'white' }}>
                      Update
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '150px',
        }}>
        <Link to="/dashboard/setLateCountRules">
          <Box
            sx={{
              position: 'absolute',
              right: '0',
              bottom: '0',
              width: '55px',
              height: '55px',
              backgroundColor: 'var(--primary-color)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
            <AddIcon width={'25px'} height={'25px'} style={{ fill: 'white' }} />
          </Box>
        </Link>
      </Box>

      {!isMobile ? (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullWidth>
          <Box
            sx={{
              input: {
                border: 'none',
                backgroundColor: 'var( --input-bgcolor)',
                padding: '10px 8px 10px 8px',
                width: '100%',
              },
              p: 3,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
              },
            }}>
            <Typography
              sx={{
                fontFamily: 'poppins',
                fontWeight: '600px',
                fontSize: '22px',
                color: '#222222',
              }}
              mb={2}>
              Update Late count Information
            </Typography>

            <form onSubmit={handleSubmit}>
              {lateType === 'Time' ? (
                <>
                  <Box className="input-container">
                    <label className="input-label" style={{ width: '30%' }}>
                      Late time Range
                    </label>
                    <input
                      value={updateRule?.time}
                      className="input-field"
                      type="number"
                      onChange={(e) =>
                        setUpdateRule({ ...updateRule, time: e.target.value })
                      }
                      placeholder="Late Time Range"
                    />
                  </Box>
                  <Box className="input-container">
                    <label className="input-label" style={{ width: '30%' }}>
                      Deduct Amount
                    </label>
                    <input
                      value={updateRule?.penalty}
                      className="input-field"
                      type="number"
                      onChange={(e) =>
                        setUpdateRule({
                          ...updateRule,
                          penalty: e.target.value,
                        })
                      }
                      placeholder="Deduct Amount"
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box className="input-container">
                    <label
                      htmlFor="office"
                      className="input-label"
                      style={{ width: '30%' }}>
                      Select Late Day Count
                    </label>

                    <Select
                      onChange={(e) =>
                        setUpdateRule({ ...updateRule, days: e.target.value })
                      }
                      value={updateRule?.days}
                      sx={{ ...selectStyle, width: '60%' }}
                      displayEmpty>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day, i) => (
                        <MenuItem key={i} sx={{ fontSize: '14px' }} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box className="input-container">
                    <label
                      htmlFor="office"
                      className="input-label"
                      style={{ width: '30%' }}>
                      Absent Count
                    </label>

                    <Select
                      onChange={(e) =>
                        setUpdateRule({
                          ...updateRule,
                          deduction: e.target.value,
                        })
                      }
                      value={updateRule?.deduction}
                      sx={{ ...selectStyle, width: '60%' }}
                      displayEmpty>
                      {[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map(
                        (day, i) => (
                          <MenuItem
                            key={i}
                            sx={{ fontSize: '14px' }}
                            value={day}>
                            {day}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Box>
                </>
              )}

              <Box sx={{ textAlign: 'end' }}>
                <Button
                  disabled={loading}
                  sx={{
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    borderRadius: '2px',
                    bgcolor: 'var(--text-medium)',
                    ':hover': {
                      bgcolor: 'var(--text-medium)',
                    },
                    color: '#FFFFFF',
                    mt: 3,
                  }}
                  type="submit">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Update'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Dialog>
      ) : (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => {}}>
          <Box
            sx={{
              input: {
                border: 'none',
                backgroundColor: 'var(--input-bgcolor)',
                padding: '10px 8px',
                width: '100%',
              },
              p: 3,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
              },
            }}>
            <Typography
              sx={{
                fontFamily: 'poppins',
                fontWeight: '600',
                fontSize: '22px',
                color: '#222222',
              }}
              mb={2}>
              Update Late Count Information
            </Typography>

            <form onSubmit={handleSubmit}>
              {lateType === 'Time' ? (
                <>
                  <Box className="input-container">
                    <Grid container>
                      <Grid item xs={6}>
                        <label className="input-label" style={{ width: '30%' }}>
                          Late Time Range
                        </label>
                      </Grid>
                      <Grid item xs={6}>
                        <input
                          value={updateRule?.time}
                          className="input-field"
                          type="number"
                          onChange={(e) =>
                            setUpdateRule({
                              ...updateRule,
                              time: e.target.value,
                            })
                          }
                          placeholder="Late Time Range"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="input-container">
                    <Grid container>
                      <Grid item xs={6}>
                        <label className="input-label" style={{ width: '30%' }}>
                          Deduct Amount
                        </label>
                      </Grid>
                      <Grid item xs={6}>
                        <input
                          value={updateRule?.penalty}
                          className="input-field"
                          type="number"
                          onChange={(e) =>
                            setUpdateRule({
                              ...updateRule,
                              penalty: e.target.value,
                            })
                          }
                          placeholder="Deduct Amount"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <Box className="input-container">
                    <Grid container>
                      <Grid item xs={7}>
                        <label
                          htmlFor="office"
                          className="input-label"
                          style={{ width: '100%' }}>
                          Select Late Day Count
                        </label>
                      </Grid>

                      <Grid item xs={5}>
                        <Select
                          onChange={(e) =>
                            setUpdateRule({
                              ...updateRule,
                              days: e.target.value,
                            })
                          }
                          value={updateRule?.days}
                          sx={{ ...selectStyle, width: '60%' }}
                          displayEmpty>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((day, i) => (
                            <MenuItem
                              key={i}
                              sx={{ fontSize: '14px' }}
                              value={day}>
                              {day}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="input-container">
                    <Grid container>
                      <Grid item xs={7}>
                        <label
                          htmlFor="office"
                          className="input-label"
                          style={{ width: '100%' }}>
                          Absent Count
                        </label>
                      </Grid>

                      <Grid item xs={5}>
                        <Select
                          onChange={(e) =>
                            setUpdateRule({
                              ...updateRule,
                              deduction: e.target.value,
                            })
                          }
                          value={updateRule?.deduction}
                          sx={{ ...selectStyle, width: '60%' }}
                          displayEmpty>
                          {[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5].map(
                            (day, i) => (
                              <MenuItem
                                key={i}
                                sx={{ fontSize: '14px' }}
                                value={day}>
                                {day}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              <Box sx={{ textAlign: 'end' }}>
                <Button
                  disabled={loading}
                  sx={{
                    width: '100%',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    borderRadius: '2px',
                    bgcolor: 'var(--text-medium)',
                    ':hover': {
                      bgcolor: 'var(--text-medium)',
                    },
                    color: '#FFFFFF',
                    mt: 3,
                  }}
                  type="submit">
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Update'
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default LateCountRules;

const attendFontStyle = {
  fontSize: '13px',
};
